import { createSelector } from '@reduxjs/toolkit'

import { CangoReduxModuleName, RootState } from '../../types'

import { ImagesState } from './types'

const getImagesState: (state: RootState) => ImagesState = createSelector(
	(state: RootState) => state[CangoReduxModuleName.CangoImages],
	(images) => images,
)

const getMappedImages = createSelector(
	getImagesState,
	({ images }) => new Map(images.map((image) => [image.name, image.url])),
)

export const selectors = {
	getMappedImages,
}
