import { ComponentType, useContext, useMemo } from 'react'
import { Stack } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { TableContext } from 'src/providers'
import { colors } from 'src/theme/colors'
import { Box, Chip, Text } from 'src/components'

type ColumnListProps = {
	onItemClick: (slice: TableTypes.FormulaSlice) => void
}

export const ColumnList: ComponentType<ColumnListProps> = ({ onItemClick }) => {
	const { mappedColumns } = useContext(TableContext)

	const columns = useMemo(() => {
		return [...mappedColumns.values()].map((_column) => ({
			_id: _column._id,
			label: _column.name,
		}))
	}, [mappedColumns])

	return (
		<>
			<Text component="label" fontSize={14} color={colors.neutral['80']} fontWeight={500}>
				Fields
			</Text>
			<Stack
				direction="row"
				flexWrap="wrap"
				marginY={0.5}
				sx={{
					overflowX: 'auto',
				}}
			>
				{columns.map(({ label, _id }) => (
					<Box key={_id} marginRight={1} mb={1}>
						<Chip
							color="primary"
							variant="outlined"
							label={label}
							onClick={() =>
								onItemClick({
									id: v4(),
									type: TableTypes.FormulaSliceType.FIELD,
									value: _id,
								})
							}
						/>
					</Box>
				))}
			</Stack>
		</>
	)
}
