import { Controller, useFormContext } from 'react-hook-form'
import React, { ComponentType } from 'react'
import { GridFilterOperator } from '@mui/x-data-grid-premium'

import { Checkbox } from 'src/components'
import { TableFilters } from 'src/components/table-filters'
import { DependencyForm } from 'src/modules/chains/components/chain-dependency-edge/types'

const customInputFilterItem: GridFilterOperator = {
	label: 'custom input',
	value: 'custom_input',
	getApplyFilterFn: () => {
		return () => true
	},
	requiresFilterValue: false,
}

export const DatabaseMods: ComponentType = () => {
	const { control, setValue } = useFormContext<DependencyForm>()

	const handleModifyDatabaseChange = (shouldMod: boolean) => {
		if (shouldMod) {
			setValue('database_changes', [], { shouldDirty: true })
			return
		}
		setValue('database_changes', null, { shouldDirty: true })
	}

	return (
		<Controller
			control={control}
			name="database_changes"
			render={({ field: { value, onChange } }) => (
				<>
					<Checkbox
						checked={!!value}
						label="Modify database with selection"
						onChange={(event, newValue) => handleModifyDatabaseChange(newValue)}
					/>
					{!!value && (
						<TableFilters
							onChange={(model) => onChange(model.items)}
							initialState={{ filter: { filterModel: { items: value } } }}
							localeText={{
								filterPanelAddFilter: 'Add mod',
								toolbarFilters: 'Mods',
							}}
							disableLogicOperators
							custom_filters={[customInputFilterItem]}
						/>
					)}
				</>
			)}
		/>
	)
}
