import { createSlice } from '@reduxjs/toolkit'

import * as thunks from './thunks'
import { ImagesState } from './types'

const initialState: ImagesState = {
	images: [],
}

export const imagesSlice = createSlice({
	name: 'images',
	initialState,
	reducers: {
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.getImages.fulfilled, (state, action) => {
			state.images = action.payload
		})
	},
})
