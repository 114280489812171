import { ComponentType, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _uniq from 'lodash/uniq'
import { V3ClientTypes } from '@cango-app/types'

import { ContactAndAssignment } from 'src/components'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers'
import { AsyncDispatchType } from 'src/store/types'
import { selectors as rolesSelectors } from 'src/store/modules/roles'

type ContactInfoProps = {
	project: Pick<V3ClientTypes.Project.Project, '_id' | 'roles' | 'externals'>
}

export const ContactInfo: ComponentType<ContactInfoProps> = ({ project }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const { task } = useContext(TaskContext)
	const mappedRoles = useSelector(rolesSelectors.getMappedRoles)

	const handleRoleAssignChange = async (roleId: string, userId: string) => {
		await dispatch(
			projectActions.assignUserToRole({
				projectId: project._id,
				userId,
				roleId,
			}),
		)
	}

	const handleExternalAssignChange = async (roleId: string, contactId: string) => {
		await dispatch(
			projectActions.assignContactToExternal({
				projectId: project._id,
				assignments: [{ roleId, contactId }],
			}),
		)
	}

	const taskRoles: { role: string; user?: string }[] = useMemo(() => {
		if (!task) return []
		const selectedTaskRoles = task.actions.reduce((_acc: string[], _action) => {
			return _uniq([..._acc, ..._action.roles])
		}, [])
		const projectRoles = project.roles
		const projectRolesInTask = projectRoles.filter((_projectRole) =>
			selectedTaskRoles.includes(_projectRole.role),
		)
		return projectRolesInTask.map((_role) => {
			const mappedRole = mappedRoles.get(_role.role)
			if (mappedRole) {
				return {
					role: mappedRole._id,
					user: mappedRole.defaultUser,
				}
			}
			return {
				role: _role.role,
				user: undefined,
			}
		})
	}, [task, project])

	const taskExternals = useMemo(() => {
		if (!task) return []
		const selectedTaskRoles = task.actions.reduce((_acc: string[], _action) => {
			return _uniq([..._acc, ..._action.roles])
		}, [])
		const projectExternals = project.externals
		return selectedTaskRoles.reduce(
			(_projectExt: { role: string; contact?: string }[], _selectedRole) => {
				const projectExternal = projectExternals.find(({ role }) => role === _selectedRole)
				if (projectExternal) {
					return [..._projectExt, projectExternal]
				}
				return [..._projectExt, { role: _selectedRole }]
			},
			[],
		)
	}, [task, project])

	return (
		<ContactAndAssignment
			taskExternals={taskExternals}
			taskRoles={taskRoles}
			onAssignExternal={handleExternalAssignChange}
			onAssignRole={handleRoleAssignChange}
		/>
	)
}
