import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../types'
import { selectors as configSelectors } from '../config/selectors'

import { PersistedConfigState, ProjectGroupingType, RecentlyViewedProject } from './types'

const getPersistedConfigState: (state: RootState) => PersistedConfigState = createSelector(
	(state: RootState) => state.cangoPersistedConfig,
	(persistedConfig) => persistedConfig,
)

const getRecentlyViewedProjects: (
	state: RootState,
) => Array<Omit<RecentlyViewedProject, 'blueprintId'> & { blueprintName: string }> = createSelector(
	getPersistedConfigState,
	configSelectors.getOrganisationId,
	(persistedConfig, organisationId) => {
		if (!persistedConfig || !persistedConfig.recentlyViewedProjects || !organisationId) return []
		if (!persistedConfig.recentlyViewedProjects[organisationId]) return []
		// return recentlyViewedOrganisationProjects.map((project) => {
		// 	const blueprint = find(blueprintCards, ({ _id }) => _id === project.blueprintId)
		// 	const blueprintCard = find(blueprintCards, ({ _id }) => _id === project.blueprintId)
		// 	return {
		// 		...project,
		// 		blueprintName: blueprint?.name || blueprintCard?.name || '',
		// 	}
		// })
		return []
	},
)

const getProjectGroupings = createSelector(
	getPersistedConfigState,
	(persistedConfig) => persistedConfig.projectGrouping ?? {},
)

const getProjectGroupingConfig: (state: RootState, projectId: string) => ProjectGroupingType =
	createSelector(
		getProjectGroupings,
		(state: RootState, projectId: string) => projectId,
		(groupings, projectId) => groupings[projectId] ?? ProjectGroupingType.Tags,
	)

const getAnswersConfig = createSelector(
	getPersistedConfigState,
	(persistedConfig) => persistedConfig.showAnswers ?? {},
)

const getTaskAttachmentDirection = createSelector(
	getPersistedConfigState,
	(persistedConfig) => persistedConfig.taskAttachmentDirection,
)

const getAllColumnVisibility = createSelector(
	getPersistedConfigState,
	(persistedConfig) => persistedConfig.columnVisibility ?? {},
)

const getColumnVisibility: (state: RootState, tableId: string) => string[] | undefined =
	createSelector(
		getAllColumnVisibility,
		(state: RootState, tableId: string) => tableId,
		(columnVisibility, tableId) => columnVisibility[tableId] ?? undefined,
	)

export const selectors = {
	getRecentlyViewedProjects,
	getProjectGroupings,
	getProjectGroupingConfig,
	getAnswersConfig,
	getTaskAttachmentDirection,
	getColumnVisibility,
}
