import React, { ComponentType, useContext } from 'react'
import { TableTypes } from '@cango-app/types'

import { TableContext } from 'src/providers'
import { Box, Modal } from 'src/components'

import { CalculationModal } from './calculation-modal/calculation-modal'
import { ConcatinationModal } from './concatination-modal'
import { columnsWithSettings } from './types'
import { Index } from './single-select-settings'

type ColumnSettingsModalProps = {
	columnId: string | null
	onClose: () => void
}

const UnDecoratedColumnSettingsModal: ComponentType<ColumnSettingsModalProps> = ({
	columnId,
	onClose,
}) => {
	const { mappedColumns, onUpdateColumn } = useContext(TableContext)
	const column = mappedColumns.get(columnId ?? '')

	if (!columnId || !column || !columnsWithSettings.includes(column.type)) return null

	const handleConcatenationSave = async (concatenation: string) => {
		await onUpdateColumn(columnId, {
			concatination: concatenation,
		})
	}

	return (
		<Modal
			open={!!column}
			onClose={onClose}
			onKeyDown={(e) => e.stopPropagation()}
			onScroll={(e) => e.stopPropagation()}
			containerStyle={{
				maxWidth: 'lg',
				overflowX: 'hidden',
				overflowY: 'auto',
			}}
		>
			<Box
				minWidth={500}
				minHeight={column.type === TableTypes.FieldType.CALCULATION ? 400 : 300}
				width={column.type === TableTypes.FieldType.CALCULATION ? '100%' : undefined}
			>
				{column.type === TableTypes.FieldType.SINGLE_SELECT && (
					<Index
						defaultOptions={column.valueOptions}
						valueOptionFilters={column?.valueOptionFilters ?? []}
						columnId={column._id}
						onClose={onClose}
						defaultSingleSelectLookup={column.single_select_lookup}
					/>
				)}
				{column.type === TableTypes.FieldType.CALCULATION && (
					<CalculationModal
						defaultCalculation={column.calculation}
						columnId={column._id}
						onClose={onClose}
					/>
				)}
				{column.type === TableTypes.FieldType.CONCATINATION && (
					<ConcatinationModal
						columnId={column._id}
						onSave={handleConcatenationSave}
						concatenation={mappedColumns.get(column._id)?.concatination ?? ''}
					/>
				)}
			</Box>
		</Modal>
	)
}

export const ColumnSettingsModal = React.memo(UnDecoratedColumnSettingsModal)
