/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'
import { V3ProjectSdk } from '@cango-app/sdk'

import { TaskProviderChildProps } from './types'

export const TaskContext = createContext<TaskProviderChildProps>({
	task: undefined,
	isLoadingTask: false,
	updateTask: async () => undefined,
	setNextTaskDbChains: () => [],
	nextTaskDbChains: {} as V3ProjectSdk.TaskDbChains,
	onClose: () => {},
	setSelectedOptions: () => {},
	selectedOptions: [],
	descendants: [],
})
