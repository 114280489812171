import { Stack } from '@mui/material'

import { Box, Modal, Text, Button } from 'src/components'

type Props = {
	isArchived: boolean
	open: boolean
	isLoading: boolean
	projectName: string
	onClose: () => void
	onArchive: () => void
}

export const ArchiveProjectModal = ({
	open,
	isArchived,
	onArchive,
	onClose,
	isLoading,
	projectName,
}: Props) => {
	return (
		<Modal open={open} onClose={onClose}>
			<Stack direction="column" spacing={2} width={450}>
				<Text variant="h4">
					{isArchived ? 'Restore:' : 'Archive:'} {projectName}
				</Text>
				<Text>Are you sure you want to {isArchived ? 'restore' : 'archive'} this project?</Text>
				<Box display="flex" justifyContent="space-between" flex={1} pt={3}>
					<Button variant="outlined" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant={isArchived ? 'contained' : 'outlined'}
						color="warning"
						onClick={onArchive}
						isLoading={isLoading}
					>
						{isArchived ? 'Restore' : 'Archive'} Project
					</Button>
				</Box>
			</Stack>
		</Modal>
	)
}
