import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { V3BlueprintTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import _uniq from 'lodash/uniq'
import { Alert, Badge, Stack } from '@mui/material'
import _isArray from 'lodash/isArray'
import _isString from 'lodash/isString'
import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined'
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined'
import { TIME_OPTIONS } from '@cango-app/sdk'
import Papa, { ParseResult } from 'papaparse'

import { Box, Button, IconButton, Select, TextField, Toggle, Text, Modal } from 'src/components'
import { TrashIcon } from 'src/assets/icons'
import { TableContext } from 'src/providers/table-provider'
import { TableFilters } from 'src/components/table-filters'

import { StepFormType } from './step-form-container'

type ChildItemProps = {
	index: number
	onRemove: () => void
	item: {
		_id: string
		label: string
		dueTime?: {
			time: string
			when: V3BlueprintTypes.WhenDueTime
		}
	}
	onPaste: (data: React.ClipboardEvent<HTMLDivElement>) => void
}

enum ListOriginType {
	Custom = 'Custom',
	DatabaseColumn = 'DatabaseColumn',
}

const ChildItem: ComponentType<ChildItemProps> = ({ item, onRemove, index, onPaste }) => {
	const { control, watch, setValue } = useFormContext<StepFormType>()
	const allOptions = watch('complete_options.options') as { _id: string; label: string }[]
	const [openTimeModal, setOpenTimeModal] = useState(false)

	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()

		const pastedText = e.clipboardData.getData('text')
		if (!pastedText.trim()) return

		const firstLine = pastedText.split('\n')[0].trim()
		setValue(`complete_options.options.${index}.label`, firstLine, {
			shouldDirty: true,
			shouldTouch: true,
		})
		if (onPaste) {
			onPaste(e)
		}
	}

	return (
		<>
			<Controller
				control={control}
				name={`complete_options.options.${index}.label`}
				rules={{
					required: 'Option cannot be empty',
					validate: () => {
						if (allOptions.some((_opt) => _opt._id !== item._id && _opt.label === item.label)) {
							return 'Option must be unique'
						}
						return true
					},
				}}
				render={({ field: { value, onChange }, fieldState: { error } }) => {
					return (
						<Box display="flex" alignItems="center">
							<TextField
								label={`Option ${index + 1}`}
								onChange={(e) => onChange(e.target.value)}
								value={value}
								fullWidth
								containerProps={{ mr: 1.5, my: 1.5, flex: 1 }}
								error={!!error}
								helperText={error?.message}
								onPaste={handlePaste}
							/>
							<IconButton
								onClick={() => setOpenTimeModal(true)}
								aria-label="Set recommended time for task"
							>
								{item?.dueTime ? (
									<Badge color="primary" badgeContent={item?.dueTime.time}>
										<AlarmOnOutlinedIcon />
									</Badge>
								) : (
									<AlarmOutlinedIcon />
								)}
							</IconButton>
							<IconButton onClick={onRemove}>
								<TrashIcon />
							</IconButton>
						</Box>
					)
				}}
			/>
			<Modal open={openTimeModal} onClose={() => setOpenTimeModal(false)}>
				<Stack direction="column" spacing={2}>
					<Text variant="h5">Set recommended time for this task:</Text>
					<Controller
						control={control}
						name={`complete_options.options.${index}.dueTime.time`}
						render={({ field: { value, onChange } }) => (
							<Select
								withNoneOption
								label="Time:"
								options={TIME_OPTIONS}
								value={value}
								onChange={onChange}
								disableOrdering
								helperText={
									<Box>
										<Text fontSize={12}>Task can be completed within the time and afterward</Text>
									</Box>
								}
							/>
						)}
					/>
					<Controller
						control={control}
						name={`complete_options.options.${index}.dueTime.when`}
						render={({ field: { value, onChange } }) => (
							<Select
								withNoneOption
								label="When:"
								options={Object.keys(V3BlueprintTypes.WhenDueTime).map((when) => ({
									_id: when,
									label: when,
								}))}
								value={value}
								onChange={onChange}
								disableOrdering
							/>
						)}
					/>
					<Stack flex={1} direction="row" justifyContent="space-between">
						<Button onClick={() => setOpenTimeModal(false)}>Close</Button>
						<Button
							variant="text"
							onClick={() => {
								setValue(`complete_options.options.${index}.dueTime`, undefined, {
									shouldDirty: true,
								})
								setOpenTimeModal(false)
							}}
						>
							Clear
						</Button>
					</Stack>
				</Stack>
			</Modal>
		</>
	)
}

// const ChainFilterGroup: ComponentType = () => {
// 	const { watch, control } = useFormContext<StepFormType>()
// 	const { remove, append, fields } = useFieldArray({ name: 'option_filters', control })
// 	const { threadMap } = useContext(ChainContext)
// 	const stepId = watch('_id')
//
// 	const threads = useMemo(() => {
// 		return threadMap.get(stepId) ?? []
// 	}, [threadMap, stepId])
//
// 	const handleCheckboxChange = (threadId: string) => {
// 		const existingField = fields.find((_field) => _field.thread_id === threadId)
// 		if (!existingField) {
// 			append({ _id: v4(), thread_id: threadId })
// 		}
// 		if (existingField) {
// 			const indexOfField = fields.findIndex((_field) => _field.thread_id === threadId)
// 			remove(indexOfField)
// 		}
// 	}
//
// 	if (!threads.length) {
// 		return null
// 	}
//
// 	return (
// 		<Box>
// 			<Text fontSize={14} color={colors.neutral['80']} fontWeight={500}>
// 				Filter options by thread?
// 			</Text>
// 			{threads.map((thread) => (
// 				<Box key={thread._id}>
// 					<Checkbox
// 						label={<ThreadList threads={[thread]} />}
// 						checked={fields.some((_field) => _field.thread_id === thread._id)}
// 						onChange={() => handleCheckboxChange(thread._id)}
// 					/>
// 				</Box>
// 			))}
// 		</Box>
// 	)
// }

export const UndecoratedOptionsFields: ComponentType = () => {
	const { control, watch, setValue } = useFormContext<StepFormType>()
	const [chainActions, options = []] = watch(['chain_actions', 'complete_options.options'])
	const { resolvedRows, mappedColumns, tableConfig } = useContext(TableContext)

	const hasArchiveTypeAction = useMemo(() => {
		return chainActions.some((action) =>
			action.actions.some((a) => a.type === V3BlueprintTypes.ActionEnum.Archive),
		)
	}, [chainActions])

	const columnHasDuplicatedValues = useMemo(() => {
		if (!options || _isArray(options)) {
			return false
		}
		if (!resolvedRows.length || !options) return false
		const columnValues = resolvedRows.reduce((_colValues: string[], _record) => {
			if (!_record[options]) {
				return _colValues
			}
			return [..._colValues, String(_record[options])]
		}, [])
		return _uniq(columnValues).length !== columnValues.length
	}, [options, resolvedRows])

	const handleOptionTypeChange = (oldValue: ListOriginType, newValue: ListOriginType) => {
		if (oldValue === newValue) {
			return
		}
		if (oldValue === ListOriginType.Custom) {
			setValue(
				'complete_options',
				{
					options: tableConfig?.principal_field ?? '',
					filter: {
						items: [],
					},
				},
				{ shouldDirty: true },
			)
			return
		}
		setValue(
			'complete_options',
			{
				options: [],
			},
			{ shouldDirty: true },
		)
	}

	const handleRemoveItem = (_id: string) => {
		if (!_isArray(options)) {
			return
		}
		setValue(
			'complete_options.options',
			options.filter((option) => option._id !== _id),
			{ shouldDirty: true },
		)
	}

	const handleNewItem = () => {
		if (!_isArray(options)) {
			return
		}
		setValue(
			'complete_options.options',
			[
				...options,
				{
					_id: v4(),
					label: '',
				},
			],
			{ shouldDirty: true },
		)
	}

	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, index: number) => {
		e.preventDefault()
		e.stopPropagation() // prevents it's handled by onPaste on core table component
		const clipboardData = e.clipboardData
		const pastedData = clipboardData.getData('text')
		if (!pastedData || typeof options === 'string') return
		const existingOptions = [...(options ?? [])]

		Papa.parse(pastedData as any, {
			delimiter: '\t',
			newline: '\n',
			skipEmptyLines: true,
			quoteChar: '"',
			complete: (results: ParseResult<string[]>) => {
				const rowsByCell = results.data as string[][]
				const allValues = rowsByCell.map((_row) => _row[0])
				let indexTicker = index
				for (const data of allValues) {
					if (existingOptions[indexTicker]) {
						existingOptions[indexTicker] = { ...existingOptions[indexTicker], label: data }
					} else {
						existingOptions.push({ _id: v4(), label: data })
					}
					indexTicker++
				}
			},
		})
		setValue('complete_options.options', existingOptions, { shouldDirty: true, shouldTouch: true })
	}

	if (hasArchiveTypeAction) {
		return null
	}

	return (
		<Box mt={2}>
			<Controller
				control={control}
				name="complete_options.options"
				render={({ field: { value } }) => {
					const currentOptionType = _isString(value)
						? ListOriginType.DatabaseColumn
						: ListOriginType.Custom
					return (
						<Toggle
							value={currentOptionType}
							onChange={(newValue) =>
								handleOptionTypeChange(currentOptionType, newValue as ListOriginType)
							}
							options={[
								{ value: ListOriginType.Custom, label: 'Custom' },
								{ value: ListOriginType.DatabaseColumn, label: 'Database Column' },
							]}
						/>
					)
				}}
			/>
			<Box>
				{_isString(options) ? (
					<Box my={2}>
						<Controller
							control={control}
							name="complete_options.options"
							render={({ field: { value, onChange } }) => (
								<Select
									options={[...mappedColumns.keys()].map((_columnKey) => ({
										_id: _columnKey,
										label: mappedColumns.get(_columnKey)?.name ?? '',
									}))}
									value={value}
									onChange={onChange}
									containerProps={{ mb: 2 }}
									label="Column"
								/>
							)}
						/>
						{columnHasDuplicatedValues && (
							<Alert severity="warning">This column has duplicated values</Alert>
						)}
						<Controller
							control={control}
							name="complete_options.filter"
							render={({ field: { value, onChange } }) => (
								<Box mt={1}>
									<TableFilters
										initialState={{
											filter: { filterModel: value },
										}}
										onChange={onChange}
									/>
								</Box>
							)}
						/>
					</Box>
				) : (
					<>
						{options.map((item, index) => {
							return (
								<Box key={item._id}>
									<ChildItem
										index={index}
										onRemove={() => handleRemoveItem(item._id)}
										item={item}
										onPaste={(e) => handlePaste(e, index)}
									/>
								</Box>
							)
						})}
						<Button sx={{ my: 2, mt: 1 }} variant="outlined" size="small" onClick={handleNewItem}>
							Add an option
						</Button>
					</>
				)}

				<Controller
					name={'isMenu'}
					control={control}
					render={({ field: { onChange, value } }) => (
						<Toggle
							label="How many options can be chosen?"
							value={value}
							options={[
								{
									label: 'Only one',
									value: false,
								},
								{
									label: 'Multiple',
									value: true,
								},
							]}
							onChange={onChange}
							containerProps={{ mb: 1.5 }}
						/>
					)}
				/>
			</Box>
		</Box>
	)
}

export const OptionsFields = React.memo(UndecoratedOptionsFields)
