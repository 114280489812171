import { V3ClientTypes } from '@cango-app/types'
import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { Stack } from '@mui/material'
import juice from 'juice'
import { Page, Document, usePDF, Font } from '@react-pdf/renderer'
import { Html } from 'react-pdf-html'
import { PulseLoader } from 'react-spinners'

import { Modal, Box, Button, TipTapContentWithStyles } from 'src/components'
import { useResolveTemplate } from 'src/hooks/useResolveTemplate'
import { TableContext } from 'src/providers'
import { useColumnFormatter } from 'src/modules/tables/use-column-formatter'
import { useRichEditor, EditorProvider } from 'src/modules/templates-editor/useRichEditor'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { EditorOptions } from 'src/modules/templates-editor/editor-options'

Font.register({
	family: 'Open Sans',
	src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf',
})

const EditorComponent = ({ templateName }: { templateName: string }) => {
	const { editor } = useRichEditor()
	const [instance, setInstance] = usePDF()

	const getContentWithStyles = useCallback(async () => {
		if (!editor) return ''
		const htmlContent = editor.getHTML()

		const response = await fetch('/rich-editor-styles.css')

		if (!response.ok) {
			showSnackbar('Error getting table styles', { variant: 'error' })
			return ''
		}
		const styles = await response.text()

		return `
        <style>${styles}</style>
      <div class="tableWrapper">
      ${htmlContent}</div>
    `
	}, [editor])

	const onCopyContent = useCallback(async () => {
		const fullHtmlContent = await getContentWithStyles()
		const htmlWithInlineStyles = juice(fullHtmlContent)
		const blob = new Blob([htmlWithInlineStyles], { type: 'text/html' })
		const clipboardItem = new ClipboardItem({ 'text/html': blob })
		navigator.clipboard
			.write([clipboardItem])
			.then(() => {
				showSnackbar('Template copied!', { variant: 'success' })
			})
			.catch(() => {
				showSnackbar('Failed to copy template', { variant: 'error' })
			})
	}, [getContentWithStyles])

	if (!editor) return null

	useEffect(() => {
		if (instance.blob) {
			const link = document.createElement('a')
			link.href = URL.createObjectURL(instance.blob)
			link.download = `${templateName}.pdf`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}, [instance])

	const handleDownloadPDF = useCallback(async () => {
		const response = await fetch('/rich-editor-styles.css')

		if (!response.ok) {
			showSnackbar('Error getting table styles', { variant: 'error' })
			return
		}
		const styles = await response.text()

		const html = `
      <html>
        <body>
          <style>${styles}</style>
          <div class="tableWrapper template">${editor?.getHTML()}</div>
        </body>
      </html>`

		const newContent = (
			<Document>
				<Page size="LETTER" style={{ padding: 20 }}>
					<Html resetStyles={true}>{html}</Html>
				</Page>
			</Document>
		)
		setInstance(newContent)
	}, [editor])
	return (
		<Box width="70vw">
			<EditorOptions />
			<TipTapContentWithStyles editor={editor} />
			<Stack marginTop={2} width="450px" direction="row" spacing={2}>
				<Button onClick={onCopyContent}>Copy Template</Button>
				<Button onClick={handleDownloadPDF}>Download PDF</Button>
			</Stack>
		</Box>
	)
}

type Props = {
	action: V3ClientTypes.Project.TaskAction
}

export const Template: ComponentType<Props> = ({ action }) => {
	const { tableConfig, resolvedRows } = useContext(TableContext)
	const apiRef = useGridApiRef()
	const { content } = useResolveTemplate({ template: action.template })
	const { columns } = useColumnFormatter({
		apiRef,
		isTableLocked: true,
		showIcons: false,
	})

	if (!action.template || !content) return null
	if (!tableConfig) return <PulseLoader />
	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				getRowId={(row) => row._id}
				columns={columns}
				rows={resolvedRows}
				sx={{
					display: 'none',
				}}
			/>
			<EditorProvider defaultContent={content}>
				<EditorComponent templateName={action.template.template.name} />
			</EditorProvider>
		</>
	)
}

export const TemplateModal = ({ action }: Props) => {
	const [open, setOpen] = useState(false)
	const { tableConfig } = useContext(TableContext)

	if (!action.template) return null

	return (
		<>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Template action={action} />
			</Modal>
			<Button variant="outlined" onClick={() => setOpen(true)} disabled={!tableConfig}>
				Open Template
			</Button>
		</>
	)
}
