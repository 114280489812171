import {
	ComponentType,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _forEach from 'lodash/forEach'
import { TableTypes } from '@cango-app/types'
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-premium'
import _isNumber from 'lodash/isNumber'
import _debounce from 'lodash/debounce'
import { AxiosError, TablesSdk } from '@cango-app/sdk'
import _isEmpty from 'lodash/isEmpty'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import { getLinkedTable, resolveAnyRowCalculations } from 'src/modules/tables/utils'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as imageSelectors, actions as imageActions } from 'src/store/modules/images'
import { MenuTableWithLinkedTables } from 'src/modules/tables/types'
import { snackbarActions } from 'src/providers/snackbar-actions'
import { RootState } from 'src/store/types'

import {
	selectColumnList,
	selectLinkedTable,
	selectMappedColumns,
	selectMappedRecords,
	selectMappedRowData,
	selectResolvedRows,
	selectColumnFilterList,
	selectConfig,
	selectFields,
	selectCachedRowIds,
	selectedMappedBlueprintRecords,
	selectValueOptions,
} from './selectors'
import {
	TableProviderProps,
	TaskProviderChildProps,
	TableActionType,
	UpdateRecordsRequest,
	TableState,
} from './types'
import TableContext from './context'
import { initialTableReducerState, tableReducer } from './reducer'

const TableProviderContainer: ComponentType<PropsWithChildren<TableProviderProps>> = (props) => {
	const storeDispatch = useDispatch()
	const [state, dispatch] = useReducer(tableReducer, initialTableReducerState)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const images = useSelector(imageSelectors.getMappedImages)
	const tableId = props.tableId
	const columns = useSelector((rootState: RootState) => selectFields(state, rootState))
	const mappedColumns = useSelector((rootState: RootState) => selectMappedColumns(state, rootState))
	const mappedRecords = useSelector((rootState: RootState) => selectMappedRecords(state, rootState))
	const mappedRowData = useSelector((rootState: RootState) => selectMappedRowData(state, rootState))
	const resolvedRows = useSelector((rootState: RootState) => selectResolvedRows(state, rootState))
	const columnList = useSelector((rootState: RootState) => selectColumnList(state, rootState))
	const columnFilterList = useSelector((rootState: RootState) =>
		selectColumnFilterList(state, rootState),
	)
	const mappedValueOptions = useSelector((rootState: RootState) =>
		selectValueOptions(state, rootState),
	)

	const tableConfig = useMemo(() => selectConfig(state), [state])
	const linkedQuestionaire = useMemo(() => selectLinkedTable(state, tableId), [state])
	const cachedRowIds = useMemo(() => selectCachedRowIds(state), [state])
	const blueprintRecords = useMemo(() => selectedMappedBlueprintRecords(state), [state])
	const referenceTables = useMemo(() => state.referenceTables, [state])

	const showCircularReferenceWarning = useCallback((circularReference: string) => {
		showSnackbar(`Circular reference detected\n${circularReference}`, {
			variant: 'error',
			autoHideDuration: 10000,
			action: snackbarActions,
			style: { whiteSpace: 'pre-line' },
		})
	}, [])

	const fetchTableList = useCallback(async () => {
		try {
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					isFetchingTableList: true,
				},
			})
			const response = await TablesSdk.getTables(import.meta.env.VITE_API as string, authHeaders)
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					isFetchingTableList: false,
					tableList: response.map((_table) => ({
						..._table,
						linkedTable: getLinkedTable(_table, response),
					})),
				},
			})
		} catch (error) {
			showSnackbar('Could not fetch tables', { variant: 'error' })
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					isFetchingTableList: false,
				},
			})
		}
	}, [authHeaders])

	const saveChanges = async (_state: TableState) => {
		if (!_state.config) return

		if (
			_isEmpty(_state.recordsBeforeChange) &&
			!_state.newRecords.length &&
			!_state.configBeforeChange
		) {
			return
		}

		try {
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					isUpdating: true,
				},
			})

			const updatedRecords = Object.keys(_state.recordsBeforeChange).reduce(
				(_records: TableTypes.Record[], _id) => {
					const resolvedRow = _state.records.find((_record) => _record._id === _id)
					if (!resolvedRow) {
						return _records
					}
					return [
						..._records,
						{
							...resolvedRow,
							calculations: resolvedRow.calculations ?? {},
						},
					]
				},
				[],
			)

			const newRows = _state.newRecords.reduce((_records: TableTypes.Record[], _id) => {
				const resolvedRow = _state.records.find((_record) => _record._id === _id)
				if (!resolvedRow) {
					return _records
				}
				return [..._records, resolvedRow]
			}, [])

			if (!_isEmpty(_state.configBeforeChange)) {
				await TablesSdk.updateTableConfig(
					import.meta.env.VITE_API as string,
					authHeaders,
					_state.config._id,
					_state.config,
				)
			}
			if (updatedRecords.length || newRows.length) {
				await TablesSdk.updateRecords(
					import.meta.env.VITE_API as string,
					authHeaders,
					_state.config._id,
					{
						records: updatedRecords,
						newRecords: newRows,
					},
				)
			}

			dispatch({
				type: TableActionType.UPDATE_AND_REMOVE,
				payload: {
					recordsBeforeChange: _state.recordsBeforeChange,
					newRecords: _state.newRecords,
				},
				update: {
					configBeforeChange: null,
					isUpdating: false,
				},
			})
		} catch (error) {
			errorHandler({ error, dispatch: storeDispatch, message: 'Could not update table' })
		}
	}

	const debouncedSaveChanges = _debounce(async (state: TableState) => {
		await saveChanges(state)
	}, 1500)

	const resetCachedRecord = (recordIds: string[]) => {
		const updatedRecordsBeforeChange = { ...state.recordsBeforeChange }
		let updatedNewRows = [...state.newRecords]
		const updatedRecords = state.records.reduce((records: TableTypes.Record[], _record) => {
			if (!recordIds.includes(_record._id)) {
				return [...records, _record]
			}
			const previousRecord = updatedRecordsBeforeChange[_record._id]
			if (previousRecord) {
				delete updatedRecordsBeforeChange[_record._id]
				return [...records, previousRecord]
			}
			if (updatedNewRows.includes(_record._id)) {
				updatedNewRows = updatedNewRows.filter((_id) => _id !== _record._id)
			}
			return records
		}, [])

		dispatch({
			type: TableActionType.UPDATE_STATE,
			payload: {
				records: updatedRecords,
				recordsBeforeChange: updatedRecordsBeforeChange,
				newRecords: updatedNewRows,
			},
		})
	}

	const updateRecords: UpdateRecordsRequest = async ({
		rows: rowUpdates,
		newRows,
		field: fieldUpdate,
		save,
		config,
		resetRecords,
	}): Promise<TableTypes.Record[]> => {
		if (!state.config?._id) {
			return rowUpdates.map(({ oldRecord }) => oldRecord)
		}
		const isLockedMap = new Map(state.fields.map((_field) => [_field._id, _field.locked]))

		const resolvedRows: Record<string, TableTypes.Record> = {}
		const previousRecords: Record<string, TableTypes.Record> = state.recordsBeforeChange ?? {}
		const previousConfig: Omit<TableTypes.CangoTable, 'records' | 'fields'> | null =
			state.configBeforeChange ?? state.config

		const recordsUpdatedByField: Record<string, TableTypes.Record> = {}
		if (fieldUpdate) {
			const { _id: fieldId, ...rest } = fieldUpdate
			const response = await TablesSdk.updateField(
				import.meta.env.VITE_API as string,
				authHeaders,
				state.config._id,
				fieldId,
				rest,
			)
			if (rest.type === TableTypes.FieldType.UNIQUE_ID && response.records) {
				response.records.forEach((_record) => {
					recordsUpdatedByField[_record._id] = _record
				})
			}
		}

		for (const { newRecord, oldRecord } of rowUpdates) {
			if (!previousRecords[newRecord._id]) {
				previousRecords[oldRecord._id] = oldRecord
			}

			const row = { ...newRecord }

			_forEach(row.data, (value, id) => {
				const isLocked = isLockedMap.get(id)
				let newValue = value
				if (isLocked) {
					newValue = oldRecord.data[id]
				}
				row.data[id] = newValue
			})

			const resolved = resolveAnyRowCalculations({
				row,
				fields: columns,
				referenceColumns: state.config.referenceColumnNames,
				questionaireAnswers: state.config.questionaire_answers ?? [],
				images,
				vLookupTables: state.config.vLookupTables,
			})

			resolvedRows[row._id] = { ...row, data: resolved }
		}

		const resolvedNewRows =
			newRows?.map((_row) => {
				const resolved = resolveAnyRowCalculations({
					row: _row,
					fields: columns,
					referenceColumns: state.config!.referenceColumnNames,
					questionaireAnswers: state.config!.questionaire_answers ?? [],
					images,
					vLookupTables: state.config!.vLookupTables,
				})

				return { ..._row, data: resolved }
			}) ?? []

		let updatedNewRows = [...state.newRecords]
		const updatedRecords = state.records.map((_record) => {
			const updatedRow = resolvedRows[_record._id]
			if (
				recordsUpdatedByField[_record._id] &&
				fieldUpdate?.type === TableTypes.FieldType.UNIQUE_ID
			) {
				updatedRow.data[fieldUpdate._id] = recordsUpdatedByField[_record._id].data[fieldUpdate._id]
				_record.data[fieldUpdate._id] = recordsUpdatedByField[_record._id].data[fieldUpdate._id]
				return updatedRow
			}
			if (updatedRow) {
				return updatedRow
			}
			if (resetRecords?.includes(_record._id)) {
				const previousRecord = previousRecords[_record._id]
				if (previousRecord) {
					delete previousRecords[_record._id]
					return previousRecord
				}
				if (updatedNewRows.includes(_record._id)) {
					updatedNewRows = updatedNewRows.filter((_id) => _id !== _record._id)
				}
			}
			return _record
		})

		const records = [...updatedRecords, ...resolvedNewRows]

		const rowOrder = state.config?.row_order
			? [...state.config.row_order, ...resolvedNewRows.map(({ _id }) => _id)]
			: [...records.map(({ _id }) => _id)]

		const stateCopy: TableState = {
			...state,
			...{
				records,
				recordsBeforeChange: previousRecords,
				newRecords: updatedNewRows,
				config: {
					...state.config,
					...config,
				},
				...(newRows?.length && {
					config: {
						...state.config,
						row_order: rowOrder,
						...config,
					},
					configBeforeChange: previousConfig,
					newRecords: [...updatedNewRows, ...newRows.map(({ _id }) => _id)],
				}),
				...(fieldUpdate && {
					fields: state.fields.map((_field) => {
						if (_field._id === fieldUpdate._id) {
							return {
								..._field,
								...fieldUpdate,
							}
						}
						return _field
					}),
				}),
			},
		}

		dispatch({
			type: TableActionType.UPDATE_STATE,
			payload: stateCopy,
		})

		if (save) {
			debouncedSaveChanges(stateCopy)
		}

		return records
	}

	const hardUpdateRecords = useCallback(
		async (records: Omit<TableTypes.Record, 'references'>[]) => {
			if (!state.config?._id) {
				return
			}
			const previousRows = [...state.records]
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					isUpdating: true,
					records: previousRows.map((_record) => {
						const updatedRecord = records.find(({ _id }) => _record._id === _id)
						if (updatedRecord) {
							return { ..._record, ...updatedRecord }
						}
						return _record
					}),
				},
			})
			try {
				await TablesSdk.updateRecords(
					import.meta.env.VITE_API as string,
					authHeaders,
					state.config._id,
					{
						records,
						newRecords: [],
					},
				)
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isUpdating: false,
					},
				})
			} catch (error) {
				showSnackbar('Error updating records', { variant: 'error' })
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						records: previousRows,
						isUpdating: false,
					},
				})
			}
		},
		[authHeaders, state.records, state.config?._id],
	)

	const updateTableListItem = useCallback(
		async (tableId: string, data: Partial<TablesSdk.MenuTable & { _action: 'delete' | 'new' }>) => {
			const previousTableList = [...state.tableList]
			try {
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						tableList: [
							...previousTableList.reduce((_acc: MenuTableWithLinkedTables[], _table) => {
								if (data._action === 'delete' && _table._id === tableId) {
									return _acc
								}
								if (_table._id === tableId) {
									return [
										..._acc,
										{
											..._table,
											...data,
										},
									]
								}
								return [..._acc, _table]
							}, []),
							...(data._action === 'new'
								? [
										{
											linkedTable: getLinkedTable(data as TablesSdk.MenuTable, previousTableList),
											...(data as TablesSdk.MenuTable),
											_id: tableId,
										},
									]
								: []),
						],
					},
				})
				if (!data._action) {
					await TablesSdk.updateTableConfig(
						import.meta.env.VITE_API as string,
						authHeaders,
						tableId,
						data,
					)
				}
			} catch (error) {
				showSnackbar('Error updating table', { variant: 'error' })
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						tableList: previousTableList,
					},
				})
			}
		},
		[authHeaders, state.tableList],
	)

	const discardAllChanges = useCallback(() => {
		dispatch({
			type: TableActionType.UPDATE_STATE,
			payload: {
				records: state.records.reduce((records: TableTypes.Record[], _record) => {
					if (state.newRecords.includes(_record._id)) {
						return records
					}
					const recordBeforeChange = state.recordsBeforeChange[_record._id]
					if (recordBeforeChange) {
						return [...records, recordBeforeChange]
					}
					return [...records, _record]
				}, []),
				newRecords: [],
				recordsBeforeChange: {},
				config: state.configBeforeChange ?? state.config,
				configBeforeChange: null,
			},
		})
	}, [state])

	const onDeleteRecords = useCallback(
		async (selectedRows: Map<GridRowId, GridValidRowModel>) => {
			if (!state.config?._id) return
			const previousRecords = [...state.records]
			const recordUpdates = previousRecords.reduce<TablesSdk.UpdateRecordsRequest['records']>(
				(acc: Omit<TableTypes.Record, 'references'>[], record) => {
					if (record.descendants?.some(({ row }) => row && selectedRows.has(row))) {
						return [
							...acc,
							{
								...record,
								_id: record._id,
								descendants: record.descendants.filter(({ row }) => row && !selectedRows.has(row)),
							},
						]
					}

					if (!selectedRows.has(record._id)) {
						return acc
					}

					return [
						...acc,
						{
							...record,
							_id: record._id,
							calculations: {},
							data: {
								...record.data,
								_action: 'delete',
							},
						},
					]
				},
				[],
			)
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					records: state.records.reduce((_acc: TableTypes.Record[], _record) => {
						if (selectedRows.has(_record._id)) {
							return _acc
						}
						if (_record.descendants?.some(({ row }) => row && selectedRows.has(row))) {
							return [
								..._acc,
								{
									..._record,
									descendants: _record.descendants.filter(
										({ row }) => row && !selectedRows.has(row),
									),
								},
							]
						}
						return [..._acc, _record]
					}, []),
					isUpdating: true,
				},
			})
			try {
				await TablesSdk.updateRecords(
					import.meta.env.VITE_API as string,
					authHeaders,
					state.config._id,
					{
						newRecords: [],
						records: recordUpdates,
					},
				)
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isUpdating: false,
					},
				})
			} catch (err) {
				showSnackbar('Error deleting records', { variant: 'error' })
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						records: previousRecords,
						isUpdating: false,
					},
				})
			}
		},
		[state.config?._id, authHeaders, state.records],
	)

	const updateTableConfig = useCallback(
		async (data: TablesSdk.UpdateTableConfigRequest, save?: boolean) => {
			if (!state.config?._id) return
			const previousConfig = { ...state.config }
			try {
				const updatedState = {
					isUpdating: !!save,
					config: {
						...state.config,
						...data,
					},
					configBeforeChange:
						!save && !state.configBeforeChange ? previousConfig : state.configBeforeChange,
					...(data.type !== undefined && {
						tableList: state.tableList.map((_table) => {
							if (_table._id === state.config?._id && data.type) {
								return {
									..._table,
									type: data.type,
								}
							}
							return _table
						}),
					}),
				}

				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: updatedState,
				})

				const updatedRecords: TableTypes.Record[] = []
				if (save) {
					const response = await TablesSdk.updateTableConfig(
						import.meta.env.VITE_API as string,
						authHeaders,
						state.config._id,
						data,
					)
					if (response.records) {
						updatedRecords.push(...response.records)
					}
					dispatch({
						type: TableActionType.UPDATE_STATE,
						payload: {
							isUpdating: false,
							...(updatedRecords.length
								? {
										records: state.records.map((_record) => {
											const updatedRecord = updatedRecords.find(({ _id }) => _record._id === _id)
											if (updatedRecord) {
												return { ..._record, ...updatedRecord }
											}
											return _record
										}),
									}
								: {}),
						},
					})
				}
			} catch (err) {
				showSnackbar('Error updating table', { variant: 'error' })
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						config: previousConfig,
						isUpdating: false,
					},
				})
			}
		},
		[
			state.config?._id,
			authHeaders,
			state.records,
			state.tableList,
			state.config,
			state.configBeforeChange,
		],
	)

	const handleAddRow = useCallback(
		async (data: { rowOrder: string[]; parentId?: string; newRowId: string }) => {
			if (!state.config?._id) return
			const { rowOrder, parentId, newRowId } = data
			const previousRecords = [...state.records]
			const recordsClone = [...state.records]
			recordsClone.push({ _id: newRowId, data: {}, calculations: {}, references: {} })
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					isUpdating: true,
					config: {
						...state.config,
						row_order: rowOrder,
					},
					records: recordsClone,
				},
			})
			try {
				const { newRow, newDescendant } = await TablesSdk.addRow(
					import.meta.env.VITE_API as string,
					authHeaders,
					state.config._id,
					{
						parentId,
						rowId: newRowId,
						row_order: rowOrder,
					},
				)

				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isUpdating: false,
						records: recordsClone.map((_record) => {
							if (_record._id === newRowId) {
								return newRow
							}
							if (_record._id === parentId && newDescendant) {
								return {
									..._record,
									descendants: [...(_record.descendants ?? []), newDescendant],
								}
							}
							return _record
						}),
					},
				})
			} catch (error) {
				errorHandler({ error, dispatch: storeDispatch, message: 'Could not add row' })
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						records: previousRecords,
						isUpdating: false,
					},
				})
			}
		},
		[state.config, authHeaders, state.records],
	)

	const handleAddColumn = useCallback(async () => {
		if (!state.config?._id) return
		dispatch({
			type: TableActionType.UPDATE_STATE,
			payload: {
				isUpdating: true,
			},
		})
		try {
			const { column } = await TablesSdk.addColumn(
				import.meta.env.VITE_API as string,
				authHeaders,
				state.config._id,
			)
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					isUpdating: false,
					fields: [...state.fields, column],
					config: {
						...state.config,
						column_order: [...(state.config.column_order ?? [...mappedColumns.keys()]), column._id],
					},
				},
			})
		} catch (error) {
			let errorMessage = 'Could not add column'
			if ((error as AxiosError<{ message?: string }>).response?.data?.message) {
				errorMessage = (error as AxiosError<{ message?: string }>).response?.data?.message as string
			}
			errorHandler({ error, dispatch: storeDispatch, message: errorMessage })
			dispatch({
				type: TableActionType.UPDATE_STATE,
				payload: {
					isUpdating: false,
				},
			})
		}
	}, [state.config, authHeaders, state.fields, mappedColumns])

	const handleDeleteColumn = useCallback(
		async (fieldId: string) => {
			if (!state.config?._id) return
			const previousFields = [...state.fields]
			const previousColumnOrder = [...state.config.column_order]
			try {
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isUpdating: true,
						fields: state.fields.filter(({ _id }) => fieldId !== _id),
						config: {
							...state.config,
							column_order: state.config.column_order.filter((_id) => fieldId !== _id),
						},
					},
				})
				await TablesSdk.deleteField(import.meta.env.VITE_API as string, authHeaders, {
					tableId: state.config._id,
					fieldId,
				})
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isUpdating: false,
					},
				})
			} catch (error) {
				errorHandler({ error, dispatch: storeDispatch, message: 'Could not delete column' })
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						fields: previousFields,
						config: {
							...state.config,
							column_order: previousColumnOrder,
						},
						isUpdating: false,
					},
				})
			}
		},
		[state.config, authHeaders, state.fields],
	)

	const handleUpdateColumn = useCallback(
		async (
			fieldId: string,
			updates: Omit<TablesSdk.UpdateFieldRequest, 'tableId'>,
			otherFields?: Partial<Omit<TableState, 'fields' | 'records'>>,
		): Promise<{ result: 'success' | 'error' }> => {
			if (!state.config?._id) return { result: 'error' }
			if (fieldId.includes('_answer')) {
				return { result: 'success' }
			}
			const previousColumns = [...state.fields]
			const column = mappedColumns.get(fieldId)
			if (_isNumber(updates.width) && updates.width === column?.width) {
				return { result: 'error' }
			}

			try {
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isUpdating: true,
						fields: state.fields.map((_field) => {
							if (_field._id === fieldId) {
								return {
									..._field,
									...updates,
								}
							}
							return _field
						}),
					},
				})
				const response = await TablesSdk.updateField(
					import.meta.env.VITE_API as string,
					authHeaders,
					state.config._id,
					fieldId,
					updates,
				)
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isUpdating: false,
						fields: state.fields.map((_field) => {
							if (_field._id === fieldId) {
								return response.field
							}
							return _field
						}),
						records: response.records,
						...otherFields,
					},
				})
				showSnackbar('Column updated', { variant: 'success' })
				return { result: 'success' }
			} catch (error) {
				errorHandler({ error, dispatch: storeDispatch, message: 'Could not update column' })
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						fields: previousColumns,
						isUpdating: false,
					},
				})
				return { result: 'error' }
			}
		},
		[state.config, authHeaders, state.fields],
	)

	const saveNewReferenceTables = (referenceTables: Record<string, TableTypes.CangoTable>) => {
		dispatch({
			type: TableActionType.UPDATE_STATE,
			payload: {
				...state.referenceTables,
				referenceTables,
			},
		})
	}

	const fetchBlueprintTable = useCallback(
		async (blueprintTableId: string) => {
			try {
				const response = await TablesSdk.getTable(
					import.meta.env.VITE_API as string,
					authHeaders,
					blueprintTableId,
				)
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						blueprintTable: response.table,
					},
				})
			} catch (error) {
				showSnackbar('Error fetching blueprint table', { variant: 'error' })
			}
		},
		[authHeaders],
	)

	const fetchTable = useCallback(
		async (newTableId: string, forceFetch?: boolean) => {
			try {
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isFetching: true,
						config: null,
						fields: [],
						records: [],
						recordsBeforeChange: {},
						newRecords: [],
						configBeforeChange: null,
						blueprintTable: null,
					},
				})
				const {
					table: { fields, records, ...config },
					circular_reference,
				} = await TablesSdk.getTable(import.meta.env.VITE_API as string, authHeaders, newTableId, {
					build_type: forceFetch ? 'build' : undefined,
				})
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isFetching: false,
						numberOfFetchTableRetries: 0,
						config,
						fields,
						records,
					},
				})
				if (circular_reference) {
					showCircularReferenceWarning(circular_reference)
				}
				storeDispatch(imageActions.getImages())
				if (config.parentTable) {
					fetchBlueprintTable(config.parentTable)
				}
			} catch (error) {
				if ((error as AxiosError).response?.status === 404) {
					showSnackbar('Table not found', { variant: 'error' })
					return
				}
				errorHandler({ error, dispatch: storeDispatch, message: 'Could not fetch table' })
				dispatch({
					type: TableActionType.UPDATE_STATE,
					payload: {
						isFetching: false,
						hasError: true,
						numberOfFetchTableRetries: state.numberOfFetchTableRetries + 1,
					},
				})
			}
		},
		[authHeaders, state],
	)

	const switchTable = async (_tableId: string) => {
		if (
			!_isEmpty(state.recordsBeforeChange) ||
			!state.newRecords.length ||
			!!state.configBeforeChange
		) {
			await debouncedSaveChanges(state)
		}
		discardAllChanges()
		await fetchTable(_tableId)
	}

	useEffect(() => {
		dispatch({
			type: TableActionType.UPDATE_STATE,
			payload: {
				hasError: false,
				numberOfFetchTableRetries: 0,
			},
		})
	}, [tableId])

	useEffect(() => {
		if (state.hasError && state.numberOfFetchTableRetries >= 2) return
		if (tableId && state.config?._id !== tableId && !state.isFetching) {
			switchTable(tableId)
		}
	}, [tableId, state.config?._id, state.isFetching, state.hasError])

	useEffect(() => {
		fetchTableList()
	}, [])

	const contextValue: TaskProviderChildProps = {
		tableConfig,
		isLoadingTable: state.isFetching,
		saveChanges: () => debouncedSaveChanges(state),
		hardSave: async () => await saveChanges(state),
		isUpdatingTable: state.isUpdating,
		discardAllChanges,
		onAddRow: handleAddRow,
		onAddColumn: handleAddColumn,
		columnList,
		columnFilterList,
		mappedColumns,
		mappedRowData,
		mappedRecords,
		onUpdateColumn: handleUpdateColumn,
		onDeleteColumn: handleDeleteColumn,
		onDeleteRecords,
		updateTableConfig,
		resolvedRows,
		updateRecords,
		hardUpdateRecords,
		columns,
		cachedRowIds,
		blueprintRecords,
		saveNewReferenceTables,
		referenceTables,
		mappedValueOptions,

		// table browser
		tableList: state.tableList,

		//loading states
		isFetchingTableList: state.isFetchingTableList,
		updateTableListItem,
		linkedTable: linkedQuestionaire,
		fetchTable,
		resetCachedRecord,
	}

	return <TableContext.Provider value={contextValue}>{props.children}</TableContext.Provider>
}

export default TableProviderContainer
