import { Controller, useForm } from 'react-hook-form'
import { ComponentType, useCallback, useContext, useMemo } from 'react'
import _isArray from 'lodash/isArray'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { TableContext } from 'src/providers'

import { Button, Grid, Select } from '../../../../components'

import { ConfigureFormulaForm, FormulaType } from './types'
import {
	areOnlyOneParameter,
	closeBracket,
	closeSquareBracket,
	conditionOptions,
	getNumberColumns,
	openBracket,
	openSquareBracket,
} from './utils'

type CreateFormulaProps = {
	activeOperator: FormulaType
	onAddField: (slice: TableTypes.FormulaSlice) => void
}

const initialFormValues = {
	fields: [],
	condition: '',
	comparator: '',
}

export const CreateFormula: ComponentType<CreateFormulaProps> = ({
	activeOperator,
	onAddField,
}) => {
	const { mappedColumns } = useContext(TableContext)
	const { control, handleSubmit, reset } = useForm<ConfigureFormulaForm>({
		defaultValues: initialFormValues,
	})

	const columns = useMemo(() => {
		const numberColumns = getNumberColumns(Array.from(mappedColumns.values()))
		return numberColumns.map((column) => ({
			_id: column._id,
			label: column.name,
		}))
	}, [mappedColumns])

	const addFormula = useCallback(
		(data: ConfigureFormulaForm) => {
			if (!activeOperator) return
			const formulaAsArray = _isArray(data.fields) ? data.fields : [data.fields]
			const flatExpression = formulaAsArray.flatMap((variable, index) => {
				if (index === formulaAsArray.length - 1) {
					return [
						{
							type: TableTypes.FormulaSliceType.FIELD,
							value: mappedColumns.get(variable)?._id || '',
						},
					]
				}
				return [
					{
						type: TableTypes.FormulaSliceType.FIELD,
						value: mappedColumns.get(variable)?._id || '',
					},
					{ type: TableTypes.FormulaSliceType.OPERATOR, value: 'COMMA' },
				]
			})

			let expressions: { type: TableTypes.FormulaSliceType; value: string }[]

			if (activeOperator.includes('IF')) {
				expressions = [
					{
						type: TableTypes.FormulaSliceType.OPERATOR,
						value: activeOperator,
					},
					openBracket,
					openSquareBracket,
					...flatExpression,
					closeSquareBracket,
					{
						type: TableTypes.FormulaSliceType.OPERATOR,
						value: 'COMMA',
					},
					{
						type: TableTypes.FormulaSliceType.OPERATOR,
						value: data.condition,
					},
					{
						type: TableTypes.FormulaSliceType.FIELD,
						value: data.comparator,
					},
					closeBracket,
				]
			} else {
				expressions = [
					{
						type: TableTypes.FormulaSliceType.OPERATOR,
						value: activeOperator,
					},
					openBracket,
					...flatExpression,
					closeBracket,
				]
			}

			for (const expression of expressions) {
				onAddField({
					id: v4(),
					...expression,
				})
			}
			reset(initialFormValues)
		},
		[activeOperator],
	)

	return (
		<>
			<Grid
				container
				columnSpacing={2}
				display="flex"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Grid item xs={4}>
					<Controller
						name="fields"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Select
								label="Fields"
								multiple={!areOnlyOneParameter.includes(activeOperator ?? '')}
								options={columns}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>
				{activeOperator.includes('IF') && (
					<>
						<Grid item xs={3}>
							<Controller
								name="condition"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										label="Condition"
										value={value}
										onChange={onChange}
										options={conditionOptions}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name="comparator"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select label="Comparator" value={value} onChange={onChange} options={columns} />
								)}
							/>
						</Grid>
					</>
				)}
				<Grid item xs={3}>
					<Controller
						control={control}
						name="fields"
						render={({ field: { value } }) => (
							<Button fullWidth disabled={!value.length} onClick={handleSubmit(addFormula)}>
								Add fields to formula
							</Button>
						)}
					/>
				</Grid>
			</Grid>
		</>
	)
}
