import { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import Stack from '@mui/material/Stack'
import { useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Collapse } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import Alert from '@mui/material/Alert'
import RefreshIcon from '@mui/icons-material/Refresh'
import { BugReport } from '@mui/icons-material'
import * as Sentry from '@sentry/react'
import { useDispatch, useSelector } from 'react-redux'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import _isEmpty from 'lodash/isEmpty'

import {
	Box,
	Button,
	Checkbox,
	Divider,
	IconButton,
	LoadingDocument,
	Select,
	Text,
} from 'src/components'
import { TableContext, TableProvider } from 'src/providers/table-provider'
import { ChevronDown } from 'src/assets/icons'
import { RouteId } from 'src/constants/routes'
import { RootState } from 'src/store/types'
import {
	selectors as persistedConfigSelectors,
	actions as persistedConfigActions,
} from 'src/store/modules/persisted-config'

import Questionaire from './questionaire-logic/questionaire-container'
import { TableMenu } from './table-menu'
import { CoreTable } from './core-table'

enum TableTabs {
	Create = 0,
	Logic = 1,
}

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

const replay = Sentry.getReplay()

const a11yProps = (index: number) => {
	return {
		id: `cango-table-tab-${index}`,
		'aria-controls': `cango-table-tabpanel-${index}`,
	}
}

const CustomTabPanel: ComponentType<TabPanelProps> = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3, display: 'flex', height: 700, flex: 1 }}>{children}</Box>
			)}
		</div>
	)
}

const TablesContent: ComponentType<{
	isMenuCollapsed: boolean
	onMenuToggleClick: () => void
	menuWidth: number
}> = ({ isMenuCollapsed, onMenuToggleClick, menuWidth }) => {
	const dispatch = useDispatch()
	const { tableConfig, columns, isLoadingTable, tableList, updateTableConfig, fetchTable } =
		useContext(TableContext)
	const [isSavingQuestionaire, setIsSavingQuestionaire] = useState(false)
	const columnVisibility = useSelector((state: RootState) =>
		persistedConfigSelectors.getColumnVisibility(state, tableConfig?._id ?? ''),
	)
	const [activeTab, setActiveTab] = useState<number>(TableTabs.Create)
	const toolbarHeight = useMemo(() => {
		if (tableConfig?.type === TableTypes.TableType.Questionaire) {
			return 230
		}
		return 200
	}, [tableConfig])

	const initialColumnState = useMemo((): GridColumnVisibilityModel => {
		return columns.reduce((acc: Record<string, boolean>, _column) => {
			if (!columnVisibility) {
				acc[_column._id] = true
				return acc
			}
			acc[_column._id] = columnVisibility.includes(_column._id)
			return acc
		}, {})
	}, [columnVisibility, columns])

	const handleColumnVisibilityChange = (model: GridColumnVisibilityModel) => {
		if (!tableConfig?._id) {
			return
		}
		const selectedColumns = _isEmpty(model) ? null : Object.keys(model).filter((key) => model[key])
		dispatch(
			persistedConfigActions.setColumnVisibility({
				tableId: tableConfig._id,
				visibleColumns: selectedColumns,
			}),
		)
	}

	const hasOptionsColumn = useMemo(() => {
		if (!columns.length) {
			return false
		}
		return columns.some((field) => field.type === TableTypes.FieldType.OPTIONS)
	}, [columns])

	const handleClickQuestionaire = async (isChecked: boolean) => {
		setIsSavingQuestionaire(true)
		await updateTableConfig(
			{ type: isChecked ? TableTypes.TableType.Questionaire : TableTypes.TableType.Standard },
			true,
		)
		setIsSavingQuestionaire(false)
	}

	const saveReplayClick = async () => {
		if (!replay) {
			return
		}
		await replay.flush()
		alert('Replay saved')
	}

	useEffect(() => {
		if (tableConfig?.type !== TableTypes.TableType.Questionaire && activeTab === TableTabs.Logic) {
			setActiveTab(TableTabs.Create)
		}
	}, [tableConfig?.type])

	if (isLoadingTable) {
		return (
			<Box flex={1}>
				<LoadingDocument errorText="" returnToRoute={RouteId.Tables} docType="database" />
			</Box>
		)
	}

	if (!tableConfig?._id) {
		return (
			<Box display="flex" flexDirection="column" alignItems="center" mt={10} flex={1}>
				<Text variant="h4" color={'grey'}>
					{tableList.length > 0 ? 'Select a table' : 'Create a table to get started'}
				</Text>
			</Box>
		)
	}

	return (
		<Box
			width={`calc(100% - ${!isMenuCollapsed ? `${menuWidth + 16}px` : '16px'})`}
			height="calc(100vh - 150px)"
			pt={1}
			pl={isMenuCollapsed ? 2 : 0}
		>
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" spacing={2} mb={1} alignItems="center">
					<Button
						variant="text"
						size="small"
						startIcon={
							<ChevronDown style={{ transform: `rotate(${isMenuCollapsed ? '270' : '90'}deg)` }} />
						}
						sx={{ minWidth: 75 }}
						onClick={onMenuToggleClick}
					>
						{isMenuCollapsed ? 'Show ' : 'Hide '}menu
					</Button>
					<Button
						startIcon={
							<Checkbox
								size="small"
								checked={tableConfig.type === TableTypes.TableType.Questionaire}
								disabled={tableList.some((t) => t.linkedTable?._id === tableConfig?._id)}
							/>
						}
						variant="outlined"
						size="small"
						onClick={() =>
							handleClickQuestionaire(tableConfig.type !== TableTypes.TableType.Questionaire)
						}
						isLoading={isSavingQuestionaire}
						sx={{ width: 250 }}
						disabled={tableList.some((t) => t.linkedTable?._id === tableConfig?._id)}
					>
						This database is a questionaire
					</Button>
					{columns.some(({ type }) => type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE) && (
						<>
							<Select
								label="Linked questionaire"
								options={tableList
									.filter((t) => t.type === 'questionaire')
									.map((t) => ({
										_id: t._id,
										label: t.name,
									}))}
								value={tableConfig?.questionaire_reference_table ?? ''}
								onChange={(event) =>
									updateTableConfig({ questionaire_reference_table: event.target.value as string })
								}
								containerProps={{ width: 250 }}
							/>
						</>
					)}
				</Stack>
				<Box display="flex" alignItems="center" mr={3}>
					<IconButton onClick={saveReplayClick}>
						<BugReport />
					</IconButton>
					<IconButton onClick={() => fetchTable(tableConfig?._id, true)}>
						<RefreshIcon />
					</IconButton>
				</Box>
			</Stack>
			<div>
				<Collapse in={tableConfig.type === TableTypes.TableType.Questionaire}>
					<Tabs value={activeTab} onChange={(event, newIndex) => setActiveTab(newIndex)}>
						<Tab label="Create" {...a11yProps(TableTabs.Create)} />
						<Tab label="Logic" {...a11yProps(TableTabs.Logic)} />
					</Tabs>
					{tableConfig.type === TableTypes.TableType.Questionaire && !hasOptionsColumn && (
						<Alert severity="warning" sx={{ mt: 1 }}>
							{'You need an "Options" column to use the questionaire feature'}
						</Alert>
					)}
				</Collapse>
			</div>
			<CustomTabPanel index={TableTabs.Create} value={activeTab}>
				<CoreTable
					rowReordering
					maxHeight={`calc(100vh - ${toolbarHeight}px)`}
					initialState={{
						columns: { columnVisibilityModel: initialColumnState },
					}}
					onColumnFiltersChange={handleColumnVisibilityChange}
				/>
			</CustomTabPanel>
			<CustomTabPanel index={TableTabs.Logic} value={activeTab}>
				<Questionaire />
			</CustomTabPanel>
		</Box>
	)
}

export const TablesContainer: ComponentType = () => {
	const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)
	const [columnWidth, setColumnWidth] = useState(350)
	const { tableId: tableIdParam } = useParams<{
		tableId?: string
	}>()

	const handleMouseMove = (e: MouseEvent) => {
		setColumnWidth(e.clientX)
	}

	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
	}

	const handleMouseDown = () => {
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}

	return (
		<TableProvider tableId={tableIdParam}>
			<Box display="flex" mr={2} flex={1}>
				{!isMenuCollapsed && <TableMenu columnWidth={columnWidth} />}
				{!isMenuCollapsed && (
					<Divider
						orientation="vertical"
						flexItem
						sx={{ borderRightWidth: 4, mr: 2, cursor: 'col-resize' }}
						onMouseDown={handleMouseDown}
					/>
				)}
				<TablesContent
					isMenuCollapsed={isMenuCollapsed}
					onMenuToggleClick={() => {
						setIsMenuCollapsed(!isMenuCollapsed)
					}}
					menuWidth={columnWidth}
				/>
			</Box>
		</TableProvider>
	)
}

export default TablesContainer
