/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'

import { TaskProviderChildProps } from 'src/providers/table-provider/types'

export default createContext<TaskProviderChildProps>({
	tableConfig: null,
	isLoadingTable: false,
	saveChanges: async () => {},
	hardSave: async () => {},
	isUpdatingTable: false,
	updateRecords: async () => [],
	discardAllChanges: () => {},
	onAddRow: async () => {},
	onAddColumn: async () => {},
	columnList: [],
	columnFilterList: [],
	mappedColumns: new Map(),
	mappedRecords: new Map(),
	mappedRowData: new Map(),
	onUpdateColumn: async () => {
		return { result: 'success' }
	},
	onDeleteColumn: async () => {},
	onDeleteRecords: async () => {},
	updateTableConfig: async () => {},
	mappedValueOptions: {},

	// table browser
	tableList: [],
	isFetchingTableList: false,
	updateTableListItem: async () => {},
	linkedTable: undefined,
	fetchTable: async () => {},
	resolvedRows: [],
	resetCachedRecord: () => {},
	hardUpdateRecords: async () => {},
	columns: [],
	cachedRowIds: new Set(),
	blueprintRecords: new Map(),
	referenceTables: {},
	saveNewReferenceTables: () => {},
})
