import { ComponentType, useContext, useMemo } from 'react'
import { Stack } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { colors } from 'src/theme/colors'
import { Box, Chip, Grid, Text } from 'src/components'
import { TableContext } from 'src/providers/table-provider'

type ReferenceColumnProps = {
	onFieldClick: (data: TableTypes.FormulaSlice) => void
	// rowId?: string
}

const ColumnsInReferencedTable: ComponentType<
	ReferenceColumnProps & {
		columnId: string
		_id: string
		label: string
	}
> = ({ _id, label, columnId, onFieldClick }) => {
	return (
		<Box marginRight={1} mb={1}>
			<Chip
				color="primary"
				variant="outlined"
				label={label}
				onClick={() => {
					onFieldClick({
						id: v4(),
						type: TableTypes.FormulaSliceType.SINGLE_SELECT_OPTION,
						value: `${columnId}::${_id}`,
					})
				}}
			/>
		</Box>
	)
}

const SingleSelectColumn: ComponentType<ReferenceColumnProps & { columnId: string }> = ({
	columnId,
	...rest
}) => {
	const { mappedColumns } = useContext(TableContext)
	const column = mappedColumns.get(columnId)

	if (!column) {
		return null
	}

	return (
		<Grid item xs={12} mb={2}>
			<Text component="label" fontSize={14} color={colors.neutral['80']} fontWeight={500}>
				{column.name} options
			</Text>
			<Stack
				direction="row"
				flexWrap="wrap"
				marginY={0.5}
				sx={{
					overflowX: 'auto',
				}}
			>
				{column.valueOptions.map(({ _id, label }) => (
					<ColumnsInReferencedTable
						{...rest}
						key={_id}
						_id={_id}
						label={label}
						columnId={columnId}
					/>
				))}
			</Stack>
		</Grid>
	)
}

export const SingleSelectValues: ComponentType<ReferenceColumnProps> = (props) => {
	const { mappedColumns } = useContext(TableContext)

	const singleSelectColumnIds = useMemo(() => {
		return [...mappedColumns.values()].reduce((acc: string[], _field) => {
			if (_field.type !== TableTypes.FieldType.SINGLE_SELECT) {
				return acc
			}
			return [...acc, _field._id]
		}, [])
	}, [mappedColumns])

	if (!singleSelectColumnIds.length) {
		return null
	}

	return (
		<>
			{singleSelectColumnIds.map((_columnId) => (
				<SingleSelectColumn {...props} key={_columnId} columnId={_columnId} />
			))}
		</>
	)
}
