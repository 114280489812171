import React, { ComponentType, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Checkbox, Text } from 'src/components'
import { TableContext } from 'src/providers'
import { DependencyForm } from 'src/modules/chains/components/chain-dependency-edge/types'

export const RequiredModFields: ComponentType = () => {
	const { control, watch } = useFormContext<DependencyForm>()
	const { mappedColumns } = useContext(TableContext)
	const database_changes = watch('database_changes')

	if (!database_changes?.length) {
		return null
	}

	return (
		<Box my={1}>
			<Text fontSize={12}>Required fields</Text>
			{database_changes?.map((_change, index) => {
				if (!_change.field) {
					return null
				}
				return (
					<Controller
						key={_change.id}
						control={control}
						name={`database_changes.${index}.is_required`}
						render={({ field: { value, onChange } }) => (
							<Checkbox
								checked={value}
								onChange={onChange}
								size="small"
								label={mappedColumns.get(_change.field)?.name}
							/>
						)}
					/>
				)
			})}
		</Box>
	)
}
