/**
 * Cango Project Database View
 */

import React, { ComponentType } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useGridApiRef } from '@mui/x-data-grid-premium'

import { Box } from 'src/components'
import { CoreTable } from 'src/modules/tables/core-table'

import useConfirmCellChange from '../../../hooks/use-confirm-cell-change'

dayjs.extend(relativeTime)

export const DatabaseTable: ComponentType = () => {
	const apiRef = useGridApiRef()
	const [CellChangeDialog, confirmChange] = useConfirmCellChange(apiRef)
	return (
		<Box px={4}>
			<CoreTable
				apiRef={apiRef}
				rowReordering={false}
				changeConfirmation={{
					Dialog: CellChangeDialog,
					onChangeCallback: confirmChange,
				}}
				maxHeight="calc(100vh - 350px)"
			/>
		</Box>
	)
}
