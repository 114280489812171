import React, { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import Stack from '@mui/material/Stack'
import { Collapse } from '@mui/material'

import { Box, Chip, Text, TextField } from 'src/components'
import {
	selectors as projectsSelectors,
	actions as projectsActions,
	ProjectsLoadingState,
} from 'src/store/modules/projects-v3'
import { selectors as configSelectors } from 'src/store/modules/config'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { colors } from 'src/theme/colors'
import { useIsMobile } from 'src/hooks/useMobile'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { RouteId } from 'src/constants/routes'

import { SectionListItem } from './section-list-item'

const ProjectListContent: ComponentType<{
	filterText: string
	newProjectDOMNodeRef: React.MutableRefObject<HTMLDivElement | null>
}> = ({ filterText, newProjectDOMNodeRef }) => {
	const dispatch = useDispatch()
	const projects = useSelector(projectsSelectors.getProjectlist)
	const sections = useSelector((state: RootState) =>
		projectsSelectors.getProjectSectionsWithTasksSelector(state, false),
	)
	const projectTasks = useSelector(projectsSelectors.getProjectTasks)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const { selectedProjectId, goToProject, selectedSectionId, goToSection } = useMyTasks()
	const projectListState = useSelector(projectsSelectors.getProjectsLoadingState)

	const isProjectLoading = [ProjectsLoadingState.Loading, ProjectsLoadingState.Idle].includes(
		projectListState,
	)

	const filteredProjects = useMemo(() => {
		return projects.filter((project) => {
			return project.name.toLowerCase().includes(filterText.toLowerCase())
		})
	}, [filterText, projects])

	const handleGoToProject = (e: React.MouseEvent, projectId: string) => {
		e.stopPropagation()
		window.open(`/${RouteId.Project}/${projectId}?orgId=${organisationId}`, '_blank')
	}

	useEffect(() => {
		if (selectedProjectId) {
			dispatch(projectsActions.fetchMyTasksProject(selectedProjectId))
		}
	}, [selectedProjectId])

	useEffect(() => {
		if (projects.length && !selectedProjectId) {
			goToProject(projects[0]._id)
			return
		}
	}, [selectedProjectId, projects.length])

	useEffect(() => {
		if (
			sections.length &&
			!selectedSectionId &&
			sections.every((_section) => _section.projectId === selectedProjectId)
		) {
			goToSection(sections[0]._id)
			return
		}
	}, [sections, selectedSectionId])

	if (isProjectLoading) {
		return (
			<Box display="flex" justifyContent="center">
				<PulseLoader size={8} />
			</Box>
		)
	}

	if (!projects.length) {
		return (
			<Box>
				<Text>No projects found</Text>
			</Box>
		)
	}

	return (
		<Box sx={{ overflowY: 'auto' }}>
			{filteredProjects.map((project) => {
				const isProjectSelected = project._id === selectedProjectId
				return (
					<div key={project._id} ref={isProjectSelected ? newProjectDOMNodeRef : undefined}>
						<Box
							width="100%"
							bgcolor="white"
							mb={1}
							borderRadius="10px"
							overflow="hidden"
							boxShadow="0px 1px 10px 0px rgba(0, 0, 0, 0.05)"
							minHeight={100}
							onClick={() => goToProject(project._id)}
							sx={{
								backgroundColor: isProjectSelected ? colors.sunglow['10'] : 'white',
								cursor: 'pointer',
								':hover': {
									backgroundColor: colors.sunglow['10'],
								},
							}}
						>
							<Stack p={2}>
								<Box display="flex">
									<Text mb={1} onClick={(e) => handleGoToProject(e, project._id)}>
										{project.name}
									</Text>
								</Box>
								<Box>
									<Chip
										label={`${project.task_count} pending ${project.task_count === 1 ? 'task' : 'tasks'}`}
										sx={{ borderRadius: 2, bgcolor: colors.sunglow['40'] }}
									/>
								</Box>
							</Stack>
							<Collapse in={isProjectSelected && !!projectTasks.length} unmountOnExit>
								{sections.map((section) => (
									<SectionListItem key={section._id} section={section} />
								))}
							</Collapse>
						</Box>
					</div>
				)
			})}
		</Box>
	)
}

export const ProjectsList: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const isLoadingProjects =
		useSelector(projectsSelectors.getProjectsLoadingState) === ProjectsLoadingState.Loading
	const newProjectDOMNodeRef = useRef<HTMLDivElement | null>(null)
	const isMobile = useIsMobile()
	const [filterText, setFilterText] = useState('')
	const { selectedSectionId: sectionIdParam } = useMyTasks()
	const organisationId = useSelector(authSelectors.getOrganisationId)

	useEffect(() => {
		if (filterText && isLoadingProjects) {
			setFilterText('')
		}
	}, [isLoadingProjects, filterText])

	useEffect(() => {
		if (newProjectDOMNodeRef.current) {
			setTimeout(() => {
				if (newProjectDOMNodeRef.current) {
					newProjectDOMNodeRef.current.scrollIntoView({ behavior: 'smooth' })
				}
			}, 500)
		}
	}, [newProjectDOMNodeRef.current])

	const fetchProjects = useCallback(async () => {
		await dispatch(projectsActions.fetchMyTasksProjects({ isMobile }))
	}, [sectionIdParam])

	useEffect(() => {
		if (organisationId) {
			fetchProjects()
		}
	}, [organisationId])

	return (
		<Box display="flex" flexDirection="column" width="100%" height="100%">
			<Box height="56px" mb={{ laptop: 3, mobile: 1 }}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					{!isMobile && (
						<Text fontSize={18} fontWeight={500} color={colors.neutral['80']} flex={1}>
							Projects
						</Text>
					)}
					<TextField
						containerProps={{
							flex: 2,
							pt: 2,
						}}
						value={filterText}
						onChange={(e) => setFilterText(e.target.value)}
						placeholder="Search projects"
						disabled={isLoadingProjects}
						fullWidth
						type="search"
						bgColor={colors.feldgrau['10']}
						sx={{
							'& fieldset': { border: 'none' },
						}}
					/>
				</Box>
			</Box>
			<ProjectListContent filterText={filterText} newProjectDOMNodeRef={newProjectDOMNodeRef} />
		</Box>
	)
}
