import { useEffect, useRef } from 'react'
import { debounce } from 'lodash'

function useDebouncedCallback<F extends (...args: any[]) => void>(callback: F, delay: number) {
	const callbackRef = useRef(callback)

	useEffect(() => {
		callbackRef.current = callback
	}, [callback])

	const debouncedCallback = useRef(
		debounce((...args: any[]) => {
			callbackRef.current(...args)
		}, delay),
	).current

	useEffect(() => {
		return () => {
			debouncedCallback.cancel()
		}
	}, [debouncedCallback])

	return debouncedCallback
}

export default useDebouncedCallback
