import React, { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Alert, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { TableTypes } from '@cango-app/types'
import { AxiosError, TablesSdk } from '@cango-app/sdk'
import { Controller, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import Badge from '@mui/material/Badge'

import { Box, Button, Modal, Select, Text, Toggle } from 'src/components'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { errorHandler } from 'src/helpers/api'
import { TableContext } from 'src/providers'
import { ConcatinationModal } from 'src/modules/tables/column-settings/concatination-modal'

import { simpleFormulas } from './utils'

type Props = {
	selectedId: string | undefined
	defaultValues: TableTypes.VLookup | undefined
	onSaveVLookup: (data: TableTypes.FormulaSlice) => void
	columnId: string
}

export const TableVLookup: ComponentType<Props> = ({
	defaultValues,
	onSaveVLookup,
	selectedId,
	columnId,
}) => {
	const { control, handleSubmit, watch, setValue } = useForm<TableTypes.VLookup>({
		defaultValues: defaultValues ?? {
			sourceCompareFieldId: '',
			targetTableId: '',
			targetComparefieldId: '',
			returnFieldId: '',
			multiple: false,
		},
	})
	const isMultipleSelected = watch('multiple')
	const [isLoadingTable, setIsLoadingTable] = useState(false)
	const { mappedColumns, tableList } = useContext(TableContext)
	const [table, setTable] = useState<TableTypes.CangoTable | undefined>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const dispatch = useDispatch()
	const [customSource, sourceFieldId] = watch(['customSourceValue', 'sourceCompareFieldId'])
	const [showConcatenationModal, setShowConcatenationModal] = useState(false)

	const columns = useMemo(() => {
		return [...mappedColumns.values()].reduce(
			(_columns: { _id: string; label: string }[], _column) => {
				if (_column.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE) {
					return [
						..._columns,
						{
							_id: `${_column._id}::answer`,
							label: `${_column.name} -> Answer`,
						},
					]
				}
				return [
					..._columns,
					{
						_id: _column._id,
						label: _column.name,
					},
				]
			},
			[],
		)
	}, [mappedColumns])

	const mappedColumnsSelectedTable = useMemo(() => {
		if (!table?.fields) return []
		return table.fields.map(({ _id, name }) => ({ _id, label: name }))
	}, [table?.fields])

	const fetchTable = useCallback(
		async (targetTableId: string) => {
			try {
				setIsLoadingTable(true)
				const response = await TablesSdk.getTable(
					import.meta.env.VITE_API as string,
					authHeaders,
					targetTableId,
				)
				setTable(response.table)
				if (response.circular_reference) {
					showSnackbar(`Circular reference detected\n${response.circular_reference}`, {
						variant: 'error',
						style: { whiteSpace: 'pre-line' },
					})
				}
			} catch (error) {
				if ((error as AxiosError).response?.status === 404) {
					showSnackbar('Table not found', { variant: 'error' })
					return
				}
				errorHandler({ error, dispatch, message: 'Could not fetch table' })
			} finally {
				setIsLoadingTable(false)
			}
		},
		[authHeaders],
	)

	const handleSaveVLookup = (data: TableTypes.VLookup) => {
		onSaveVLookup({
			id: selectedId || v4(),
			type: TableTypes.FormulaSliceType.VLOOKUP,
			value: '',
			vlookup: data,
		})
	}

	const handleSaveConcatenation = async (concatString: string) => {
		setValue('customSourceValue', concatString)
		setShowConcatenationModal(false)
	}

	useEffect(() => {
		if (defaultValues?.targetTableId) {
			fetchTable(defaultValues.targetTableId)
		}
	}, [defaultValues?.targetTableId])

	return (
		<>
			<Modal open={showConcatenationModal} onClose={() => setShowConcatenationModal(false)}>
				<ConcatinationModal
					columnId={columnId}
					onSave={handleSaveConcatenation}
					concatenation={customSource ?? ''}
				/>
			</Modal>
			<Text fontSize={14} fontWeight="bold">
				This table
			</Text>
			<Controller
				name={`sourceCompareFieldId`}
				control={control}
				render={({ field: { onChange, value } }) => (
					<Select
						label="Which column in THIS TABLE should be matched?"
						options={columns}
						value={!customSource ? value : undefined}
						onChange={onChange}
						containerProps={{ width: 500, mb: 2 }}
						disabled={!!customSource}
						withNoneOption
					/>
				)}
			/>
			<Text>OR</Text>
			<Box my={2}>
				<Badge badgeContent={customSource ? '!' : 0} color="primary">
					<Button
						variant="outlined"
						onClick={() => setShowConcatenationModal(true)}
						disabled={!!sourceFieldId}
					>
						Custom
					</Button>
				</Badge>
			</Box>
			<Text fontSize={14} fontWeight="bold">
				Which table and column should be searched?
			</Text>
			<Controller
				name={`targetTableId`}
				control={control}
				render={({ field: { value, onChange } }) => (
					<Select
						label="Table"
						options={tableList.map((_item) => ({ ..._item, label: _item.name }))}
						value={value}
						onChange={(event) => {
							const tableId = event.target.value as string
							if (tableId && tableId !== value) {
								fetchTable(tableId)
							}
							onChange(tableId)
						}}
						containerProps={{ width: 500, mb: 2 }}
					/>
				)}
			/>

			<Controller
				name={`targetComparefieldId`}
				control={control}
				render={({ field: { value, onChange } }) => (
					<Select
						label="Column"
						isLoading={isLoadingTable}
						options={mappedColumnsSelectedTable}
						value={value}
						onChange={onChange}
						containerProps={{ width: 500, mb: 2 }}
					/>
				)}
			/>

			<Controller
				name={`returnFieldId`}
				control={control}
				render={({ field: { value, onChange } }) => (
					<Select
						label="Return value"
						isLoading={isLoadingTable}
						options={mappedColumnsSelectedTable}
						value={value}
						onChange={onChange}
						containerProps={{ width: 500, mb: 2 }}
					/>
				)}
			/>
			<Controller
				control={control}
				name={`multiple`}
				render={({ field: { value, onChange } }) => {
					const handleChange = (newValue: boolean) => {
						if (!newValue) {
							setValue('multiple_formula', undefined, { shouldDirty: true })
						}
						onChange(newValue)
					}

					return (
						<Toggle
							label="Should this find the first value or multiple values?"
							value={value}
							options={[
								{ value: false, label: 'a single value' },
								{ value: true, label: 'multiple values' },
							]}
							onChange={(newValue) => handleChange(newValue)}
							containerProps={{ width: 500, mb: 2 }}
						/>
					)
				}}
			/>
			{isMultipleSelected && (
				<Controller
					control={control}
					name="multiple_formula"
					rules={{ required: isMultipleSelected }}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<Box mb={1}>
							<Alert severity={error ? 'error' : 'info'} sx={{ mb: 2 }}>
								When multiple values are matched, they need to be reduced to one number. How will
								you do this?
							</Alert>
							<Select
								value={value}
								onChange={onChange}
								label="Choose a formula"
								options={simpleFormulas.map((_form) => ({
									_id: _form,
									label: _form,
								}))}
								containerProps={{ width: 500 }}
								error={!!error}
							/>
						</Box>
					)}
				/>
			)}
			<Grid item xs={3}>
				<Button onClick={handleSubmit(handleSaveVLookup)} size="small">
					{selectedId ? 'Update formula' : 'Add to formula'}
				</Button>
			</Grid>
		</>
	)
}
