import React, { ComponentType, ReactNode, ReactElement, useContext, useState } from 'react'
import { SxProps } from '@mui/material'
import SwapVerticalCircleOutlined from '@mui/icons-material/SwapVerticalCircleOutlined'

import { DriveFilesContext } from 'src/providers/drive-files-provider'
import { invariantDefined } from 'src/helpers/invariant'

import { Button } from '../button'
import googleDriveLogo from '../../assets/images/google_drive_logo.png'
import { Text } from '../text'

import { DriveUploadPanelContainer } from './drive-upload-panel'
import { useParentFolderFiles } from './use-parent-folder-files'

type DriveUploadButtonProps = {
	containerStyles?: SxProps
	label?: string
	isDisabled?: boolean
	isLoading?: boolean
	ctaVariant?: 'button' | 'replaceIcon'
	selectedFilesIds: string[]
	children?: (props: { onClick: () => void }) => ReactNode
	onlyUpload?: boolean
	onFileIdsChange?: ((fileIds: string[]) => void) | ((fileIds: string[]) => Promise<void>)
}

const noop = async () => undefined
export const DriveUploadButtonContainer: ComponentType<DriveUploadButtonProps> = (props) => {
	const { onlyUpload = false, onFileIdsChange = noop, selectedFilesIds } = props
	const [isOpen, setIsOpen] = useState(false)
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)

	const parentFolderFilesProps = useParentFolderFiles(
		invariantDefined(parentFolderId, 'parentFolderId is required'),
		parentFolderName as string,
	)

	const handleDriveClick = async () => {
		parentFolderFilesProps.fetchFiles()
		setIsOpen(true)
	}

	return (
		<>
			<DriveUploadButton
				{...props}
				isLoadingFiles={parentFolderFilesProps.isLoadingFiles}
				handleDriveClick={handleDriveClick}
			/>
			<DriveUploadPanelContainer
				{...parentFolderFilesProps}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				onlyUpload={onlyUpload}
				selectedFilesIds={selectedFilesIds}
				parentFolderName={parentFolderName}
				parentFolderId={parentFolderId as string}
				onFileIdsChange={onFileIdsChange}
			/>
		</>
	)
}

const DriveUploadButton: ComponentType<
	DriveUploadButtonProps & {
		handleDriveClick: () => Promise<void>
		isLoadingFiles: boolean
	}
> = ({
	containerStyles,
	label = 'Save to Google Drive™',
	isDisabled,
	isLoading,
	ctaVariant = 'button',
	isLoadingFiles,
	children,
	handleDriveClick,
}) => {
	if (children) {
		return children({ onClick: handleDriveClick }) as ReactElement
	}

	switch (ctaVariant) {
		case 'replaceIcon':
			return (
				<Button
					onClick={handleDriveClick}
					disabled={isLoading}
					isLoading={!!isLoading || isLoadingFiles}
					variant="outlined"
					size="small"
					startIcon={<SwapVerticalCircleOutlined />}
				>
					Replace files
				</Button>
			)
		default: {
			return (
				<Button
					sx={{ ...containerStyles, textTransform: 'none' }}
					variant="outlined"
					startIcon={<img src={googleDriveLogo} width={20} alt="Google drive logo" />}
					disabled={isDisabled || isLoading}
					isLoading={!!isLoading || isLoadingFiles}
					onClick={handleDriveClick}
				>
					<Text>{isDisabled ? 'Failed to fetch file' : label}</Text>
				</Button>
			)
		}
	}
}
