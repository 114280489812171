import { V3BlueprintTypes, V3ClientTypes, V3ProjectTypes } from '@cango-app/types'
import { ComponentType, useContext, useMemo } from 'react'
import _orderBy from 'lodash/orderBy'
import { Stack } from '@mui/material'

import { Box, Text } from 'src/components'
import { AnnotationCard } from 'src/modules/chains/components/annotation-card'
import { TaskContext } from 'src/providers'

import { FileViewItem } from './file-tasks/file-view'
import { LinkViewItem } from './link-view'
import { CompletationInfo } from './file-tasks/completation-info'

interface TaskReferenceItemType {
	stepId: string
	taskName: string
	chain: V3ProjectTypes.ProjectChain | undefined
	tasks: V3ClientTypes.Project.Task[]
}

type TaskReferenceCategories = {
	files: TaskReferenceItemType[]
	links: TaskReferenceItemType[]
	// contacts: FileViewItemType[]
	notes:
		| {
				taskName: string
				completed: number | undefined
				annotations: V3ClientTypes.Project.Task['annotations']
		  }
		| undefined
}

const getFileReferences = (
	task: V3ClientTypes.Project.Task,
	previousRefs: TaskReferenceItemType[],
	index: number,
): TaskReferenceItemType[] => {
	const newRefs = [...previousRefs]
	if (!task.step?._id) {
		newRefs.push({
			stepId: `${task._id}-${index}-no-step`,
			taskName: task.name,
			chain: task.chain,
			tasks: [task],
		})
		return newRefs
	}

	const accIndex = newRefs.findIndex(
		(accTask) =>
			accTask.stepId === task.step?._id &&
			(accTask.chain?._id === task.chain?._id || !accTask.chain?._id),
	)

	const rootAccIndex = newRefs.findIndex(
		(_accTask) =>
			_accTask.stepId === task.step?._id &&
			(_accTask.chain?._id === task.chain?._id || !task.chain?._id),
	)

	if (rootAccIndex >= 0) {
		newRefs[rootAccIndex].tasks.push(task)
		newRefs[rootAccIndex].tasks = _orderBy(newRefs[rootAccIndex].tasks, 'iteration', 'desc')
		return newRefs
	}

	if (accIndex >= 0) {
		newRefs[accIndex].tasks.push(task)
		newRefs[accIndex].tasks = _orderBy(newRefs[accIndex].tasks, 'iteration', 'desc')
		return newRefs
	}

	newRefs.push({
		stepId: task.step?._id as string,
		taskName: task.name,
		chain: task.chain,
		tasks: [task],
	})

	return newRefs
}

export const TaskReference: ComponentType<{
	taskReferences: V3ClientTypes.Project.TaskAction['task_references']
	project?: Pick<V3ClientTypes.Project.Project, '_id' | 'google_drive_folder_id' | 'name'>
	onAddFilesToTask?: (data: {
		fileIds: string[]
		taskId: string
		dependencyId: string
		projectId: string
		actionIndex: number
	}) => Promise<void>
}> = ({ taskReferences, project }) => {
	const { updateTask } = useContext(TaskContext)
	const references = useMemo((): TaskReferenceCategories => {
		if (!taskReferences.length) {
			return {
				files: [],
				links: [],
				// contacts: [],
				notes: undefined,
			}
		}

		const filteredReferences = taskReferences.filter((_ref) => _ref.task)

		return filteredReferences.reduce(
			(referenceCategories: TaskReferenceCategories, _reference, index) => {
				let stepId = _reference.task.step?._id
				if (!stepId) {
					stepId = `${_reference.task._id}-no-step`
				}

				if (_reference.fields.includes(V3BlueprintTypes.TaskReferenceEnum.Files)) {
					referenceCategories.files = getFileReferences(
						_reference.task,
						referenceCategories.files,
						index,
					)
				}

				if (_reference.fields.includes(V3BlueprintTypes.TaskReferenceEnum.Links)) {
					referenceCategories.links = [
						...referenceCategories.links,
						{
							stepId: stepId,
							taskName: _reference.task.name,
							chain: _reference.task.chain,
							tasks: [_reference.task],
						},
					]
				}
				if (_reference.fields.includes(V3BlueprintTypes.TaskReferenceEnum.Notes)) {
					const completedAtTime = _reference.task?.lifecycle?.completed_at?.length
						? _reference.task.lifecycle?.completed_at[
								_reference.task.lifecycle?.completed_at.length - 1
							]
						: undefined
					referenceCategories.notes = {
						annotations: _reference.task.annotations,
						completed: completedAtTime,
						taskName: _reference.task.name,
					}
				}
				return referenceCategories
			},
			{
				files: [],
				links: [],
				// contacts: [],
				notes: undefined,
			},
		)
	}, [taskReferences])

	const handleChangeFileIds = async (
		referencedTaskId: string,
		fileIds: string[],
		actionIndex: number,
	) => {
		await updateTask(`actions.${actionIndex}.file_ids`, fileIds, {
			referencedTaskId,
			updateDb: true,
		})
	}

	if (!project) {
		return null
	}
	return (
		<Stack direction="column" spacing={4}>
			{!!references.files.length && (
				<Box>
					<Text variant="overline">Files</Text>
					{references.files.map((fileRef, index) => {
						return (
							<FileViewItem
								key={`${fileRef.stepId}-${fileRef.chain?._id}-${index}`}
								item={fileRef}
								project={project}
								onUpdateFileIds={handleChangeFileIds}
								isLastItem={index === references.files.length - 1}
							/>
						)
					})}
				</Box>
			)}

			{!!references.links.length && (
				<Box>
					<Text variant="overline">Links</Text>
					{references.links.map((linkRef, index) => (
						<LinkViewItem
							key={`${linkRef.stepId}-${linkRef.chain?._id}-${index}`}
							item={linkRef}
							isLastItem={index === references.links.length - 1}
						/>
					))}
				</Box>
			)}
			{!!references.notes?.annotations.length && (
				<Box>
					<Text variant="overline">Notes</Text>
					<Text fontSize={14}>
						Task: <b>{references.notes.taskName}</b>
					</Text>
					<CompletationInfo completedAtTime={references.notes.completed} />
					<Stack direction="column" spacing={1.5} marginTop={2}>
						{references.notes.annotations.map(({ _id, annotation }) => {
							return <AnnotationCard key={_id} annotation={annotation} />
						})}
					</Stack>
				</Box>
			)}
		</Stack>
	)
}
