import { ComponentType, MouseEvent, useContext, useState } from 'react'
import { Handle, Position, NodeProps, FitView } from 'reactflow'

import { Box, IconButton, Text } from 'src/components'
import { PlusIcon } from 'src/assets/icons'
import { V3BlueprintStep } from 'src/modules/blueprints-v3/types'

import { ChainContext } from '../chain-provider'

import StepModal from './step-modal'

const ChainNode: ComponentType<
	NodeProps<
		V3BlueprintStep & {
			fitView: FitView
		}
	>
> = ({ data, isConnectable }) => {
	const { onAddStep, onRemoveDeletedStep } = useContext(ChainContext)
	const [isCreatingStep, setIsCreatingStep] = useState(false)
	const [showStepModal, setShowStepModal] = useState(false)

	const handleNewStepClick = async (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		setIsCreatingStep(true)
		await onAddStep(data._id, () => {
			data.fitView()
		})
		setIsCreatingStep(false)
	}

	return (
		<>
			{showStepModal && (
				<StepModal
					stepId={data._id}
					onClose={() => setShowStepModal(false)}
					onDeleteStep={onRemoveDeletedStep}
				/>
			)}
			<Box
				bgcolor="#e5f6fd"
				p={1}
				width={200}
				borderRadius={1}
				position="relative"
				onClick={() => setShowStepModal(true)}
			>
				<Handle type="target" position={Position.Top} isConnectable={isConnectable} />
				<Text fontSize={14} textAlign="center">
					Chain: <b>{data.name}</b>
				</Text>

				<Box position="absolute" right={0} bottom={0}>
					<IconButton size="small" onClick={handleNewStepClick} isLoading={isCreatingStep}>
						<PlusIcon width={12} />
					</IconButton>
				</Box>
				<Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
			</Box>
		</>
	)
}

export default ChainNode
