import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { createSelector } from '@reduxjs/toolkit'

import { isStepReady } from 'src/providers/task-provider/utils'
import { ListedStep } from 'src/modules/chains/types'

export const filterDescendantsBySelection = (
	task: V3ClientTypes.Project.Task,
	chainTasks: V3ClientTypes.Project.Task[],
	completed_options: {
		_id: string
		label: string
	}[],
) => {
	return (
		task.step?.descendants.reduce(
			(_descendants: V3ClientTypes.Project.ProjectDescendant[], _desc) => {
				if (!_desc.step?._id) {
					return _descendants
				}

				const optionConditionFrom =
					chainTasks.find(({ _id }) => _desc.option_condition?.from === _id) ?? task
				const completedOptions =
					optionConditionFrom._id === task._id
						? completed_options
						: optionConditionFrom.lifecycle.completed_options

				if (_desc.option_condition?.values.length && !completedOptions.length) {
					return _descendants
				}

				if (
					_desc.option_condition?.operator === V3BlueprintTypes.ChildConditionOperator.Is &&
					!completedOptions.some(({ _id }) => _desc.option_condition?.values.includes(_id))
				) {
					return _descendants
				}

				return [..._descendants, _desc]
			},
			[],
		) ?? []
	)
}

export const selectAvailableDescendants: (
	projectSteps: ListedStep[],
	chainTasks: V3ClientTypes.Project.Task[],
	projectTasks: V3ClientTypes.Project.Task[],
	completed_options: {
		_id: string
		label: string
	}[],
	task: V3ClientTypes.Project.Task | undefined,
) => V3ClientTypes.Project.ProjectDescendant[] = createSelector(
	(projectSteps: ListedStep[]) => projectSteps,
	(projectSteps: ListedStep[], chainTasks: V3ClientTypes.Project.Task[]) => chainTasks,
	(
		projectSteps: ListedStep[],
		chainTasks: V3ClientTypes.Project.Task[],
		projectTasks: V3ClientTypes.Project.Task[],
	) => projectTasks,
	(
		projectSteps: ListedStep[],
		chainTasks: V3ClientTypes.Project.Task[],
		projectTasks: V3ClientTypes.Project.Task[],
		completed_options: {
			_id: string
			label: string
		}[],
	) => completed_options,
	(
		projectSteps: ListedStep[],
		chainTasks: V3ClientTypes.Project.Task[],
		projectTasks: V3ClientTypes.Project.Task[],
		completed_options: {
			_id: string
			label: string
		}[],
		task?: V3ClientTypes.Project.Task,
	) => task,
	(projectSteps, chainTasks, projectTasks, completed_options, activeTask) => {
		if (!activeTask?.step?.descendants) {
			return []
		}

		const sectionSteps = new Map<string, string>()
		const stepDescendants = filterDescendantsBySelection(activeTask, chainTasks, completed_options)

		stepDescendants.forEach((_desc) => {
			if (_desc.step?.isSection) {
				_desc.step.descendants.forEach((_sectionDesc) => {
					if (!_sectionDesc.step?._id) {
						return
					}
					sectionSteps.set(String(_sectionDesc.step._id), String(_desc.step!._id))
				})
			}
		})

		if (!stepDescendants.length) {
			return []
		}

		return stepDescendants.reduce(
			(_descendants: V3ClientTypes.Project.ProjectDescendant[], _desc) => {
				const _descStep = _desc.step!
				if (!_desc.step) {
					return _descendants
				}
				if (
					_descStep.isSection &&
					_descStep.descendants.every((_sectionDesc) => {
						if (!_sectionDesc.step?._id) {
							return false
						}

						return isStepReady({
							step: _sectionDesc.step,
							sectionSteps,
							blueprintSteps: projectSteps,
							projectTasks,
							activeTask,
							completed_options,
						})
					})
				) {
					_descendants.push(_desc)
				} else if (
					!_desc.step.isSection &&
					isStepReady({
						step: _descStep,
						sectionSteps,
						blueprintSteps: projectSteps,
						projectTasks,
						activeTask,
						completed_options,
					})
				) {
					_descendants.push(_desc)
				}

				return _descendants
			},
			[],
		)
	},
)
