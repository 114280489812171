import React, { ComponentType, useContext, useState } from 'react'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

import { Button, CreateView } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { PlusIcon } from 'src/assets/icons'

type ViewsButtonProps = {
	label?: string
	buttonVariant?: 'text' | 'outlined' | 'contained'
	size?: 'small' | 'medium' | 'large'
	onViewSelect: (viewId: string | undefined) => void
	openViewParams: { open: boolean; viewId?: string }
	onClose: () => void
	onViewSaved?: (newViewId: string) => void
}

export const ViewsButton: ComponentType<ViewsButtonProps> = ({
	label = 'Views',
	buttonVariant = 'text',
	size = 'medium',
	openViewParams,
	onViewSelect,
	onClose,
	onViewSaved,
}) => {
	const { tableConfig } = useContext(TableContext)
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleViewSaved = async (newViewId: string | undefined) => {
		if (newViewId && onViewSaved) {
			onViewSaved(newViewId)
		}
		onClose()
	}

	return (
		<>
			<CreateView
				open={openViewParams.open}
				onClose={onClose}
				viewId={openViewParams.viewId}
				onViewSaved={handleViewSaved}
			/>
			<Button
				aria-controls={open ? 'views-menu-options' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : 'false'}
				variant={buttonVariant}
				onClick={handleClick}
				size={size}
			>
				{label}
			</Button>
			<Menu
				id="views-menu-options"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				onClick={handleClose}
			>
				<MenuItem onClick={() => onViewSelect(undefined)}>
					<ListItemIcon>
						<PlusIcon />
					</ListItemIcon>
					<ListItemText>Create view</ListItemText>
				</MenuItem>
				{!!tableConfig?.views &&
					tableConfig.views.map((_view) => {
						return (
							<MenuItem key={_view._id} onClick={() => onViewSelect(_view._id)}>
								<ListItemText>{_view.name}</ListItemText>
							</MenuItem>
						)
					})}
			</Menu>
		</>
	)
}
