import React, { ComponentType, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Stack, SxProps } from '@mui/material'

import { AttachedFile, Box, DriveUploadButton, Text } from 'src/components'
import { DriveFilesContext } from 'src/providers'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'

import { StepFormType } from './step-form-container'

type TemplateUploadFieldsProps = {
	containerStyles?: SxProps
	isLoading?: boolean
	attachmentIndex: number
	chainIndex: number
}

const UndecoratedTemplateUploadFields: ComponentType<TemplateUploadFieldsProps> = ({
	isLoading,
	containerStyles,
	attachmentIndex,
	chainIndex,
}) => {
	const { control } = useFormContext<StepFormType>()
	const { chain } = useContext(ChainContext)
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)
	const { allFiles } = useParentFolderFiles(parentFolderId as string, parentFolderName as string)

	const name = `chain_actions.${chainIndex}.attachments.${attachmentIndex}.file_ids` as const

	if (!chain) return null

	return (
		<Stack direction="column" spacing={2}>
			<Text>Files:</Text>
			<Controller
				control={control}
				name={name}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<Box>
						{!!value.length && (
							<Box display="flex" flexWrap="wrap" sx={containerStyles}>
								{value.map((fileId: string) => (
									<AttachedFile
										key={fileId}
										fileId={fileId}
										containerStyles={{ mb: 0.5, mr: 1 }}
										_file={allFiles.find((file) => file.id === fileId)}
										parentFolderId={parentFolderId}
										parentFolderName={parentFolderName}
									/>
								))}
							</Box>
						)}
						<DriveUploadButton
							containerStyles={{ mt: value.length ? 1 : 0 }}
							selectedFilesIds={value}
							ctaVariant={value.length ? 'replaceIcon' : undefined}
							isLoading={isLoading}
							onFileIdsChange={onChange}
						/>
						{!!error && (
							<Text color="red" fontSize={12}>
								{error.message}
							</Text>
						)}
					</Box>
				)}
			/>
		</Stack>
	)
}

export const TemplateUploadFieldsV2 = React.memo(UndecoratedTemplateUploadFields)
