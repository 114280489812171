import React, { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { V3BlueprintSdk } from '@cango-app/sdk'
import { ComponentType, useEffect, useState, useContext } from 'react'
import { Control, FormProvider, useForm } from 'react-hook-form'
import _isEmpty from 'lodash/isEmpty'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'

import { Box, Text, TabPanel, Checkbox } from 'src/components'
import { ChainContext } from 'src/modules/chains/chain-provider'

import { StepContext } from '../step-provider'

import { CoreFields } from './core-fields'
import { SubmitButtons } from './submit-buttons'
import { TrainingFields } from './training-fields'
import { OptionsFields } from './child-fields'
import { ChainReferenceForm } from './chain-reference-form'
import { MultipleStepActions } from './multiple-action-fields'
import { ChainExtractionForm } from './chain-extraction-form'
import { removeLastNonAlphanumeric } from './utils'

type StepFormProps = {
	onDelete: () => void
	onCloseDrawer: () => void
	setDirtiedFields: (tf: boolean) => void
	step: V3ClientTypes.Blueprint.Step
}

export type StepFormType = Required<V3BlueprintSdk.UnpopulatedStep>
export type StepFormControl = Control<StepFormType>

export enum StepModalTab {
	General = 0,
	Actions = 1,
	Resources = 3,
	Training = 4,
	Lifecycle = 5,
	Settings = 6,
	Hierarchy = 7,
	Options = 8,
	Database = 9,
}

const getItemsDirtyData = <
	TData extends Record<keyof TDirtyItems, unknown>,
	TDirtyItems extends Record<string, unknown>,
>(
	data: TData,
	dirtyItems: TDirtyItems,
): Partial<TData> => {
	const dirtyItemsEntries = Object.entries(dirtyItems)

	return dirtyItemsEntries.reduce((dirtyData, [name, value]) => {
		if (name === 'action') {
			return { ...dirtyData, [name]: data.action }
		}

		if (name === 'training') {
			return { ...dirtyData, [name]: data.training }
		}

		if (name === 'when') {
			return { ...dirtyData, [name]: data.when }
		}

		if (name === 'chain') {
			return { ...dirtyData, [name]: data.chain }
		}

		if (name === 'resource') {
			return { ...dirtyData, [name]: data.resource }
		}

		if (name === 'chain_actions') {
			return { ...dirtyData, [name]: data.chain_actions }
		}

		if (name === 'complete_options') {
			return { ...dirtyData, [name]: data.complete_options }
		}

		if (name === 'name') {
			// important for sectionId in myTask page
			return { ...dirtyData, [name]: removeLastNonAlphanumeric(data[name] as string) }
		}

		if (typeof value !== 'object' || Array.isArray(value)) {
			return { ...dirtyData, [name]: data[name] }
		}

		return {
			...dirtyData,
			[name]: getItemsDirtyData(data[name] as TData, dirtyItems[name] as TDirtyItems),
		}
	}, {})
}

export const getDefaultStepValues = (
	step: V3ClientTypes.Blueprint.Step | undefined,
): Partial<StepFormType> => ({
	...(step ?? {}),
	name: step?.name ?? '',
	population_model: step?.population_model ?? '',
	created_at: step?.created_at ?? dayjs().unix(),
	updated_at: step?.updated_at ?? dayjs().unix(),
	task_type: step?.task_type ?? V3BlueprintTypes.TaskType.Standard,
	chain_id: step?.chain_id ?? '',
	description: step?.description ?? '',
	descendants: step?.descendants ?? [],
	roles: step?.roles ?? [],
	isMultiUse: step?.isMultiUse ?? false,
	isMenu: step?.isMenu ?? false,
	isSection: step?.isSection ?? false,
	requiresEveryParent: step?.requiresEveryParent ?? false,
	chain_actions: step?.chain_actions ?? [],
	training: step?.training ?? [],
	chain_reference: step?.chain_reference ?? '',
})

export const StepForm: ComponentType<StepFormProps> = ({
	onDelete,
	onCloseDrawer,
	setDirtiedFields,
	step,
}) => {
	const { isLoading, updateStep } = useContext(StepContext)
	const { onUpdateStep: updateChainStep } = useContext(ChainContext)
	const [activeTab, setActiveTab] = useState<StepModalTab>(StepModalTab.General)
	const [showChainReferenceForm, setShowChainReferenceForm] = useState(!!step.chain_reference)
	const [showExtractChainForm, setShowExtractChainForm] = useState(false)

	const formMethods = useForm<StepFormType>({
		defaultValues: getDefaultStepValues(step),
	})

	const {
		control,
		handleSubmit,
		formState: { dirtyFields, errors },
		watch,
	} = formMethods
	const isSection = watch('isSection')

	const onSave = async (data: StepFormType) => {
		const dirtyValues = getItemsDirtyData(data, dirtyFields)
		const response = await updateStep({ ...dirtyValues })

		if (response) {
			updateChainStep({
				_id: response._id,
				name: response.name,
				descendants: response.descendants,
				isMenu: response.isMenu,
				isMultiUse: response.isMultiUse,
				isSection: response.isSection,
				roles: response.roles,
				complete_options: response.complete_options,
			})
			onCloseDrawer()
		}
	}

	const canSubmit = () => {
		if (showChainReferenceForm) {
			return true
		}

		return !_isEmpty(dirtyFields)
	}

	useEffect(() => {
		setDirtiedFields(!_isEmpty(dirtyFields))
	}, [_isEmpty(dirtyFields)])

	const content = () => {
		if (showChainReferenceForm) {
			return (
				<ChainReferenceForm onDeselectChainReference={() => setShowChainReferenceForm(false)} />
			)
		}

		if (showExtractChainForm) {
			return (
				<ChainExtractionForm
					onClose={() => setShowExtractChainForm(false)}
					onCloseDrawer={onCloseDrawer}
				/>
			)
		}

		return (
			<>
				<Box sx={{ mb: 1 }}>
					<Text variant="h5" mb={2}>
						{step?.name ? step.name : 'New step'}
					</Text>
					<Stack direction="column">
						<Checkbox
							label={'This task references another chain'}
							onChange={() => setShowChainReferenceForm(true)}
							disabled={!!step.chain_reference}
							checked={!!step.chain_reference}
						/>

						{/* <Checkbox
						label={'Extract this chain as a new chain'}
						onChange={() => setShowExtractChainForm(true)}
						disabled={chainHasCustomLogic || !!step.chain_reference}
					/> */}
					</Stack>
				</Box>
				<Tabs
					value={activeTab}
					variant="scrollable"
					scrollButtons
					onChange={(e, newValue) => setActiveTab(newValue)}
				>
					<Tab value={StepModalTab.General} label="General" />
					<Tab value={StepModalTab.Options} label="Options" disabled={isSection} />
					<Tab value={StepModalTab.Actions} label="Actions & Attachments" disabled={isSection} />

					<Tab value={StepModalTab.Training} label="Training" disabled={isSection} />
				</Tabs>
				<TabPanel index={StepModalTab.General} value={activeTab}>
					<CoreFields onSectionSelect={() => setActiveTab(StepModalTab.General)} />
				</TabPanel>
				<TabPanel index={StepModalTab.Options} value={activeTab}>
					<OptionsFields />
				</TabPanel>
				<TabPanel index={StepModalTab.Actions} value={activeTab}>
					<MultipleStepActions />
				</TabPanel>

				<TabPanel index={StepModalTab.Training} value={activeTab}>
					<TrainingFields control={control} />
				</TabPanel>
			</>
		)
	}

	return (
		<FormProvider {...formMethods}>
			<Stack role="presentation" direction="column" justifyContent="space-between" height="100%">
				<Stack
					padding={3}
					direction="column"
					sx={{
						overflowY: 'auto',
					}}
				>
					{content()}
					{!step.chain_reference && (showChainReferenceForm || showExtractChainForm) && (
						<Alert severity="warning" sx={{ mt: 2 }}>
							<AlertTitle>Warning</AlertTitle>
							Extracting this chain is irreversible. Are you sure you want to proceed?
						</Alert>
					)}
				</Stack>
				<Box mb={2} paddingX={4} mt={'auto'}>
					{!showExtractChainForm && (
						<SubmitButtons
							onSubmit={handleSubmit(onSave)}
							onDelete={onDelete}
							isLoading={isLoading}
							stepId={step?._id}
							canSubmit={canSubmit()}
							errors={errors}
						/>
					)}
				</Box>
			</Stack>
		</FormProvider>
	)
}
