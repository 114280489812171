import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import _isNumber from 'lodash/isNumber'
import _round from 'lodash/round'
import _isUndefined from 'lodash/isUndefined'
import dayjs from 'dayjs'
import { ComponentType, createContext, useContext, useMemo } from 'react'
import { CopyAllOutlined } from '@mui/icons-material'

import { ResolvedRowData } from 'src/modules/tables/utils'
import { colors } from 'src/theme/colors'
import { TableContext } from 'src/providers/table-provider'

import { Box } from '../box'
import { Text } from '../text'
import { IconButton } from '../icon-button'
import { Button } from '../button'

import {
	generateTreeData,
	getFormattedPercentageRows,
	onCopyAllGroups,
	onCopyGroup,
	TreeData,
} from './presentation-view-utils'

const PresentationViewContext = createContext<Partial<TableTypes.TableView>>({})

const Records: ComponentType<{ records: any[]; hasNestedChild: boolean }> = ({
	records,
	hasNestedChild,
}) => {
	if (!records.length) return null
	return (
		<TableBody>
			<TableRow key={`${records[0]._id}-row`}>
				{!hasNestedChild &&
					records.map(({ name, _id }) => {
						return <TableCell key={_id}>{name}</TableCell>
					})}
			</TableRow>
		</TableBody>
	)
}

const CustomTableCell = ({ children }: { children: React.ReactNode }) => {
	const { withHorizontalTableBorder, withVerticalTableBorder } = useContext(PresentationViewContext)
	return (
		<TableCell
			sx={{
				borderBottom: withHorizontalTableBorder ? '1px solid rgba(224, 224, 224, 1)' : 0,
				...(withVerticalTableBorder && {
					borderRight: '1px solid rgba(224, 224, 224, 1)',
					'&:first-of-type': {
						borderLeft: '1px solid rgba(224, 224, 224, 1)',
					},
				}),
			}}
		>
			{children}
		</TableCell>
	)
}

const renderChildProperties = (row: any, records?: TableTypes.Field[]) => {
	return (
		<TableRow key={row._id}>
			{records &&
				records.map(({ _id, type }) => {
					if (type === TableTypes.FieldType.BOOLEAN) {
						return <CustomTableCell key={_id}>{row[_id] ? 'true' : 'false'}</CustomTableCell>
					}
					if (type === TableTypes.FieldType.DATE) {
						return (
							<CustomTableCell key={_id}>{dayjs(row[_id]).format('MMM DD, YYYY')}</CustomTableCell>
						)
					}
					return (
						<CustomTableCell key={_id}>
							{_isNumber(row[_id]) ? row[_id].toFixed(2) : row[_id]}
						</CustomTableCell>
					)
				})}
		</TableRow>
	)
}

const renderAggregationCell = (node: TreeData) => {
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			{node.label && (
				<Stack direction="row" width="10%" justifyContent="space-between" alignItems="center">
					<Text variant="caption">{node.label}</Text>
					<IconButton size="small" onClick={(e) => onCopyGroup(e, node)}>
						<CopyAllOutlined />
					</IconButton>
				</Stack>
			)}
			{node?.aggregates && (
				<Box>
					<TableRow>
						{node.records.map(({ _id, name }) =>
							!_isUndefined(node.aggregates[_id]) ? (
								<TableCell key={`${_id}-aggregation-headers`}>{name}</TableCell>
							) : null,
						)}
					</TableRow>
					<TableRow>
						{node.records.map(({ _id, format, type }) => {
							let rowValue = node.aggregates[_id]
							if (_isUndefined(rowValue)) return null
							if (format == TableTypes.FormatType.currency) {
								rowValue = rowValue ? '$' + Number(rowValue).toFixed(2) : ''
							}
							if (format === TableTypes.FormatType.percentage) {
								rowValue = rowValue ? `${_round(Number(rowValue) * 100)} %` : ''
							}
							if (type === TableTypes.FieldType.DATE) {
								rowValue = rowValue ? dayjs(rowValue).format('MMM DD, YYYY') : ''
							}
							return (
								<TableCell
									key={`${_id}-aggregation`}
									sx={{ color: colors.feldgrau['60'], fontWeight: 800 }}
								>
									{_isNumber(rowValue) ? rowValue.toFixed(2) : rowValue}
								</TableCell>
							)
						})}
					</TableRow>
				</Box>
			)}
		</Stack>
	)
}

const Tree: ComponentType<{ node: TreeData; records?: TableTypes.Field[] }> = ({
	node,
	records = [],
}) => (
	<>
		{node.children ? (
			<TreeItem key={node._id} itemId={node._id} label={renderAggregationCell(node)}>
				{node.records && (
					<Records
						records={node.records}
						hasNestedChild={node.children.some((child: any) => child?.children)}
					/>
				)}
				{node.children.map((child: any) => (
					<Tree key={child._id} node={child} records={node.records} />
				))}
			</TreeItem>
		) : (
			renderChildProperties(node, records)
		)}
	</>
)
export const DataTree = ({ data }: { data: TreeData[] }) => {
	return (
		<SimpleTreeView>
			{data.map((item) => (
				<Tree key={item._id} node={item} />
			))}
		</SimpleTreeView>
	)
}

type Props = {
	rows: ResolvedRowData[]
	columns: TableTypes.Field[]
	view: TableTypes.TableView
}

export const PresentationView = ({ columns, view, rows }: Props) => {
	const { tableConfig } = useContext(TableContext)

	const treeData = useMemo((): TreeData[] => {
		if (!tableConfig) return []
		if (view?.groupedFields?.length > 0) {
			return generateTreeData(rows, columns, view.groupedFields)
		}
		return [
			{
				_id: tableConfig._id,
				children: getFormattedPercentageRows(rows, columns),
				label: tableConfig.name,
				records: columns,
				aggregates: {},
			},
		]
	}, [columns, rows, view])

	if (!tableConfig) return null
	return (
		<PresentationViewContext.Provider value={view}>
			<Button
				variant="text"
				endIcon={<CopyAllOutlined />}
				onClick={() => onCopyAllGroups(treeData)}
			>
				Copy all items
			</Button>
			<TableContainer>
				<Table>
					<DataTree data={treeData} />
				</Table>
			</TableContainer>
		</PresentationViewContext.Provider>
	)
}
