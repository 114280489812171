import { TableTypes } from '@cango-app/types'

import { TableAction, TableActionType, TableState } from 'src/providers/table-provider/types'

export const initialTableReducerState: TableState = {
	isFetching: false,
	isFetchingTableList: false,
	numberOfFetchTableRetries: 0,
	hasError: false,
	isUpdating: false,
	config: null,
	records: [],
	fields: [],
	recordsBeforeChange: {},
	newRecords: [],
	tableList: [],
	configBeforeChange: null,
	blueprintTable: null,
	referenceTables: {},
}

export const tableReducer = (state: TableState, action: TableAction): TableState => {
	switch (action.type) {
		case TableActionType.UPDATE_STATE:
			return {
				...state,
				...action.payload,
			}
		case TableActionType.UPDATE_AND_REMOVE:
			return {
				...state,
				...action.update,
				recordsBeforeChange: Object.keys(state.recordsBeforeChange).reduce(
					(acc: Record<string, TableTypes.Record>, key) => {
						if (action.payload.recordsBeforeChange[key]) {
							return acc
						}
						acc[key] = state.recordsBeforeChange[key]
						return acc
					},
					{},
				),
				newRecords: state.newRecords.filter(
					(recordId) => !action.payload.newRecords.includes(recordId),
				),
			}

		case TableActionType.RESET:
			return { ...initialTableReducerState }
		default:
			return state
	}
}
