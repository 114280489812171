import { ComponentType, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { Box } from 'src/components'
import { useIsMobile } from 'src/hooks/useMobile'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { TabPanel } from 'src/components/tab-panel'
import { TaskTraining } from 'src/modules/projects-v3/project-detail/task-drawer/configure-task/task-training'
import { TaskContext } from 'src/providers'

import { TaskAction } from '../components/actions'
import { BottomButtonBar } from '../components/single-task-bottom-button-bar'
import { TopButtonBar } from '../components/single-task-top-button-bar'
import { ChatModal } from '../components/task-chat'

enum TaskTab {
	Action = 0,
	Training = 1,
}

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export const SingleTaskComponent: ComponentType = () => {
	const isMobile = useIsMobile()
	const { task: selectedTask } = useContext(TaskContext)
	const selectedProject = useSelector(projectSelectors.getSelectedProject)
	const [selectedTab, setSelectedTab] = useState<TaskTab>(TaskTab.Action)
	return (
		<>
			<Box
				sx={{
					laptop: {
						bgcolor: 'white',
						borderRadius: '30px',
						px: 3,
						py: 2,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
					},
				}}
			>
				<TopButtonBar />
				{!!selectedTask?.description && (
					<Box mt={2}>
						<Alert severity="info">{selectedTask?.description}</Alert>
					</Box>
				)}

				<Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
					<Tab label="Actions" value={TaskTab.Action} {...a11yProps(0)} />
					{!!selectedTask?.training?.length && (
						<>
							<Tab
								label="Training"
								value={TaskTab.Training}
								sx={{ textTransform: 'none' }}
								{...a11yProps(1)}
							/>
						</>
					)}
				</Tabs>
				<TabPanel value={selectedTab} index={TaskTab.Action}>
					<TaskAction />
				</TabPanel>
				<TabPanel value={selectedTab} index={TaskTab.Training}>
					<TaskTraining
						task={selectedTask}
						activeValue={selectedTab}
						index={TaskTab.Training}
						containerProps={{ pt: 2 }}
					/>
				</TabPanel>
				{!isMobile && <BottomButtonBar />}
			</Box>
			{isMobile && <BottomButtonBar />}
			<ChatModal task={selectedTask} project={selectedProject} />
		</>
	)
}
