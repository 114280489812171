export type RecentlyViewedProject = {
	_id: string
	name: string
	blueprintId?: string
}

export enum ProjectGroupingType {
	Tags = 'tags',
	Sections = 'sections',
}

export enum TaskAttachmentDirection {
	Column = 'column',
	Row = 'row',
}

export interface PersistedConfigState {
	recentlyViewedProjects: { [organisationId: string]: RecentlyViewedProject[] }
	projectGrouping?: {
		[projectId: string]: ProjectGroupingType
	}
	showAnswers?: {
		[tableId: string]: {
			[columnId: string]: boolean
		}
	}
	columnVisibility?: {
		[tableId: string]: string[]
	}
	taskAttachmentDirection: TaskAttachmentDirection
}
