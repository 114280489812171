import React, { ComponentType, useCallback, useEffect, useState } from 'react'
import { ClientTypes, PermissionsTypes } from '@cango-app/types'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete, Stack } from '@mui/material'
import { UserSdk } from '@cango-app/sdk'
import _debounce from 'lodash/debounce'

import { Box, Text, Button, TextField, Select, UserAvatar } from 'src/components'
import { actions as userActions, selectors as userSelectors } from 'src/store/modules/user'
import { selectors as roleSelectors, actions as roleActions } from 'src/store/modules/roles'
import type { RootState } from 'src/store/types'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { getNameInitials } from 'src/routing/navigation/utils'

type UserFormProps = {
	user?: ClientTypes.User & { assignments?: number }
	closeDrawer: () => void
	setShouldPromptDelete: (tf: boolean) => void
	permissions: PermissionsTypes.Permissions[] | undefined
}

type UserFormValues = Omit<ClientTypes.User, '_id' | 'roles' | 'permissions'> & {
	roles: string[]
	permissions: string
}

export const UserForm: ComponentType<UserFormProps> = ({
	user,
	closeDrawer,
	setShouldPromptDelete,
	permissions,
}) => {
	const dispatch = useDispatch()
	const roles = useSelector((state: RootState) => roleSelectors.getRolesForSelect(state, true))
	const allRolesLength = useSelector(roleSelectors.getRoles).length
	const users = useSelector(userSelectors.getAllUsers)
	const [isUpdating, setIsUpdating] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [userEmails, setUserEmails] = useState<UserSdk.GetEmailUsersResponse>([])
	const { handleSubmit, control, watch, setValue } = useForm<UserFormValues>({
		defaultValues: {
			permissions: user?.organisations[0].permissions._id,
			name: user?.name,
			surname: user?.surname,
			roles: user?.organisations[0].roles || [],
			email: user?.email,
			phone: user?.phone,
		},
	})
	const watchEmail = watch('email')

	const validateEmailNotInUse = (inputtedEmailAddress: string): boolean => {
		const otherUsersWithEmail = users.find(({ email }) => email === inputtedEmailAddress)
		return !!user || !otherUsersWithEmail
	}

	const fetchUserEmails = useCallback(
		_debounce(async () => {
			try {
				const response = await UserSdk.getEmailUsers(
					import.meta.env.VITE_API as string,
					authHeaders,
					watchEmail,
				)
				setUserEmails(response)
			} catch (err) {}
		}, 1000),
		[watchEmail],
	)

	useEffect(() => {
		if (user || !watchEmail || watchEmail?.length < 3) return
		fetchUserEmails()
		return () => fetchUserEmails.cancel()
	}, [watchEmail, fetchUserEmails, user])

	const onSubmit = async (
		userData: Omit<ClientTypes.User, '_id' | 'roles' | 'permissions'> & {
			roles: string[]
			permissions: string
		},
	) => {
		setIsUpdating(true)
		if (user?._id) {
			await dispatch(userActions.updateUser({ userId: user._id, update: userData }))
		} else {
			await dispatch(userActions.createUser(userData))
		}
		setIsUpdating(false)
		closeDrawer()
	}

	const userHasAssignments = user?.assignments ? user.assignments > 0 : false

	useEffect(() => {
		if (!allRolesLength) {
			dispatch(roleActions.getRoles())
		}
	}, [])

	if (!permissions) return null

	return (
		<Box width={400} p={3} role="presentation">
			<Text sx={{ mb: 3 }} variant="h4">
				{user ? user.name + ' ' + user.surname : 'New User'}
			</Text>

			<form>
				<Controller
					name={'email'}
					control={control}
					rules={{
						required: true,
						validate: {
							checkEmail: (v) => validateEmailNotInUse(v) || 'Email address already in use',
						},
					}}
					render={({ field: { onChange, value }, fieldState: { error } }) =>
						user ? (
							<TextField
								error={!!error}
								disabled={!!user}
								onChange={onChange}
								value={value}
								helperText={!!error && error.message}
								required
								fullWidth
								label={'Email address'}
								containerProps={{ mb: 3 }}
							/>
						) : (
							<Autocomplete
								freeSolo
								options={userEmails}
								getOptionLabel={(user) => (typeof user === 'string' ? user : user.email)}
								onInputChange={(e, value, reason) => {
									if (reason === 'reset') {
										// this is true when user selects from user email list
										const selectedUser = userEmails.find(({ email }) => value === email)
										if (selectedUser) {
											setValue('email', selectedUser.email)
											setValue('name', selectedUser.name)
											setValue('surname', selectedUser.surname)
											setValue('phone', selectedUser?.phone)
										}
									}
								}}
								renderOption={(props, option) => (
									<Stack component="li" direction="row" spacing={1} {...props} key={option.email}>
										<UserAvatar
											picture={option.picture}
											userInitials={getNameInitials(option.name, option.surname)}
										/>
										<Text>{option.email}</Text>
									</Stack>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!!error}
										disabled={!!user}
										onChange={onChange}
										value={value}
										helperText={!!error && error.message}
										required
										fullWidth
										label={'Email address'}
										containerProps={{ mb: 3 }}
									/>
								)}
							/>
						)
					}
				/>
				<Controller
					name={'permissions'}
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Select
							error={!!error}
							label="What permission level is this user?"
							onChange={(event) => onChange(event.target.value)}
							value={value}
							options={permissions.map(({ _id, name }) => ({
								_id,
								label: `Level ${name}`,
							}))}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
				<Box display={'flex'}>
					<Controller
						name={'name'}
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								required
								fullWidth
								onChange={onChange}
								value={value}
								label={'Name'}
								containerProps={{ mb: 3, mr: 1 }}
							/>
						)}
					/>
					<Controller
						name={'surname'}
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								onChange={onChange}
								value={value}
								required
								fullWidth
								label={'Surname'}
								containerProps={{ mb: 3, ml: 1 }}
							/>
						)}
					/>
				</Box>
				<Controller
					name={'roles'}
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Select
							error={!!error}
							multiple
							multiline
							label="Which Role(s) does this User perform?"
							onChange={(event) => {
								onChange(
									(event.target.value as string[]).filter((_roleId) =>
										roles.some((_role) => _role._id === _roleId),
									),
								)
							}}
							value={value}
							options={roles}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
				<Controller
					name={'phone'}
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							error={!!error}
							onChange={onChange}
							value={value}
							fullWidth
							label={'Phone number'}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
			</form>

			<Button variant="contained" onClick={handleSubmit(onSubmit)} isLoading={isUpdating}>
				{user ? 'Update' : 'Create'}
			</Button>
			{!!user && (
				<Button
					onClick={() => setShouldPromptDelete(true)}
					variant="outlined"
					color="error"
					disabled={userHasAssignments}
					sx={{ float: 'right' }}
				>
					{userHasAssignments ? 'User is assigned' : 'Delete'}
				</Button>
			)}
		</Box>
	)
}
