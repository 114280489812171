import { ComponentType } from 'react'
import MobileStepper from '@mui/material/MobileStepper'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { colors } from 'src/theme/colors'

import { Button } from '../button'

type Props = {
	handleBefore: () => void
	activeQuestionIndex: number
	numberOfSteps: number
	handleIsGoingForward: (nextValue: boolean) => void
	handleNext: () => void
	canGoForward: boolean
}

export const QuestionStepper: ComponentType<Props> = ({
	activeQuestionIndex,
	handleBefore,
	numberOfSteps,
	handleIsGoingForward,
	handleNext,
	canGoForward,
}) => {
	return (
		<MobileStepper
			variant="progress"
			steps={numberOfSteps}
			position="static"
			activeStep={activeQuestionIndex}
			sx={{
				width: 550,
				marginTop: 4,
				backgroundColor: colors.neutral['10'],
			}}
			nextButton={
				<Button
					size="small"
					variant="text"
					onClick={handleNext}
					disabled={!canGoForward}
					endIcon={<KeyboardArrowRight />}
				>
					Next
				</Button>
			}
			backButton={
				<Button
					size="small"
					variant="text"
					onClick={handleBefore}
					startIcon={<KeyboardArrowLeft />}
					onMouseEnter={() => handleIsGoingForward(false)}
					disabled={activeQuestionIndex === 0}
					sx={{ marginRight: 1 }}
				>
					Back
				</Button>
			}
		/>
	)
}
