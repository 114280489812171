import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../types'

import { RoleWithUsage } from './types'

const getRolesState = (state: RootState) => state.cangoRoles

const getRoles: (state: RootState) => RoleWithUsage[] = createSelector(
	getRolesState,
	({ roles }) => roles,
)

const getRoleById = (state: RootState, roleId?: string): RoleWithUsage | undefined => {
	const roles = getRoles(state)
	return roles.find(({ _id }) => _id === roleId)
}

const getRolesForSelect: (state: RootState, internalOnly?: boolean) => RoleWithUsage[] =
	createSelector(
		getRoles,
		(state: RootState, internalOnly?: boolean) => !!internalOnly,
		(roles, internalOnly) => {
			let rolesForSelect = roles
			if (internalOnly) {
				rolesForSelect = roles.filter(({ internal }) => internal)
			}
			return rolesForSelect.map((role) => ({
				...role,
				value: role._id as string,
			}))
		},
	)

const getAllRolesList: (state: RootState) => RoleWithUsage[] = createSelector(
	getRolesForSelect,
	(roles) => roles,
)

const getMappedRoles = createSelector(
	getRoles,
	(roles) => new Map(roles.map((role) => [role._id, role])),
)

export const selectors = {
	getRoles,
	getRoleById,
	getRolesForSelect,
	getMappedRoles,
	getAllRolesList,
}
