import { Controller, useFormContext } from 'react-hook-form'
import React from 'react'

import { TableFilters } from 'src/components/table-filters'

import { SingleSelectForm } from '../types'

type Props = {
	index: number
}

export const TableOptionsFilters = ({ index }: Props) => {
	const { control } = useFormContext<SingleSelectForm>()

	return (
		<Controller
			control={control}
			name={`valueOptionFilters.${index}.filters`}
			render={({ field: { onChange } }) => <TableFilters onChange={onChange} />}
		/>
	)
}
