type UserOrganisation = {
	name: string
	organisationCode: string
	jwt?: string
}

export enum UserOrganisationState {
	pending = 'pending',
	fetched = 'fetched',
	failed = 'failed',
	idle = 'idle',
}

export interface AuthState {
	defaultOrganisationId?: string
	lastVisitedOrganisationId?: string
	userOrganisations: {
		[organisationId: string]: UserOrganisation
	}
	loginToken?: string
	firebaseToken?: string
	isAuthenticating: boolean
	organisationState: UserOrganisationState
}
