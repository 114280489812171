import {
	ComponentType,
	MutableRefObject,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react'
import List from '@mui/material/List'
import {
	Controller,
	FieldArrayWithId,
	FormProvider,
	useFieldArray,
	useForm,
	useFormContext,
} from 'react-hook-form'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { v4 } from 'uuid'
import ListItemIcon from '@mui/material/ListItemIcon'
import Stack from '@mui/material/Stack'
import { ListItemSecondaryAction } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { ImagesSdk } from '@cango-app/sdk'
import { TableTypes } from '@cango-app/types'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import {
	Box,
	Button,
	IconButton,
	InternalImageUpload,
	Modal,
	Select,
	Text,
	TextField,
} from 'src/components'
import { colors } from 'src/theme/colors'
import { TrashIcon } from 'src/assets/icons'
import { moveItemInArray } from 'src/helpers/operations'
import { TableContext } from 'src/providers'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as authSelectors } from 'src/store/modules/auth'

import { convertRowValueToQuestionaireOption } from './questionaire-logic/utils'
import { ListLetter } from './questionaire-logic/list-letter'

type ListOptionsModalProps = {
	onClose: () => void
	columnId: string
	row: TableTypes.Record
	apiRef: MutableRefObject<GridApiPremium>
}

type ListOptionsForm = {
	answerType: TableTypes.AnswerType
	options: TableTypes.ListOption[]
}

const ListOption: ComponentType<{ index: number; onDelete: () => void }> = ({
	index,
	onDelete,
}) => {
	const { watch, control } = useFormContext<ListOptionsForm>()
	const [showImageUpload, setShowImageUpload] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const [images, setImages] = useState<Map<string, string>>(new Map())
	const options = watch('options')
	const someHaveImages = options.some((option) => option.image?.path)

	const fetchImages = useCallback(async () => {
		try {
			const response = await ImagesSdk.getBucket(import.meta.env.VITE_API as string, authHeaders)
			setImages(new Map(response.map((_response) => [_response.name, _response.url])))
		} catch (error) {
			showSnackbar('Failed to fetch images', { variant: 'error' })
		}
	}, [])

	useEffect(() => {
		fetchImages()
	}, [])

	return (
		<ListItem
			sx={{ border: '1px solid', borderColor: colors.neutral['40'], mb: 2, borderRadius: '8px' }}
		>
			{showImageUpload && (
				<Controller
					control={control}
					name={`options.${index}.image`}
					render={({ field: { onChange } }) => (
						<InternalImageUpload
							open={true}
							onClose={() => setShowImageUpload(false)}
							onImageSelect={({ name, url }) => {
								onChange({
									path: name,
									url,
								})
								setImages((prev) => new Map([...prev, [name, url]]))
							}}
						/>
					)}
				/>
			)}
			<ListItemIcon>
				<ListLetter alphabetIndex={index + 1} />
			</ListItemIcon>
			{someHaveImages && (
				<ListItemIcon>
					<Controller
						control={control}
						name={`options.${index}.image`}
						render={({ field: { value, onChange } }) => {
							const valueUrl = images.get(value?.path ?? '')
							return (
								<Box mr={1} width={170} position="relative">
									{!!valueUrl && (
										<>
											<img
												srcSet={valueUrl}
												src={valueUrl}
												alt={value?.path}
												loading="lazy"
												style={{
													width: 170,
												}}
											/>
											<Box
												sx={{
													cursor: 'pointer',
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													bgcolor: 'rgba(224, 122, 95, 0.5)',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													opacity: 0,
													'&:hover': {
														opacity: 0.9,
													},
												}}
												onClick={() => onChange({})}
											>
												<TrashIcon stroke="#fff" />
											</Box>
										</>
									)}
								</Box>
							)
						}}
					/>
				</ListItemIcon>
			)}
			<ListItemText>
				<Controller
					control={control}
					name={`options.${index}.label`}
					render={({ field: { value, onChange } }) => (
						<TextField
							value={value}
							onChange={onChange}
							fullWidth
							containerProps={{ width: 300 }}
						/>
					)}
				/>
			</ListItemText>
			<ListItemSecondaryAction sx={{ ml: 2 }}>
				<Stack direction="row">
					<IconButton onClick={() => setShowImageUpload(true)}>
						<ImageIcon />
					</IconButton>
					<IconButton onClick={onDelete}>
						<TrashIcon stroke={colors.error.dark} />
					</IconButton>
				</Stack>
			</ListItemSecondaryAction>
		</ListItem>
	)
}

const OptionsList: ComponentType<{
	fields: FieldArrayWithId<ListOptionsForm['options']>[]
	onRemove: (index: number) => void
}> = ({ fields, onRemove }) => {
	const { watch, setValue } = useFormContext<ListOptionsForm>()
	const options = watch('options')

	const handleDragEnd = (result: DropResult) => {
		const optionsCopy = [...options]
		if (!result.source || !result.destination) return
		const newArray = moveItemInArray(optionsCopy, result.source.index, result.destination.index)
		setValue('options', newArray)
	}
	return (
		<>
			<Text variant="h6">Options</Text>
			<Box maxHeight={500} overflow="scroll">
				<List>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="droppable">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{fields.map((_field, index) => (
										<Draggable key={_field.id} draggableId={_field.id} index={index}>
											{(provided, snapshot) => {
												if (snapshot.isDragging) {
													const offsetEl = document.querySelector(
														`[data-rbd-draggable-id='${provided.draggableProps['data-rbd-draggable-id']}']`,
													) as HTMLElement
													provided.draggableProps.style = {
														...provided.draggableProps.style,
														left: offsetEl ? offsetEl.offsetLeft : 0,
														top: offsetEl ? offsetEl.offsetTop : 0,
													} as any
												}
												return (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<ListOption
															key={_field.id}
															index={index}
															onDelete={() => onRemove(index)}
														/>
													</div>
												)
											}}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</List>
			</Box>
		</>
	)
}

export const ListOptionsModal: ComponentType<ListOptionsModalProps> = ({
	onClose,
	row,
	columnId,
	apiRef,
}) => {
	const { updateRecords, mappedRecords } = useContext(TableContext)
	const form = useForm<ListOptionsForm>({
		defaultValues: convertRowValueToQuestionaireOption(row.data[columnId]),
	})
	const { fields, remove, append } = useFieldArray({ control: form.control, name: 'options' })
	const answerType = form.watch('answerType')

	const modalRef = useRef<HTMLDivElement | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const handleSave = async (data: ListOptionsForm) => {
		const oldRow = apiRef.current.getRow(row._id)
		const originalRecord = mappedRecords.get(row._id)
		if (!originalRecord) {
			return
		}
		const optionsWithoutLinks = data.options.map((option) => ({
			...option,
			image: {
				...option.image,
				url: undefined,
			},
		}))
		const options = ![
			TableTypes.AnswerType.SingleSelect,
			TableTypes.AnswerType.MultiSelect,
		].includes(data.answerType)
			? []
			: optionsWithoutLinks
		const newRecord = { ...originalRecord, data: { ...oldRow, [columnId]: { ...data, options } } }
		setIsSaving(true)
		await updateRecords({ rows: [{ newRecord, oldRecord: originalRecord }], save: true })
		setIsSaving(false)
		onClose()
	}

	const handleChangeAnswerType = (answerType: TableTypes.AnswerType) => {
		if (
			![TableTypes.AnswerType.MultiSelect, TableTypes.AnswerType.SingleSelect].includes(answerType)
		) {
			form.setValue('options', [])
		}
		form.setValue('answerType', answerType)
	}

	return (
		<Modal ref={modalRef} open={true} onClose={onClose}>
			<FormProvider {...form}>
				<Box width={700} minHeight={500} maxHeight="90vh" py={1}>
					<Controller
						control={form.control}
						name="answerType"
						render={({ field: { value } }) => (
							<Select
								value={value}
								options={Object.values(TableTypes.AnswerType).map((label) => ({
									_id: label,
									label,
								}))}
								onChange={(e) => handleChangeAnswerType(e.target.value as TableTypes.AnswerType)}
							/>
						)}
					/>
					{[TableTypes.AnswerType.SingleSelect, TableTypes.AnswerType.MultiSelect].includes(
						answerType,
					) && <OptionsList fields={fields} onRemove={remove} />}
					<Stack direction="row" pt={2} justifyContent="space-between">
						<Button
							variant="outlined"
							size="small"
							onClick={() =>
								append({
									_id: v4(),
									label: '',
								})
							}
						>
							Create option
						</Button>
						<Button size="small" onClick={form.handleSubmit(handleSave)} isLoading={isSaving}>
							Save
						</Button>
					</Stack>
				</Box>
			</FormProvider>
		</Modal>
	)
}
