import React, { useCallback, useContext, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Controller, useForm } from 'react-hook-form'
import { TableTypes, V3BlueprintTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { getFieldType } from 'src/modules/tables/mui-formatter'
import { applyFilterModelToRow } from 'src/modules/tables/utils'
import { TaskContext, TableContext } from 'src/providers'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import { Box, Button, Text, Select } from '../components'

type RoleContactSelectList = {
	[roleId: string]: { _id: string; label: string }[]
}

export enum StatusType {
	Confirmed = 'confirmed',
	Rejected = 'rejected',
}

type ResolveData = {
	status: StatusType
	data: { [contactId: string]: string[] }
}

type PromiseHandlers = {
	resolve: (data: ResolveData) => void
	column: TableTypes.Field
	nextTaskName: string
	selectedRoles: { [roleId: string]: string[] }
}

const useConfirmRoleChains = (): [
	JSX.Element,
	(data: {
		filter: V3BlueprintTypes.DatabaseChainLogic & { stepName: string; descendantId: string }
	}) => Promise<ResolveData>,
] => {
	const [promise, setPromise] = useState<PromiseHandlers | null>(null)
	const { mappedColumns, resolvedRows } = useContext(TableContext)
	const { task } = useContext(TaskContext)
	const contacts = useSelector(contactSelectors.getContacts)
	const mappedRoles = useSelector(roleSelectors.getMappedRoles)
	const projectTasks = useSelector(projectSelectors.getProjectTasks)
	const [isLoading] = useState(false)
	const { control, handleSubmit, getValues, reset } = useForm<{
		[roleId: string]: string[]
	}>({
		defaultValues: {},
	})

	const contactsForRoles = useMemo((): RoleContactSelectList => {
		if (!promise?.selectedRoles) {
			return {}
		}
		return Object.keys(promise.selectedRoles).reduce((acc: RoleContactSelectList, _roleId) => {
			const contactsForRole = contacts.filter((_contact) => _contact.roles.includes(_roleId))
			acc[_roleId] = contactsForRole.map((_contact) => ({
				_id: _contact._id,
				label: `${_contact.name} ${_contact.surname}`,
			}))
			return acc
		}, {})
	}, [promise, contacts])

	const confirm = (data: {
		filter: V3BlueprintTypes.DatabaseChainLogic & { stepName: string; descendantId: string }
	}): Promise<ResolveData> => {
		return new Promise((resolve) => {
			const column = mappedColumns.get(data.filter.column)
			if (!column || column.type !== TableTypes.FieldType.ROLE) {
				return
			}

			const columnsWithTypes = [...mappedColumns.values()].map((_column) => ({
				_id: _column._id,
				type: getFieldType(_column.type, _column.returnType),
			}))

			const filteredRows = resolvedRows.filter((_row) => {
				const nextStepFilters = data.filter.filters
				if (
					nextStepFilters &&
					!applyFilterModelToRow({
						columns: columnsWithTypes,
						row: _row,
						filterModel: nextStepFilters,
					})
				) {
					return false
				}

				if (
					!task?.chain?.database_chain_logic.filters.items.length
					// TODO check out why this condition is here
					// || task.step?.isLastStepInChain
				) {
					return true
				}

				return applyFilterModelToRow({
					columns: columnsWithTypes,
					row: _row,
					filterModel: task.chain.database_chain_logic.filters,
				})
			})

			const selectedRoles = filteredRows.reduce((acc: { [roleId: string]: string[] }, _row) => {
				const roleInRow = _row[column._id] as string
				if (!roleInRow || acc[roleInRow]) {
					return acc
				}
				if (
					projectTasks.some(
						(_task) =>
							_task.chain?.original_descendant_id === data.filter.descendantId &&
							!_task.lifecycle.complete &&
							_task.chain?.label.selected_option === roleInRow,
					)
				) {
					return acc
				}

				const contactsForRole = contacts.filter((_contact) => _contact.roles.includes(roleInRow))
				acc[roleInRow] = contactsForRole.map((_contact) => _contact._id)
				return acc
			}, {})
			reset(selectedRoles)
			setPromise({
				resolve,
				column,
				selectedRoles,
				nextTaskName: data.filter.stepName,
			})
		})
	}

	const handleClose = () => {
		setPromise(null)
	}

	const handleConfirm = async () => {
		if (!promise) {
			return
		}
		const data = getValues()

		const contactSelections = Object.keys(data).reduce(
			(acc: { [contactId: string]: string[] }, _roleId) => {
				const contactIds = data[_roleId]
				contactIds.forEach((_contactId) => {
					if (!acc[_contactId]) {
						acc[_contactId] = []
					}
					acc[_contactId].push(_roleId)
				})
				return acc
			},
			{},
		)

		promise.resolve({ status: StatusType.Confirmed, data: contactSelections })
		handleClose()
	}

	const handleCancel = useCallback(() => {
		promise?.resolve({ status: StatusType.Rejected, data: {} })
		handleClose()
	}, [promise])

	// You could replace the Dialog with your library's version
	const RoleAssignmentDialog = useMemo(
		() => (
			<Dialog
				open={promise !== null}
				fullWidth
				PaperProps={{
					sx: { borderRadius: 2, p: 2 },
				}}
			>
				<DialogContent>
					<Box mb={1}>
						<Box>
							<Text fontWeight="bold" variant="overline" fontSize={16}>
								Confirm contacts for {promise?.column.name}
							</Text>
						</Box>
						<Box>
							<Text variant="overline" fontSize={16}>
								Next task: <b>{promise?.nextTaskName}</b>
							</Text>
						</Box>
						{!_isEmpty(promise?.selectedRoles) ? (
							<Text>This will create tasks for each contact selected</Text>
						) : (
							<Text>All roles are accounted for</Text>
						)}
					</Box>
					<Box>
						{Object.keys(promise?.selectedRoles ?? {}).map((_roleId) => {
							const roleName = mappedRoles.get(_roleId)?.label ?? ''
							return (
								<Box key={_roleId}>
									<Controller
										control={control}
										name={_roleId}
										render={({ field: { value, onChange } }) => (
											<Select
												value={value ?? []}
												onChange={onChange}
												label={roleName}
												options={contactsForRoles[_roleId] ?? []}
												multiple
												containerProps={{ mb: 1 }}
											/>
										)}
									/>
								</Box>
							)
						})}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleConfirm} isLoading={isLoading}>
						Confirm
					</Button>
					<Button onClick={handleCancel} variant="outlined">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		),
		[promise, isLoading, handleSubmit, handleConfirm, control],
	)

	return [RoleAssignmentDialog, confirm]
}

export default useConfirmRoleChains
