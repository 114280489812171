import { ComponentType, useContext, useMemo } from 'react'
import { V3BlueprintTypes } from '@cango-app/types'
import { Controller, useFormContext } from 'react-hook-form'

import { Select } from 'src/components'
import { DependencyForm } from 'src/modules/chains/components/chain-dependency-edge/types'
import { TableContext } from 'src/providers'

type ChainEndingsSelectionProps = {
	allThreads: Array<V3BlueprintTypes.Thread & { descendantId: string }>
}

export const ChainEndingsSelection: ComponentType<ChainEndingsSelectionProps> = ({
	allThreads,
}) => {
	const { control } = useFormContext<DependencyForm>()
	const { mappedColumns } = useContext(TableContext)
	const threadsAsOptions = useMemo(() => {
		const fullThreads = allThreads.filter((_thread) => _thread.prefix)
		return fullThreads.map((_thread) => {
			let label = _thread.prefix
			const column = mappedColumns.get(_thread.prefix)
			if (column) {
				label = column.name
			}
			return {
				_id: _thread.descendantId,
				label: label,
			}
		})
	}, [allThreads, mappedColumns])

	return (
		<Controller
			control={control}
			name="chain_endings"
			render={({ field: { value, onChange } }) => (
				<Select
					options={threadsAsOptions}
					label="End of chains"
					value={value}
					onChange={onChange}
					multiple
					containerProps={{ mt: 1 }}
				/>
			)}
		/>
	)
}
