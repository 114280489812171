import { Alert, AlertTitle, Stack } from '@mui/material'
import { useState } from 'react'

import { Box, Modal, Text, Button, TextField } from 'src/components'

type Props = {
	open: boolean
	isLoading: boolean
	onClose: () => void
	projectName: string
	onDelete: () => void
}

export const DeleteProjectModal = ({ onClose, onDelete, open, projectName, isLoading }: Props) => {
	const [input, setInput] = useState('')

	const handleClose = () => {
		setInput('')
		onClose()
	}
	return (
		<Modal open={open} onClose={handleClose}>
			<Stack direction="column" spacing={2} width={450}>
				<Text variant="h4">Delete {projectName}</Text>
				<Alert severity="warning">
					<AlertTitle>You will not be able to restore this project</AlertTitle>
				</Alert>
				<Text sx={{ mb: 2 }} fontSize={14}>
					Enter <b style={{ textDecoration: 'underline' }}>{projectName}</b> into the text box below
					to confirm that you want to delete this project
				</Text>
				<TextField
					fullWidth
					placeholder={projectName}
					value={input}
					onChange={(e) => setInput(e.target.value)}
				/>
				<Box display="flex" justifyContent="space-between" flex={1}>
					<Button variant="outlined" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						color="error"
						disabled={input !== projectName}
						onClick={onDelete}
						isLoading={isLoading}
					>
						Delete Project
					</Button>
				</Box>
			</Stack>
		</Modal>
	)
}
