import React, { ComponentType, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Stack from '@mui/material/Stack/Stack'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit'
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit'

import { Box, Text } from 'src/components'
import { TabPanel } from 'src/components/tab-panel'
import { TaskContext } from 'src/providers'
import {
	selectors as persistedConfigSelectors,
	actions as persistedConfigActions,
	TaskAttachmentDirection,
} from 'src/store/modules/persisted-config'
import { relativeTimeLabel } from 'src/helpers/labels'

import { CompleteTaskOps } from './complete-task-ops'
import { ActionFields } from './action-fields'
import { TaskDrawerTab } from './task-form-container'

type TaskInfoProps = {
	activeTab: TaskDrawerTab
}

export const TaskInfo: ComponentType<TaskInfoProps> = ({ activeTab }) => {
	const { task } = useContext(TaskContext)
	const dispatch = useDispatch()
	const taskAttachmentDirection =
		useSelector(persistedConfigSelectors.getTaskAttachmentDirection) ?? 'vertical'

	const completedWording = useMemo(() => {
		if (!task) return ''
		if (task.isMultiUse) return 'Instance created'
		if (task.lifecycle.completed_options.length > 2) return 'Decision made'
		return 'Completed'
	}, [task?.isMultiUse, task?.lifecycle.completed_options.length])

	const handleChangeTaskAttachmentDirection = (newValue: TaskAttachmentDirection | null) => {
		if (!newValue) {
			return
		}
		dispatch(persistedConfigActions.setTaskAttachmentDirection(newValue))
	}

	if (!task) {
		return null
	}

	return (
		<TabPanel index={TaskDrawerTab.Info} value={activeTab} containerProps={{ pt: 2 }}>
			<Box>
				{!!task.lifecycle.completed_at?.length && (
					<Box mb={2}>
						<Alert severity={task.isMultiUse ? 'info' : 'success'}>
							<AlertTitle>{completedWording}</AlertTitle>
							{relativeTimeLabel(
								task.lifecycle.completed_at[task.lifecycle.completed_at?.length - 1],
							)}
						</Alert>
					</Box>
				)}
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					{(!task.lifecycle.complete || !!task.lifecycle.completed_options?.length) && (
						<>
							{!!task.lifecycle.completed_options?.length &&
								task.lifecycle.complete &&
								task.lifecycle.completed_options.map((option) => (
									<Text style={{ fontWeight: 'bold' }} key={option._id}>
										{option.label}
									</Text>
								))}
							{!task.lifecycle.complete && <CompleteTaskOps />}
						</>
					)}
					<Box>
						<ToggleButtonGroup
							value={taskAttachmentDirection}
							exclusive
							onChange={(e, newValue: TaskAttachmentDirection | null) =>
								handleChangeTaskAttachmentDirection(newValue)
							}
						>
							<ToggleButton value={TaskAttachmentDirection.Column}>
								<HorizontalSplitIcon sx={{ transform: 'rotate(180deg)' }} />
							</ToggleButton>
							<ToggleButton value={TaskAttachmentDirection.Row}>
								<VerticalSplitIcon sx={{ transform: 'rotate(180deg)' }} />
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
				</Stack>
			</Box>
			<ActionFields />
		</TabPanel>
	)
}
