import React, { ComponentType, useContext, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'

import { TableContext } from 'src/providers'

import { Box } from '../box'
import { TableFilters } from '../table-filters'

export const ColumnsAndFilters: ComponentType<{ viewId: string | undefined }> = ({ viewId }) => {
	const { tableConfig } = useContext(TableContext)
	const { control } = useFormContext<TableTypes.TableView>()

	const selectedView = useMemo(() => {
		if (!tableConfig || !viewId) {
			return
		}

		return tableConfig.views.find((view) => view._id === viewId)
	}, [viewId, tableConfig?.views])

	if (viewId && !selectedView) {
		return null
	}

	return (
		<Box>
			<Box height={50} width={175}>
				<Controller
					control={control}
					name="filters"
					render={({ field: { onChange } }) => {
						return (
							<TableFilters
								initialState={{
									filter: {
										filterModel: selectedView?.filters,
									},
								}}
								onChange={onChange}
							/>
						)
					}}
				/>
			</Box>
		</Box>
	)
}
