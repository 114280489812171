import { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { TableTypes } from '@cango-app/types'
import { ImagesSdk } from '@cango-app/sdk'
import { useSelector } from 'react-redux'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { TableContext } from 'src/providers'

import { Box } from '../box'

import { QuestionStepper } from './question-stepper'
import { QuestionCard } from './question-card'
import { QuestionTitle } from './question-title'
import { QuestionEffect } from './question-effect'
import { AnswerManager } from './answers-manager'
import { Question } from './types'

type QuestionFlowContainer = {
	questions: Question[]
	onAnswerClick: (
		answerId: TableTypes.QuestionaireResponseItem[],
		existingResponse: TableTypes.QuestionaireAnswer | undefined,
	) => void
	activeQuestionIndex: number
	goBack: () => void
	goForward: (question: Question, answer: TableTypes.QuestionaireResponseItem[]) => void
}

const findResponse = (
	_response: TableTypes.QuestionaireAnswer,
	activeQuestion: Question,
	questionScope: {
		column_id: string
		value: string
	}[],
) => {
	if (_response.question_id !== activeQuestion.questionRowId) {
		return
	}

	if (_response.scope.length !== questionScope.length) {
		return
	}

	if (
		_response.scope.every(({ column_id, value }) => activeQuestion.hierarchy[column_id] === value)
	) {
		return _response
	}
}

export const QuestionFlowContainer: ComponentType<QuestionFlowContainer> = ({
	questions,
	onAnswerClick,
	goBack,
	activeQuestionIndex,
	goForward,
}) => {
	const [isGoingForward, setIsGoingForward] = useState(true)
	const { tableConfig } = useContext(TableContext)
	const activeQuestion = useMemo(() => {
		return questions[activeQuestionIndex]
	}, [activeQuestionIndex, questions])
	const [images, setImages] = useState<Map<string, string>>(new Map())
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const response = useMemo(() => {
		const questionScope = activeQuestion?.hierarchy
			? Object.entries(activeQuestion.hierarchy).map(([column_id, value]) => ({
					column_id,
					value,
				}))
			: []
		return tableConfig?.questionaire_answers?.find((_response) =>
			findResponse(_response, activeQuestion, questionScope),
		)
	}, [tableConfig, activeQuestion])

	const handleAnswerClick = useCallback(
		(_newAnswer: TableTypes.QuestionaireResponseItem[]) => {
			setIsGoingForward(true)
			onAnswerClick(_newAnswer, response)
		},
		[response, onAnswerClick],
	)

	const fetchImages = useCallback(async () => {
		try {
			const response = await ImagesSdk.getBucket(import.meta.env.VITE_API as string, authHeaders)
			setImages(new Map(response.map((_response) => [_response.name, _response.url])))
		} catch (error) {
			showSnackbar('Failed to fetch images', { variant: 'error' })
		}
	}, [])

	useEffect(() => {
		fetchImages()
	}, [])

	return (
		<Box>
			<Box flex={1} padding={4} display="flex" flexDirection="column" alignItems="center">
				{!!activeQuestion && (
					<QuestionEffect activeQuestionId={activeQuestion?._id} isGoingForward={isGoingForward}>
						<QuestionCard>
							<QuestionTitle activeQuestion={activeQuestion} />
							<AnswerManager
								answerOptions={activeQuestion.options}
								onAnswerClick={handleAnswerClick}
								response={response}
								answerType={activeQuestion.type}
								images={images}
							/>
						</QuestionCard>
					</QuestionEffect>
				)}
				<QuestionStepper
					activeQuestionIndex={activeQuestionIndex}
					handleBefore={goBack}
					canGoForward={!!response?.answer?.length}
					handleNext={() => goForward(activeQuestion, response?.answer || [])}
					handleIsGoingForward={(goingForward: boolean) => setIsGoingForward(goingForward)}
					numberOfSteps={questions.length}
				/>
			</Box>
		</Box>
	)
}
