import { ComponentType, useCallback, useContext, useState } from 'react'
import { Fab, Stack } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import SendIcon from '@mui/icons-material/Send'
import { PulseLoader } from 'react-spinners'

import { TaskContext } from 'src/providers'
import { TextField, Box, Text, IconButton } from 'src/components'
import { colors } from 'src/theme/colors'
import { EditPenIcon, PlusIcon, TrashIcon } from 'src/assets/icons'
import { showSnackbar } from 'src/helpers/snackbarManager'

type AnnotationsForm = {
	annotation: string
}

export const Annotations: ComponentType = () => {
	const { task, updateTask, isLoadingTask } = useContext(TaskContext)
	const [selectedAnnotationId, setSelectedAnnotationId] = useState<string | undefined>()

	const { control, handleSubmit, setValue, reset } = useForm<AnnotationsForm>({
		defaultValues: {
			annotation: '',
		},
	})

	if (!task) {
		return null
	}

	const onClick = useCallback(
		({ annotation }: AnnotationsForm) => {
			if (!annotation) {
				showSnackbar('Note cannot be empty', { variant: 'error' })
				return
			}
			if (selectedAnnotationId) {
				const updatedAnnotations = task.annotations?.map((note) => {
					if (note._id === selectedAnnotationId) {
						return {
							_id: note._id,
							annotation,
						}
					}
					return note
				})
				updateTask('annotations', updatedAnnotations, { updateDb: true })
				setSelectedAnnotationId(undefined)
			} else {
				updateTask('annotations', [{ _id: v4(), annotation }, ...(task.annotations ?? [])], {
					updateDb: true,
				})
			}
			reset()
		},
		[
			selectedAnnotationId,
			setSelectedAnnotationId,
			updateTask,
			task.annotations,
			reset,
			showSnackbar,
		],
	)

	const onRemove = useCallback(
		(removeId: string) => {
			setSelectedAnnotationId(undefined)
			const filteredAnnotations = task.annotations?.filter(({ _id }) => _id !== removeId)
			updateTask('annotations', filteredAnnotations, { updateDb: true })
		},
		[setSelectedAnnotationId, updateTask, task.annotations],
	)

	return (
		<Stack direction="column" spacing={1} width="100%" my={1}>
			<Controller
				control={control}
				name="annotation"
				render={({ field: { value, onChange } }) => (
					<Box position="relative">
						<TextField
							fullWidth
							multiline
							minRows={5}
							label="Notes"
							value={value}
							onChange={onChange}
						/>
						<Fab
							sx={{
								position: 'absolute',
								bottom: 10,
								right: 10,
							}}
							size="small"
							onClick={handleSubmit(onClick)}
						>
							{isLoadingTask ? (
								<PulseLoader size={4} />
							) : selectedAnnotationId ? (
								<SendIcon fontSize="small" />
							) : (
								<PlusIcon fontSize="small" />
							)}
						</Fab>
					</Box>
				)}
			/>

			{task.annotations.map(({ annotation, _id }) => {
				return (
					<Stack
						direction="row"
						key={_id}
						my={1}
						px={2}
						py={2}
						borderRadius={4}
						bgcolor={colors.neutral['30']}
					>
						<Text flex={1}>{annotation}</Text>
						<IconButton>
							<EditPenIcon
								onClick={() => {
									setSelectedAnnotationId(_id)
									setValue('annotation', annotation)
								}}
								stroke={colors.feldgrau['80']}
							/>
						</IconButton>
						<IconButton>
							<TrashIcon onClick={() => onRemove(_id)} stroke={colors.feldgrau['80']} />
						</IconButton>
					</Stack>
				)
			})}
		</Stack>
	)
}
