import { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { V3ProjectSdk } from '@cango-app/sdk'
import { V3BlueprintTypes, TableTypes, V3ProjectTypes, V3ClientTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import dayjs, { ManipulateType } from 'dayjs'
import { SelectChangeEvent, Stack } from '@mui/material'
import { GridColType } from '@mui/x-data-grid-premium'
import { Controller, useForm } from 'react-hook-form'
import _uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import _cloneDeep from 'lodash/cloneDeep'
import _isString from 'lodash/isString'

import { Box, Button, Modal, RemoveFlaggedTaskModal, Select, TextField, Text } from 'src/components'
import { showSnackbar } from 'src/helpers/snackbarManager'
import {
	actions as projectActions,
	CompleteTaskResponse,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { TableContext, TaskContext } from 'src/providers'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { useListOptions } from 'src/hooks/use-list-options'
import useDebouncedCallback from 'src/hooks/useDebouncedCallback'
import { UpdateRecordsRequestParams } from 'src/providers/table-provider/types'
import { filterDescendantsBySelection } from 'src/providers/task-provider/selectors'

import {
	applyFilterModelToRow,
	getFilteredChains,
	getFilters,
	ResolvedRowData,
} from '../../tables/utils'
import useConfirmRoleChains from '../../../hooks/use-confirm-role-chains'

import { ArchiveButtons } from './archive-buttons'

const getRemainingTime = (
	createAt: number,
	recommendedTimeNumber?: number,
	recommendedTimeFormat?: ManipulateType,
) => {
	if (!recommendedTimeNumber || !recommendedTimeFormat) return ''
	const dueTime = dayjs.unix(createAt).add(recommendedTimeNumber, recommendedTimeFormat)

	const diffDays = dueTime.diff(dayjs(), 'day')
	if (diffDays > 0) {
		return `${diffDays} ${diffDays === 1 ? 'day' : 'days'}`
	}
	return `${dueTime.diff(dayjs(), 'hour')} hour(s)`
}

const getWarningText = (when: V3BlueprintTypes.WhenDueTime | undefined) => {
	if (!when) return ''
	if (when === V3BlueprintTypes.WhenDueTime.before) {
		return 'The remaining time for this task is:'
	}
	return 'You are trying to complete this task ahead of time. You still have'
}

export type OnPostCompleteTaskProps = {
	status: 'fulfilled' | 'rejected'
	payload: CompleteTaskResponse
	hasResolveFlagged?: boolean
}

type CompleteTaskButtonProps = {
	onPreComplete?: () => 'continue' | 'cancel'
	onPostComplete?: (data: OnPostCompleteTaskProps) => void
	sectionId?: string
}

type CustomInputForm = {
	inputs: {
		[columnId: string]: string
	}
	rowsForDuplication: string[]
}

const updateDatabaseRows = ({
	resolvedRows,
	task,
	columnsWithFilterType,
	descendants,
	newSelectedOptions,
	previousSelectedOptions,
	mappedRecords,
	mappedColumns,
	customFields,
	blueprintRecords,
}: {
	resolvedRows: ResolvedRowData[]
	task: V3ClientTypes.Project.Task
	columnsWithFilterType: { _id: string; type: GridColType }[]
	descendants: V3ClientTypes.Project.ProjectDescendant[]
	previousSelectedOptions: { _id: string; label: string }[]
	newSelectedOptions: { _id: string; label: string }[]
	mappedRecords: Map<string, TableTypes.Record>
	mappedColumns: Map<string, TableTypes.Field>
	customFields?: Map<string, string>
	blueprintRecords: Map<string, TableTypes.Record>
}): {
	newRows: TableTypes.Record[]
	rowUpdates: { newRecord: TableTypes.Record; oldRecord: TableTypes.Record }[]
	rowsToReset: string[]
} => {
	const _newRows: TableTypes.Record[] = []
	const rowUpdates: Record<string, { newRecord: TableTypes.Record; oldRecord: TableTypes.Record }> =
		{}
	const rowsToReset: string[] = []

	descendants.forEach((_desc) => {
		if (!task) {
			return
		}
		const databaseChanges = _desc.database_changes
		if (!databaseChanges) {
			return
		}

		const nextChains = getFilteredChains(_desc, task.chain)

		const filters = getFilters({
			chains: nextChains,
			mappedColumns,
		})

		if (_isString(task.step?.complete_options?.options) && task.isMultiUse && task.isMenu) {
			const onlyNewOrChangedOptions = newSelectedOptions.filter(
				(_option) =>
					!task.lifecycle.completed_options.some(
						(_completedOption) => _completedOption._id === _option._id,
					),
			)
			filters.push({
				field: task.step.complete_options.options,
				operator: 'isAnyOf',
				value: onlyNewOrChangedOptions.map(({ label }) => label),
			})
		}

		const filteredRows = resolvedRows.filter((_row) => {
			if (filters.length) {
				return applyFilterModelToRow({
					columns: columnsWithFilterType,
					row: _row,
					filterModel: { items: filters },
				})
			}
			return true
		})

		const records = filteredRows.reduce((_acc: TableTypes.Record[], _row) => {
			const record = mappedRecords.get(_row._id)
			if (record) {
				_acc.push(record)
			}
			return _acc
		}, [])

		records.forEach((record) => {
			const isSelected = newSelectedOptions.some((_newOption) => {
				if (!_isString(task.step?.complete_options?.options)) {
					return true
				}
				return (
					_newOption.label === record.data[task.step?.complete_options.options] ||
					_newOption._id === record.data[task.step?.complete_options.options]
				)
			})

			const wasPreviouslySelected = previousSelectedOptions.some((_oldOption) => {
				if (!_isString(task.step?.complete_options?.options)) {
					return true
				}
				return (
					_oldOption.label === record.data[task.step?.complete_options.options] ||
					_oldOption._id === record.data[task.step?.complete_options.options]
				)
			})

			if (!isSelected && wasPreviouslySelected) {
				rowsToReset.push(record._id)
				return
			}

			if (!isSelected || (wasPreviouslySelected && isSelected)) {
				return
			}

			const customDatabaseChanges = databaseChanges.filter(
				({ operator }) => operator === 'custom_input',
			)

			if (!task.isMenu && record && customDatabaseChanges.length) {
				const rowHasExistingValue = customDatabaseChanges.every(({ field, is_required }) => {
					if (!is_required) {
						return true
					}
					const val = record.data?.[field] ?? record.overrides?.[field]
					return val !== null && val !== undefined && val !== ''
				})

				const blueprintRecord = blueprintRecords.get(record._id)
				if (rowHasExistingValue && blueprintRecord) {
					const newRow = { ...blueprintRecord, _id: v4() }
					task?.chain?.database_chain_logic.filters.items.forEach((_filter) => {
						if (_filter.operator === 'custom_input') {
							return
						}
						newRow.data[_filter.field] = record.data[_filter.field]
					})
					databaseChanges.forEach((_mod) => {
						if (mappedColumns.get(_mod.field)?.type === TableTypes.FieldType.CALCULATION) {
							newRow.overrides = {
								...newRow.overrides,
								[_mod.field]: customFields?.get(_mod.field) ?? _mod.value,
							}
							return
						}
						newRow.data[_mod.field] = _mod.value
					})
					_newRows.push(newRow)
					return
				}
			}

			const requiredDatabaseChanges = customDatabaseChanges.filter(({ is_required }) => is_required)
			const rowAlreadyEditted = requiredDatabaseChanges.every(({ field, operator, value }) => {
				if (operator === 'custom_input') {
					const val = record.data?.[field] ?? record.overrides?.[field]
					return val !== null && val !== undefined && val !== ''
				}
				return record.data[field] === value || record.overrides?.[field] === value
			})

			if (requiredDatabaseChanges.length && rowAlreadyEditted) {
				return
			}

			const oldRecord = _cloneDeep(record)
			const updatedRow = _cloneDeep(rowUpdates[record._id]?.newRecord ?? record)

			databaseChanges.forEach((_change) => {
				const customValue = customFields?.get(_change.field)
				const changedColumn = mappedColumns.get(_change.field)
				if (changedColumn?.type === TableTypes.FieldType.CALCULATION) {
					updatedRow.overrides = {
						...updatedRow.overrides,
						[_change.field]: _change.value ?? customValue,
					}
					return
				}

				const existingChange = updatedRow.data[_change.field]
				if (
					existingChange === undefined ||
					(existingChange !== customValue && existingChange !== _change.value)
				) {
					updatedRow.data[_change.field] = customValue ?? _change.value
				}
			})

			rowUpdates[record._id] = { newRecord: updatedRow, oldRecord }
			return
		})
	})

	return {
		newRows: _newRows,
		rowUpdates: Object.values(rowUpdates),
		rowsToReset,
	}
}

export const CompleteTaskButton: ComponentType<CompleteTaskButtonProps> = ({
	onPreComplete,
	sectionId,
	onPostComplete,
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const {
		mappedColumns,
		tableConfig,
		resolvedRows,
		mappedRecords,
		updateRecords,
		columnFilterList,
		blueprintRecords,
		discardAllChanges,
	} = useContext(TableContext)
	const { task, selectedOptions, setSelectedOptions, descendants } = useContext(TaskContext)
	const [isCompleting, setIsCompleting] = useState(false)
	const [warningState, setWarningState] = useState<{
		hasBeenSeen: boolean
		openWarning: boolean
		recommendedTimeNumber?: number
		recommendedTimeFormat?: ManipulateType
		when?: V3BlueprintTypes.WhenDueTime
	}>({
		hasBeenSeen: false,
		openWarning: false,
	})
	const projectTasks = useSelector(projectSelectors.getProjectTasks)
	const chainTasks = useSelector((state: RootState) =>
		projectSelectors.getProjectTasksInChain(state, task),
	)
	const { control, watch, setValue } = useForm<CustomInputForm>({
		defaultValues: {},
	})
	const customInputs = watch('inputs')

	const [isResolveFlaggedTaskModalOpen, setIsResolveFlaggedTaskModalOpen] = useState(false)
	const [instanceName, setInstanceName] = useState('')
	const listOptions = useListOptions({
		optionsWithFilter: task?.step?.complete_options ?? { options: [], filter: { items: [] } },
		filterModel: task?.chain?.database_chain_logic.filters,
	})
	const [RoleAssignmentDialog, onConfirmRoles] = useConfirmRoleChains()
	const [rowIdsUpdating, setRowIdsUpdating] = useState<string[]>([])

	const isSubmitDisabled = useMemo(() => {
		if (!task) {
			return true
		}

		if (task.isMultiUse && !listOptions.length && !instanceName) {
			return true
		}

		if (listOptions.length && !selectedOptions.length) {
			return true
		}

		return isCompleting || task.lifecycle.complete
	}, [task, listOptions, isCompleting, instanceName, selectedOptions])

	const mappedOptions = useMemo(() => {
		return new Map(
			listOptions.map((_option) => [
				_option._id,
				{
					label: _option.label,
					dueTime: _option.dueTime,
					originalValue: _option.originalValue,
				},
			]),
		)
	}, [listOptions])

	const onOpenModal = () => {
		setIsResolveFlaggedTaskModalOpen(true)
	}

	const onCloseModal = () => {
		setIsResolveFlaggedTaskModalOpen(false)
	}

	const handleArchiveProject = async () => {
		if (!task) return
		const archiveDate = dayjs().unix()
		await dispatch(
			projectActions.updateProject({
				projectId: task.project_id,
				update: { archived: { state: true, at: archiveDate } },
			}),
		)
	}

	const debouncedOnUpdateFields = useDebouncedCallback(
		useCallback(
			(data: UpdateRecordsRequestParams) => {
				updateRecords(data)
			},
			[updateRecords],
		),
		300,
	)

	const handleCustomFieldChange = (columnId: string, value: string) => {
		const updatedFields = rowIdsUpdating.reduce(
			(
				acc: {
					newRecord: TableTypes.Record
					oldRecord: TableTypes.Record
				}[],
				_rowId,
			) => {
				const originalRecord = mappedRecords.get(_rowId)
				if (!originalRecord) {
					return acc
				}
				const updatedRecord = { ...originalRecord }
				const column = mappedColumns.get(columnId)
				if (!column) {
					return acc
				}
				if (column.type === TableTypes.FieldType.CALCULATION) {
					updatedRecord.overrides = {
						...updatedRecord.overrides,
						[columnId]: value,
					}
				} else {
					updatedRecord.data[columnId] = value
				}
				return [
					...acc,
					{
						newRecord: updatedRecord,
						oldRecord: originalRecord,
					},
				]
			},
			[],
		)
		setValue(`inputs.${columnId}`, value)
		debouncedOnUpdateFields({
			rows: updatedFields,
		})
	}

	const handleSelectOption = async (e: SelectChangeEvent<unknown>) => {
		if (!task) {
			return
		}
		let preValues: string[]
		if (task?.isMenu) {
			preValues = e.target.value as string[]
		} else {
			preValues = [e.target.value as string]
		}

		const missingOptions = new Map<
			string,
			V3ProjectTypes.CompletedOption & {
				dueTime?: {
					time: string
					when: V3BlueprintTypes.WhenDueTime
				}
			}
		>()

		task?.lifecycle.completed_options.forEach((_completedOption) => {
			if (
				task.isMultiUse &&
				task.isMenu &&
				!preValues.some((_id) => _id === _completedOption._id)
			) {
				preValues.push(_completedOption._id)
				missingOptions.set(_completedOption._id, _completedOption)
			}
		})
		const newSelectedOptions = preValues.reduce(
			(
				_acc: Array<
					V3ProjectTypes.CompletedOption & {
						dueTime?: {
							time: string
							when: V3BlueprintTypes.WhenDueTime
						}
					}
				>,
				val,
			) => {
				if (missingOptions.has(val)) {
					_acc.push(missingOptions.get(val)!)
					return _acc
				}
				const option = mappedOptions.get(val)
				const column = _isString(task.step?.complete_options?.options)
					? mappedColumns.get(task.step.complete_options.options)
					: undefined
				if (option) {
					_acc.push({
						_id: val,
						label: option?.originalValue ? String(option.originalValue) : option.label,
						dueTime: option.dueTime,
						column: column ? { type: column.type, _id: column._id } : undefined,
					})
				}
				return _acc
			},
			[],
		)

		const filteredDescendants = filterDescendantsBySelection(task, chainTasks, newSelectedOptions)

		if (task?.step?.descendants.some(({ database_changes }) => !!database_changes)) {
			discardAllChanges()
			const { rowUpdates, newRows, rowsToReset } = updateDatabaseRows({
				resolvedRows,
				task,
				columnsWithFilterType: columnFilterList,
				descendants: filteredDescendants,
				newSelectedOptions,
				previousSelectedOptions: selectedOptions,
				mappedRecords,
				mappedColumns,
				blueprintRecords,
			})

			if (rowUpdates.length || newRows.length || rowsToReset.length) {
				await updateRecords({ rows: rowUpdates, newRows, resetRecords: rowsToReset })
			}
			setRowIdsUpdating(
				_uniq([
					...rowUpdates.map(({ newRecord }) => newRecord._id),
					...newRows.map(({ _id }) => _id),
				]),
			)
		}

		setSelectedOptions([...newSelectedOptions])
	}

	const handleCompleteTaskClick = async ({
		hasResolveFlagged,
	}: {
		hasResolveFlagged?: boolean
	}) => {
		if (!task || (onPreComplete && onPreComplete() === 'cancel')) {
			return
		}

		if (
			descendants.some((_desc) =>
				_desc.database_changes?.some(({ is_required, field, operator }) => {
					return is_required && operator === 'custom_input' && !customInputs[field]
				}),
			)
		) {
			showSnackbar('Please fill in all required fields', { variant: 'warning' })
			return
		}

		setIsCompleting(true)

		const databaseLogic =
			descendants.reduce(
				(
					filters: Record<
						string,
						V3BlueprintTypes.Descendant['database_chain_logic'] & {
							stepName: string
							descendantId: string
						}
					>,
					_child,
				) => {
					if (!_child.step) {
						return filters
					}

					if (_child.database_chain_logic?.column) {
						if (
							_child.option_condition?.add_selection_to_database_chain_filters &&
							selectedOptions.length
						) {
							const fromTask =
								projectTasks.find((_task) => _task._id === _child.option_condition?.from) ?? task
							let completed_options: V3ProjectTypes.CompletedOption[]
							if (fromTask._id === task._id) {
								completed_options = selectedOptions
							} else {
								completed_options = fromTask.lifecycle.completed_options.filter((_option) =>
									_child.option_condition?.values.includes(_option._id),
								)
							}
							if (_isString(task.step?.complete_options?.options)) {
								_child.database_chain_logic = {
									..._child.database_chain_logic,
									filters: {
										..._child.database_chain_logic.filters,
										items: [
											..._child.database_chain_logic.filters.items,
											{
												field: task.step?.complete_options?.options,
												operator: 'isAnyOf',
												value: completed_options.map(({ label }) => label),
											},
										],
									},
								}
							}
						}
						filters[_child.step._id] = {
							..._child.database_chain_logic,
							stepName: _child.step?.name ?? '',
							descendantId: _child._id,
						}
					}

					if (_child.step.isSection) {
						_child.step.descendants.forEach((_sectionChild) => {
							if (!_sectionChild.step) {
								return
							}
							if (_sectionChild.database_chain_logic?.column) {
								filters[_sectionChild.step._id] = {
									..._sectionChild.database_chain_logic,
									stepName: _sectionChild.step?.name ?? '',
									descendantId: _sectionChild._id,
								}
							}
						})
					}

					return filters
				},
				{},
			) ?? {}

		if (!_isEmpty(databaseLogic) && !tableConfig) {
			showSnackbar('No table found', { variant: 'error' })
			setIsCompleting(false)
			return
		}

		const databaseLogicSteps = Object.keys(databaseLogic)

		const filteredRows: V3ProjectSdk.TaskDbChains = {}

		for (const _stepId of databaseLogicSteps) {
			const filter = databaseLogic[_stepId]

			if (!filter) {
				continue
			}

			const column = mappedColumns.get(filter.column)
			const columnType = column?.type

			if (!columnType) {
				continue
			}

			if (columnType === TableTypes.FieldType.ROLE) {
				const confirmResponse = await onConfirmRoles({ filter })
				if (confirmResponse.status === 'rejected') {
					return
				}
				const contactsForRoles = confirmResponse.data
				filteredRows[_stepId] = Object.keys(contactsForRoles).map((_contactId) => ({
					_id: v4(),
					prefix: _contactId,
					labels: contactsForRoles[_contactId],
					columnType: TableTypes.FieldType.ROLE,
				}))
				continue
			}

			const mappedColumnLabels = new Map<string, string>(
				resolvedRows.reduce((acc: [string, string][], _row) => {
					const label = _row[filter.column]
					if (!label) {
						return acc
					}
					return [...acc, [_row._id, String(label)]]
				}, []),
			)

			const filteredRowsSet = resolvedRows.reduce((_filteredRows: Set<string>, _row) => {
				if (!tableConfig) {
					return _filteredRows
				}

				const doesRowCompleteNextLogic = applyFilterModelToRow({
					columns: columnFilterList,
					row: _row,
					filterModel: filter.filters,
				})

				if (!doesRowCompleteNextLogic) {
					return _filteredRows
				}

				if (task.chain?.database_chain_logic.filters.items.length) {
					const isRowInChain = applyFilterModelToRow({
						columns: columnFilterList,
						row: _row,
						filterModel: task.chain.database_chain_logic.filters,
					})
					if (!isRowInChain) {
						return _filteredRows
					}
				}

				const doesRowPass = applyFilterModelToRow({
					columns: columnFilterList,
					row: _row,
					filterModel: filter.filters,
				})
				if (doesRowPass) {
					_filteredRows.add(_row._id)
				}
				return _filteredRows
			}, new Set<string>())

			const selectedLabels = [...filteredRowsSet].reduce((_optionList: Set<string>, _rowId) => {
				const label = mappedColumnLabels.get(_rowId)
				const chainExists = projectTasks.some(
					({ chain: _chain }) =>
						_chain?.label &&
						_chain.label.selected_option === label &&
						_chain.label.prefix === column?._id &&
						_chain.original_descendant_id === filter.descendantId,
				)

				if (label && !chainExists) {
					_optionList.add(label)
				}
				return _optionList
			}, new Set<string>())

			filteredRows[_stepId] = [...selectedLabels].map((_label) => ({
				_id: v4(),
				labels: [_label],
				columnType,
			}))
		}

		const multiUseTasksCompleted =
			descendants.reduce((_acc: V3ClientTypes.Project.Task[], _desc) => {
				if (_desc.chain_endings) {
					_desc.chain_endings.forEach((_end) => {
						const taskWithDesc = chainTasks.find((_task) =>
							_task.step?.descendants.some((_desc) => _desc._id === _end),
						)
						if (!taskWithDesc) {
							return
						}
						const nextStepIsChainOrigin = taskWithDesc.step === _desc.step
						if (taskWithDesc?.isMultiUse && !nextStepIsChainOrigin) {
							_acc.push(taskWithDesc)
						}
					})
				}
				return uniqBy(_acc, '_id')
			}, []) ?? []

		const { rowUpdates } = updateDatabaseRows({
			resolvedRows,
			task,
			columnsWithFilterType: columnFilterList,
			descendants,
			newSelectedOptions: selectedOptions,
			previousSelectedOptions: [],
			mappedRecords,
			mappedColumns,
			customFields: new Map(Object.entries(customInputs ?? {})),
			blueprintRecords,
		})

		await updateRecords({ rows: rowUpdates, save: true })

		const response = await dispatch(
			projectActions.completeTask({
				taskId: task._id,
				options: selectedOptions.filter(
					({ _id }) => !task?.lifecycle.completed_options.find((_option) => _option._id === _id),
				),
				sectionId,
				hasResolveFlagged,
				taskDbChains: !_isEmpty(filteredRows) ? filteredRows : undefined,
				projectId: task.project_id,
				instanceName: task.isMultiUse ? instanceName : undefined,
				filterChildren: descendants.map(({ _id }) => _id) ?? [],
			}),
		)

		for (const _task of multiUseTasksCompleted) {
			await dispatch(
				projectActions.updateTask({
					taskId: _task._id,
					projectId: task.project_id,
					'lifecycle.complete': true,
				}),
			)
		}
		setSelectedOptions([])
		setIsCompleting(false)
		if (onPostComplete) {
			onPostComplete({
				status: response.meta.requestStatus,
				payload: response.payload,
				hasResolveFlagged,
			})
			return
		}
	}

	return (
		<>
			{RoleAssignmentDialog}
			<RemoveFlaggedTaskModal
				isOpen={isResolveFlaggedTaskModalOpen}
				blockedTaskName={task?.unblock_task?.name}
				onClose={onCloseModal}
				onComplete={() => {
					handleCompleteTaskClick({})
					onCloseModal()
				}}
				onCompleteAndResolve={() => {
					handleCompleteTaskClick({ hasResolveFlagged: true })
					onCloseModal()
				}}
			/>
			<Box display="flex" alignItems="center" flexDirection={{ mobile: 'column', laptop: 'row' }}>
				<Box>
					{!!listOptions.length && (
						<Select
							label={`Choose ${task?.isMenu ? 'options' : 'an option'}`}
							multiline={task?.isMenu}
							multiple={task?.isMenu}
							options={listOptions}
							value={selectedOptions.map(({ _id }) => _id) as string[]}
							onChange={handleSelectOption}
							sx={{ width: 300 }}
							containerProps={{
								width: { mobile: '100%', laptop: undefined },
								minWidth: 300,
								mr: { laptop: 1 },
								mb: { mobile: 1, laptop: 0 },
							}}
						/>
					)}
					{task?.isMultiUse && !listOptions.length && (
						<TextField
							label="Name"
							value={instanceName}
							onChange={(e) => setInstanceName(e.target.value)}
							fullWidth
							containerProps={{ width: 300 }}
							sx={{ mb: 1, mr: 1 }}
						/>
					)}
					{descendants.map((_desc) => {
						if (!_desc.database_changes) {
							return null
						}
						return (
							<Box key={_desc._id}>
								{_desc.database_changes.map((_change) => {
									if (_change.operator !== 'custom_input') {
										return null
									}
									return (
										<Controller
											key={_change.field}
											control={control}
											rules={{ required: _change.is_required }}
											name={`inputs.${_change.field}`}
											render={({ field: { value } }) => (
												<TextField
													label={mappedColumns.get(_change.field)?.name}
													value={value}
													onChange={(e) => {
														handleCustomFieldChange(_change.field, e.target.value)
													}}
													containerProps={{ width: 300 }}
													fullWidth
													disabled={!!listOptions.length && !selectedOptions.length}
												/>
											)}
										/>
									)
								})}
							</Box>
						)
					})}
				</Box>
				{task?.actions.some((_action) => _action.type === V3BlueprintTypes.ActionEnum.Archive) && (
					<ArchiveButtons
						onArchiveProject={handleArchiveProject}
						onCompleteTask={() => handleCompleteTaskClick({})}
					/>
				)}
				<Stack>
					<Button
						disabled={isSubmitDisabled}
						onClick={() => {
							const optionWithRecommendedTime = selectedOptions.find((option) => option.dueTime)
							if (optionWithRecommendedTime?.dueTime) {
								const [recommendedTimeNumber, recommendedTimeFormat] = [
									Number(optionWithRecommendedTime.dueTime.time[0]),
									optionWithRecommendedTime.dueTime.time[1] as ManipulateType,
								]
								const HasExtraTime =
									!!task &&
									dayjs
										.unix(task.created_at)
										.add(recommendedTimeNumber, recommendedTimeFormat)
										.isAfter(dayjs())
								if (HasExtraTime && !warningState.hasBeenSeen) {
									setWarningState({
										openWarning: true,
										hasBeenSeen: false,
										recommendedTimeNumber,
										recommendedTimeFormat,
										when: optionWithRecommendedTime.dueTime.when,
									})
									return
								}
							}
							if (task?.unblock_task) {
								onOpenModal()
								return
							}
							handleCompleteTaskClick({})
						}}
						sx={{
							mt: { laptop: 2 },
						}}
						isLoading={isCompleting}
					>
						Mark as completed
					</Button>
				</Stack>
			</Box>
			<Modal
				open={warningState.openWarning}
				onClose={() =>
					setWarningState({
						hasBeenSeen: true,
						openWarning: false,
					})
				}
			>
				<Stack direction="column" spacing={2}>
					<Text variant="h5">Are you sure you want to complete this task?</Text>
					{!!task && (
						<Text>{`${getWarningText(warningState.when)} ${getRemainingTime(task.created_at, warningState.recommendedTimeNumber, warningState.recommendedTimeFormat)}`}</Text>
					)}
					<Button
						onClick={() =>
							setWarningState({
								hasBeenSeen: true,
								openWarning: false,
							})
						}
					>
						Close
					</Button>
				</Stack>
			</Modal>
		</>
	)
}
