import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import { FileUpload } from 'src/modules/my-tasks-v3/components/attachments/file-upload'
import { getActionLabel } from 'src/helpers/labels'
import { CreateContact } from 'src/modules/my-tasks-v3/components/attachments/create-contact'
import { FileTemplate } from 'src/modules/my-tasks-v3/components/attachments/file-template'
import { TaskContext } from 'src/providers'
import { VideoEmbed } from 'src/modules/my-tasks-v3/components/actions/video-embed'
import { Software } from 'src/modules/my-tasks-v3/components/actions/software'
import { TaskReference } from 'src/modules/my-tasks-v3/components/actions/task-reference'
import { TemplateModal } from 'src/modules/my-tasks-v3/components/actions/template-modal'
import { AsyncDispatchType } from 'src/store/types'
import { Box, ContactAndAssignment, Grid, Text } from 'src/components'
import { QuestionFlowLogicContainer } from 'src/components/question-flow/question-flow-logic-container'
import { SetResources } from 'src/modules/my-tasks-v3/components/actions/set-resources'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'

type Props = {
	attachments: V3ClientTypes.Project.Task['attachments']
}

const groupAttachmentsByRows = (attachments: V3ClientTypes.Project.Task['attachments']) => {
	const orderedRows: Array<V3ClientTypes.Project.TaskAction & { width: number }> = []

	let currentWidth = 0

	attachments.forEach((attachment) => {
		if (attachment.type === V3BlueprintTypes.ActionEnum.None) {
			return
		}
		const width = attachment.type === V3BlueprintTypes.ActionEnum.SetResources ? 1 : 0.5

		if (currentWidth + width <= 1) {
			orderedRows.push({ ...attachment, width })
			currentWidth += width
		} else {
			currentWidth = width // Start new row width with the current attachment
			orderedRows.push({ ...attachment, width })
		}
	})

	return orderedRows
}

export const TaskAttachments = ({ attachments: unorderedAttachments }: Props) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const project = useSelector(projectSelectors.getSelectedProject)
	const { task, updateTask } = useContext(TaskContext)
	const taskRoles = useSelector(projectSelectors.getTaskRoles)
	const taskExternals = useSelector(projectSelectors.getTaskExternals)

	const attachments = useMemo(() => {
		return groupAttachmentsByRows(unorderedAttachments)
	}, [unorderedAttachments])

	const handleRoleAssignChange = async (roleId: string, userId: string) => {
		if (!task) return
		await dispatch(
			projectActions.assignUserToRole({
				userId,
				roleId,
				projectId: task.project_id,
			}),
		)
	}

	const handleExternalAssignChange = async (roleId: string, contactId: string) => {
		if (!task) return
		await dispatch(
			projectActions.assignContactToExternal({
				projectId: task.project_id,
				assignments: [{ roleId, contactId }],
			}),
		)
	}

	const createContact = (contactId: string) => {
		if (!task) return
		updateTask('lifecycle.resource', contactId, { updateDb: true })
	}

	const renderAttachment = (
		type: V3BlueprintTypes.ActionEnum,
		attachment: V3ClientTypes.Project.TaskAction,
	) => {
		if (!task || !project) return null
		switch (type) {
			case V3BlueprintTypes.ActionEnum.FileUpload:
				return <FileUpload project={project} task={task} action={attachment} />
			case V3BlueprintTypes.ActionEnum.FileTemplate:
				return <FileTemplate task={task} project={project} action={attachment} />
			case V3BlueprintTypes.ActionEnum.Call:
			case V3BlueprintTypes.ActionEnum.Email:
			case V3BlueprintTypes.ActionEnum.Invite:
			case V3BlueprintTypes.ActionEnum.Meeting:
				return (
					<Box>
						<ContactAndAssignment
							taskExternals={taskExternals}
							taskRoles={taskRoles}
							onAssignExternal={handleExternalAssignChange}
							onAssignRole={handleRoleAssignChange}
						/>
					</Box>
				)
			case V3BlueprintTypes.ActionEnum.Software:
				return <Software links={attachment.links ?? []} />
			case V3BlueprintTypes.ActionEnum.Video: {
				return <VideoEmbed urls={attachment.links ?? []} />
			}
			case V3BlueprintTypes.ActionEnum.TaskReference:
				return <TaskReference taskReferences={attachment.task_references} project={project} />

			case V3BlueprintTypes.ActionEnum.Note:
				return (
					<Box sx={{ overflowWrap: 'break-word' }}>
						<Text fontSize={14}>{attachment.note}</Text>
					</Box>
				)
			case V3BlueprintTypes.ActionEnum.Contact:
				return <CreateContact task={task} useDrawer onCreateContact={createContact} />
			case V3BlueprintTypes.ActionEnum.SetResources:
				return <SetResources action={attachment} forceHide={true} />
			case V3BlueprintTypes.ActionEnum.Template:
				return <TemplateModal action={attachment} />
			case V3BlueprintTypes.ActionEnum.Questionaire:
				return (
					<QuestionFlowLogicContainer
						filters={{
							...task.chain?.database_chain_logic?.filters,
							...attachment.questionaire?.filters,
							items: [
								...(task.chain?.database_chain_logic?.filters?.items ?? []),
								...(attachment.questionaire?.filters.items ?? []),
							],
						}}
						acceptedQuestionColumns={attachment.questionaire?.columns ?? []}
						questionScope={attachment.questionaire?.scope ?? []}
					/>
				)
		}
	}

	if (!task || !attachments.length) {
		return null
	}

	return (
		<Grid container mt={3} gap={1} wrap="wrap">
			{attachments.map((attachment) => {
				if (attachment.type === V3BlueprintTypes.ActionEnum.None) {
					return null
				}
				return (
					<Grid
						item
						key={attachment._id}
						flex={`${attachment.width * 100}%`}
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Stack
							spacing={2}
							direction="column"
							p={2}
							sx={{
								boxShadow: 'rgb(0 0 0 / 12%) 2px 4px 15px;',
								borderRadius: '20px 0px 20px 20px',
								minHeight: '150px',
								height: '100%',
								display: 'flex',
							}}
						>
							<Text
								variant="overline"
								fontWeight="bold"
							>{`Attachment-${getActionLabel(attachment.type)}`}</Text>
							{renderAttachment(attachment.type, attachment)}
						</Stack>
					</Grid>
				)
			})}
		</Grid>
	)
}
