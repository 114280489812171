import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { CommsSdk, V3ProjectSdk } from '@cango-app/sdk'

import { errorHandler } from 'src/helpers/api'

import { AsyncDispatchType, RootState } from '../../types'
import { selectors as authSelectors } from '../auth'

import { NoteWithMessageStatus, MessageStatus } from './types'

export const getInbox = createAsyncThunk<
	CommsSdk.GetInboxResponse,
	CommsSdk.InboxNavState | string,
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>('notes/get-inbox', async (inboxNavState, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		return await CommsSdk.getInbox({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			state: inboxNavState,
		})
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const readNotes = createAsyncThunk<
	CommsSdk.ReadNotesResponse,
	CommsSdk.ReadNotesRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('notes/readNotes', async (notesToRead: string[], { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await CommsSdk.readNotes(
			import.meta.env.VITE_API as string,
			headers,
			notesToRead,
		)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateTaskNotes = createAsyncThunk<
	NoteWithMessageStatus[],
	Required<CommsSdk.FetchMessagesForTaskRequest>,
	{ rejectValue: string; state: RootState; dispatch: AsyncDispatchType }
>('notes/update-notes-for-task', async (params, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await CommsSdk.fetchMessagesForTask({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			params,
		})

		const messagesWithStatus = response.map((message) => ({
			...message,
			messageStatus: MessageStatus.Sent,
		}))

		dispatch(readNotes(messagesWithStatus.map((message) => message._id)))

		return messagesWithStatus
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const fetchAllTaskNotes = createAsyncThunk<
	NoteWithMessageStatus[],
	CommsSdk.FetchMessagesForTaskRequest,
	{ rejectValue: string; state: RootState; dispatch: AsyncDispatchType }
>('notes/fetch-notes-for-task', async ({ taskId }, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await CommsSdk.fetchMessagesForTask({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			params: {
				taskId,
			},
		})

		const messagesWithStatus = response.map((message) => ({
			...message,
			messageStatus: MessageStatus.Sent,
		}))

		dispatch(readNotes(messagesWithStatus.map((message) => message._id)))

		return messagesWithStatus
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const sendNote = createAsyncThunk<
	V3ProjectSdk.AddNoteToTaskResponse,
	V3ProjectSdk.AddNoteToTaskRequest & { userId: string; organisationId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('notes/add-note', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await V3ProjectSdk.addNoteToTask(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})
