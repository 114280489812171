import React, { ComponentType, ReactNode } from 'react'
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import PulseLoader from 'react-spinners/PulseLoader'

type ICheckBoxProps = CheckboxProps & {
	isLoading?: boolean
	label?: string | ReactNode
	containerStyle?: FormControlLabelProps['sx']
	fontSize?: number
}

export const Checkbox: ComponentType<ICheckBoxProps> = ({
	isLoading,
	label,
	containerStyle,
	fontSize = 14,
	...props
}) => {
	if (isLoading) {
		return <PulseLoader size={6} />
	}

	if (label) {
		return (
			<FormControlLabel
				control={<MuiCheckbox {...props} />}
				label={label}
				slotProps={{ typography: { fontSize } }}
				sx={{ ...containerStyle }}
				onClick={(event) => event.stopPropagation()}
			/>
		)
	}

	return <MuiCheckbox {...props} />
}
