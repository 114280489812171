import {
	TableTypes,
	TemplateTypes,
	V3ClientTypes,
	V3ProjectTypes,
	ClientTypes,
} from '@cango-app/types'
import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
	generateTreeData,
	getFormatedTable,
	TreeData,
} from 'src/components/display-view/presentation-view-utils'
import { TableContext, TaskContext } from 'src/providers'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

type UseResolveTemplate = {
	template: V3ClientTypes.Project.TaskAction['template']
}

const getFormattedView = (treeData: TreeData[]) => {
	return treeData
		.map((nodeChild) => {
			const { title, tables } = getFormatedTable(nodeChild)
			return tables
				.map((table, index) => {
					return `<h3>${title[index]}</h3>${table}<br/><br/>`
				})
				.join('')
		})
		.join('')
}

const getFormattedChainOptions = ({
	mappedColumns,
	mappedContacts,
	chain,
}: {
	mappedColumns: Map<string, TableTypes.Field>
	mappedContacts: Map<string, ClientTypes.Contact>
	chain: V3ProjectTypes.ProjectChain | undefined
}) => {
	if (!chain) return ''
	const { prefix, selected_option, columnType } = chain.label
	if (!columnType || !selected_option || !prefix) return ''
	if (
		[
			TableTypes.FieldType.CONTACT,
			TableTypes.FieldType.SINGLE_SELECT,
			TableTypes.FieldType.TABLE_SELECT,
			TableTypes.FieldType.REFERENCE,
		].includes(columnType)
	) {
		const column = mappedColumns.get(prefix)
		return column?.valueOptions.find(({ _id }) => selected_option === _id)?.label ?? ''
	}

	if (columnType === TableTypes.FieldType.ROLE) {
		const contact = mappedContacts.get(prefix)
		if (!contact) return ''
		return `${contact.name} ${contact.surname}`
	}
	return selected_option
}

export const useResolveTemplate = ({ template }: UseResolveTemplate) => {
	const contacts = useSelector(contactSelectors.getContactsForSelect)
	const { task } = useContext(TaskContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const { tableConfig, mappedColumns, resolvedRows } = useContext(TableContext)
	const projectTasks = useSelector(projectSelectors.getProjectTasks)
	const project = useSelector(projectSelectors.getSelectedProject)

	const views = useMemo(() => {
		if (!tableConfig) return []
		return tableConfig.views
	}, [tableConfig])

	const replaceVariables = useCallback(
		(taskTemplate: V3ClientTypes.Project.TaskTemplate): string => {
			const taskActionTemplateVariables = taskTemplate.assigned_values
			const template = taskTemplate.template
			if (!views) return ''
			return template.content.replace(/\{\{([^}]+)\}\}/g, (_, variableName: string): string => {
				const templateVariable = template.variables.find(({ name }) => name === variableName)
				const foundVariable = taskActionTemplateVariables[templateVariable?._id ?? '']
				const blueprintVariable = template.variables.find(
					(variable) => variable._id === templateVariable?._id,
				)

				if (blueprintVariable?.type === TemplateTypes.VariableType.project_name) {
					return project?.name ?? ''
				}

				if (!foundVariable || !blueprintVariable) return `{{${variableName}}}`

				switch (blueprintVariable.type) {
					case TemplateTypes.VariableType.text:
						return foundVariable.value ?? `{{${variableName}}}`
					case TemplateTypes.VariableType.contacts: {
						const foundContact = contacts.find(({ _id }) => _id === foundVariable.value)
						return `${foundContact?.name} ${foundContact?.surname}`
					}
					case TemplateTypes.VariableType.view: {
						const foundView = views.find(({ _id }) => foundVariable.value === _id)
						if (!foundView) return `${variableName}`
						const columns = [...mappedColumns.values()]
						const treeData = Object.keys(foundView.groupedFields).length
							? generateTreeData(resolvedRows, columns, foundView?.groupedFields)
							: ([
									{
										_id: tableConfig?._id,
										children: resolvedRows,
										label: tableConfig?.name,
										records: columns,
									},
								] as TreeData[])
						return getFormattedView(treeData)
					}
					case TemplateTypes.VariableType.chain: {
						const chain = projectTasks.reduce(
							(_chain: V3ProjectTypes.ProjectChain | undefined, _task) => {
								if (_chain) {
									return _chain
								}
								const taskDescendant = _task.step?.descendants?.find(
									(_desc) => _desc._id === foundVariable.value,
								)

								if (!taskDescendant) {
									return _chain
								}

								const childTask = projectTasks.find(
									({ parent, step }) =>
										parent?._id === _task._id && step?._id === taskDescendant.step?._id,
								)

								if (!childTask) {
									return _chain
								}

								if (
									task?.chain?._id !== childTask.chain?._id ||
									!task?.chain?.parent_chains.some(
										(_parentChain) => _parentChain._id !== childTask.chain?._id,
									)
								) {
									return _chain
								}

								return childTask.chain
							},
							undefined,
						)
						return getFormattedChainOptions({
							mappedColumns,
							chain,
							mappedContacts,
						})
					}
					default:
						return `{{${variableName}}}`
				}
			})
		},
		[views, mappedColumns, task?.chain, tableConfig, resolvedRows, mappedContacts],
	)

	const content = useMemo(() => {
		if (!template) {
			return
		}
		return replaceVariables(template)
	}, [replaceVariables, template])

	return {
		content,
	}
}
