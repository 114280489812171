import { Controller, FormProvider, useFieldArray, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'
import { GridAggregationModel } from '@mui/x-data-grid-premium'
import { ComponentType, useContext, useMemo } from 'react'
import { v4 } from 'uuid'
import { Stack } from '@mui/material'

import { TableContext } from 'src/providers'
import { TrashIcon } from 'src/assets/icons'

import { Select } from '../select'
import { Box } from '../box'
import { Button } from '../button'
import { Text } from '../text'
import { Grid } from '../grid'
import { IconButton } from '../icon-button'
import { Divider } from '../divider'
import { Checkbox } from '../checkbox'

const AggregationList: ComponentType<{
	groupIndex: number
	displayMode: TableTypes.ViewStyleMode
}> = ({ groupIndex, displayMode }) => {
	const { control, watch } = useFormContext<TableTypes.TableView>()
	const columnVisibility = watch(`groupedFields.${groupIndex}.column_visibility`)
	const allGroupedFields = watch(`groupedFields`)
	const { columnList, mappedColumns } = useContext(TableContext)

	const allPreviousAggregations = useMemo(() => {
		if (displayMode === TableTypes.ViewStyleMode.presentation || groupIndex === 0) {
			return {}
		}
		return allGroupedFields.reduce((_acc: GridAggregationModel, _group, index) => {
			const isVisible = !!columnVisibility.length
			if (index < groupIndex && isVisible) {
				_acc = {
					..._acc,
					..._group.aggregationModel,
				}
			}
			return _acc
		}, {})
	}, [JSON.stringify(allGroupedFields)])

	const aggregationList = useMemo(() => {
		const displayedColumns = columnList.filter(({ _id }) => columnVisibility.includes(_id))
		return displayedColumns.map(({ _id, label }) => {
			const columnType = mappedColumns.get(_id)?.type ?? TableTypes.FieldType['STRING']
			const options = [
				TableTypes.FieldType['NUMBER'],
				TableTypes.FieldType['CALCULATION'],
			].includes(columnType)
				? TableTypes.AggregationFormulaNumbers
				: {
						...TableTypes.AggregationFormulaStrings,
					}
			return {
				_id,
				label,
				options: [
					...Object.keys(options).map((agg) => ({
						_id: agg,
						label: agg,
					})),
					{ _id: 'value', label: 'value' },
				],
			}
		})
	}, [columnList, mappedColumns, columnVisibility])

	return (
		<Grid item xs={12}>
			<Text>Aggregations:</Text>
			<Controller
				control={control}
				name={`groupedFields.${groupIndex}.aggregationModel`}
				render={({ field: { value, onChange } }) => {
					return (
						<Stack direction="row" width="100%" flexWrap="wrap">
							{aggregationList.map(({ _id, label, options }) => {
								return (
									<Select
										containerProps={{
											minWidth: '150px',
											marginRight: 2,
										}}
										key={_id}
										fullWidth
										withNoneOption
										specialOption={TableTypes.AggregationSpecialCase['show']}
										label={label}
										value={allPreviousAggregations[_id] ?? value?.[_id] ?? ''}
										disabled={allPreviousAggregations[_id] !== undefined}
										onChange={(event) => {
											const newValue = {
												...value,
												[_id]: event.target.value,
											}
											onChange(newValue)
										}}
										options={options}
									/>
								)
							})}
						</Stack>
					)
				}}
			/>
		</Grid>
	)
}

const ChildVisibilityModelSelector: ComponentType<{
	groupIndex: number
	displayMode: TableTypes.ViewStyleMode
}> = ({ groupIndex, displayMode }) => {
	const { control, watch } = useFormContext<TableTypes.TableView>()
	const { columnList } = useContext(TableContext)
	const allGroupedFields = watch('groupedFields')

	const previousSelectedColumns = useMemo(() => {
		if (displayMode === TableTypes.ViewStyleMode.presentation || groupIndex === 0) {
			return []
		}
		return allGroupedFields.reduce((_acc: string[], _group, index) => {
			if (index < groupIndex) {
				const visibleColumns = _group.column_visibility
				_acc.push(...visibleColumns.map(([_colId]) => _colId))
			}
			return _acc
		}, [])
	}, [JSON.stringify(allGroupedFields)])

	return (
		<Controller
			control={control}
			name={`groupedFields.${groupIndex}.column_visibility`}
			render={({ field: { value, onChange } }) => {
				return (
					<Box>
						<Select
							fullWidth
							multiple
							label="Visibility in group"
							name="visibility"
							options={columnList.map((option) => ({
								...option,
								disabled: previousSelectedColumns.includes(option._id),
							}))}
							value={value}
							size="small"
							onChange={(e) => onChange(e.target.value as string[])}
						/>
						<Box display="flex">
							<Button
								size="small"
								variant="text"
								onClick={() => onChange(columnList.map((_col) => _col._id))}
								sx={{ minWidth: 75, mr: 1 }}
							>
								Select all
							</Button>
							<Button
								size="small"
								variant="text"
								onClick={() => onChange([])}
								sx={{ minWidth: 75 }}
							>
								Clear all
							</Button>
						</Box>
					</Box>
				)
			}}
		/>
	)
}

const GroupedChildContainer: ComponentType<{
	groupIndex: number
	onRemoveChild: () => void
	displaymode: TableTypes.ViewStyleMode
}> = ({ groupIndex, onRemoveChild, displaymode }) => {
	const { control } = useFormContext<TableTypes.TableView>()
	const { columnList } = useContext(TableContext)

	return (
		<Box>
			<Grid container spacing={2} paddingBottom={2}>
				<Grid item xs={12}>
					<Text variant="h6">{`Grouping ${groupIndex + 1}`}</Text>
				</Grid>
				<Grid item xs={5}>
					<Controller
						control={control}
						name={`groupedFields.${groupIndex}.groupColumnId`}
						render={({ field: { value, onChange } }) => (
							<Select
								fullWidth
								label="Group by column"
								name="grouping"
								options={columnList}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<ChildVisibilityModelSelector groupIndex={groupIndex} displayMode={displaymode} />
				</Grid>
				<Grid item xs={1}>
					<Box mt={3}>
						<IconButton onClick={onRemoveChild}>
							<TrashIcon width={24} />
						</IconButton>
					</Box>
				</Grid>
				<AggregationList groupIndex={groupIndex} displayMode={displaymode} />
			</Grid>
			<Divider />
		</Box>
	)
}

export const GroupingChildren = () => {
	const formControl = useFormContext<TableTypes.TableView>()
	const { columnList } = useContext(TableContext)
	const displayMode = formControl.watch('displayMode')

	const { fields, append, remove } = useFieldArray({
		control: formControl.control,
		name: 'groupedFields',
	})

	return (
		<FormProvider {...formControl}>
			<Box>
				<Stack direction="column" spacing={1} paddingBottom={2}>
					{displayMode === 'editable' && (
						<Controller
							control={formControl.control}
							name="displayChildren"
							render={({ field: { value, onChange } }) => (
								<Checkbox label="Allow opening of children" checked={value} onChange={onChange} />
							)}
						/>
					)}
					{fields.map((groupedField, index) => {
						return (
							<GroupedChildContainer
								key={groupedField.id}
								groupIndex={index}
								onRemoveChild={() => remove(index)}
								displaymode={displayMode}
							/>
						)
					})}
				</Stack>
				<Button
					disabled={fields.length === columnList.length}
					onClick={() => {
						append({
							_id: v4(),
							groupColumnId: columnList[0]?._id ?? '',
							column_visibility: [],
							aggregationModel: {},
						})
					}}
				>
					Add nested group
				</Button>
			</Box>
		</FormProvider>
	)
}
