import React, { ComponentType, useState } from 'react'
import {
	DataGridPremium,
	GridCallbackDetails,
	GridFilterModel,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridInitialState,
	GridToolbarColumnsButton,
	GridColumnVisibilityModel,
	useGridApiRef,
	GridFilterOperator,
} from '@mui/x-data-grid-premium'
import { GridLocaleText } from '@mui/x-data-grid/models/api'

import { useColumnFormatter } from '../modules/tables/use-column-formatter'

import { Box } from './box'

type TableFiltersProps = {
	onChange: (model: GridFilterModel, details: GridCallbackDetails) => void
	initialState?: GridInitialState
	withColumns?: boolean
	onColumnsChange?: (columns: string[]) => void
	localeText?: Partial<GridLocaleText>
	disableLogicOperators?: boolean
	custom_filters?: GridFilterOperator[]
}

const CustomToolbar: ComponentType<{
	setFilterButtonEl?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
	hideColumnSelector?: boolean
}> = ({ setFilterButtonEl, hideColumnSelector }) => {
	return (
		<GridToolbarContainer>
			<GridToolbarFilterButton ref={setFilterButtonEl} />
			{!hideColumnSelector && <GridToolbarColumnsButton />}
		</GridToolbarContainer>
	)
}

const slots = {
	toolbar: CustomToolbar,
}

export const TableFilters: ComponentType<TableFiltersProps> = ({
	onChange,
	initialState,
	withColumns,
	onColumnsChange,
	localeText,
	disableLogicOperators,
	custom_filters,
}) => {
	const apiRef = useGridApiRef()
	const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null)

	const { columns } = useColumnFormatter({
		isTableLocked: true,
		apiRef,
		custom_filters,
	})

	const handleColumnVisibilityModelChange = (columns: GridColumnVisibilityModel) => {
		if (!onColumnsChange) {
			return
		}
		const visibleColumns = Object.keys(columns).filter((column) => columns[column])
		onColumnsChange(visibleColumns)
	}

	return (
		<Box width={190}>
			<DataGridPremium
				initialState={initialState}
				rows={[]}
				columns={columns}
				onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				hideFooter
				sx={{
					'& .MuiDataGrid-main': {
						display: 'none',
					},
					'& .MuiDataGrid-root': {
						border: 'none !important',
					},
					'& .MuiDataGrid-toolbarContainer': {
						padding: 1,
					},
				}}
				disableColumnSelector={!withColumns}
				disableDensitySelector
				onFilterModelChange={onChange}
				slots={slots}
				slotProps={{
					toolbar: {
						setFilterButtonEl,
					},
					panel: {
						anchorEl: filterButtonEl,
					},
					...(disableLogicOperators && {
						filterPanel: {
							logicOperators: [],
						},
					}),
				}}
				localeText={localeText}
			/>
		</Box>
	)
}
