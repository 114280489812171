import Dagre, { graphlib, Label } from '@dagrejs/dagre'
import { Edge, MarkerType, Position } from 'reactflow'
import { TableTypes } from '@cango-app/types'

import { sortEdgesByNodes } from '../../chains/get-element-layout'

import { BasicQuestionaireData, CangoQuestionaireNode } from './types'

export const getLayout = ({
	rows,
	optionsColumnId,
	questionColumnId,
}: {
	rows: TableTypes.Record[]
	optionsColumnId: string
	questionColumnId: string
}) => {
	const g = new Dagre.graphlib.Graph({ directed: true }).setDefaultEdgeLabel(() => ({}))
	g.setGraph({ rankdir: 'LR' })

	const { edges, nodes } = rows.reduce(
		(
			acc: {
				edges: Edge[]
				nodes: Label[]
			},
			_row,
		) => {
			const rowChildren = _row.descendants ?? []
			const descendantsMap = new Map(rowChildren.map((_desc) => [_desc.row, _desc]))

			const nodeData: BasicQuestionaireData = {
				label: (_row.data[questionColumnId] as string) ?? '',
				answerConfig: (_row.data[optionsColumnId] as TableTypes.AnswerColumnConfig) ?? {
					answerType: TableTypes.AnswerType.SingleSelect,
					options: [],
				},
			}

			acc.nodes.push({
				id: _row._id,
				data: nodeData,
				type: 'question',
				width: 200,
				height: 300,
				sourcePosition: Position.Right,
				targetPosition: Position.Left,
			})

			const newEdges = rowChildren.reduce((_acc: Edge[], _childRow) => {
				if (_acc.find((_edge) => _edge.source === _row._id && _edge.target === _childRow.row)) {
					return _acc
				}

				const childMeta = descendantsMap.get(_childRow.row)

				if (!childMeta || !_childRow.row) {
					return _acc
				}

				_acc.push({
					id: `e${_row._id}--${_childRow.row}`,
					source: _row._id,
					target: _childRow.row,
					sourceHandle: 'left',
					targetHandle: 'right',
					markerEnd: {
						type: MarkerType.ArrowClosed,
					},
				})

				return _acc
			}, [])
			acc.edges.push(...newEdges)
			return acc
		},
		{
			edges: [],
			nodes: [],
		},
	)

	edges.forEach((edge) => {
		if (edge.source && edge.target) {
			g.setEdge(edge.source, edge.target)
		}
	})
	nodes.forEach((node) => g.setNode(node.id, node))
	Dagre.layout(g)

	const sortedNodes = graphlib.alg.topsort(g)
	const sortedEdges = sortEdgesByNodes(edges, sortedNodes)

	return {
		nodes: sortedNodes.reduce(
			(nodesAcc: CangoQuestionaireNode<BasicQuestionaireData>[], nodeId) => {
				const node = nodes.find((node) => node.id === nodeId)
				if (!node) {
					return nodesAcc
				}
				const { x, y } = g.node(node.id)
				return [...nodesAcc, { ...node, position: { x, y }, id: nodeId, data: node.data }]
			},
			[],
		),
		edges: sortedEdges,
	}
}
