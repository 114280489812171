import { ComponentType, MouseEvent, useContext, useMemo, useState } from 'react'
import { Handle, Position, NodeProps } from 'reactflow'
import Alert from '@mui/material/Alert'
import { V3BlueprintTypes } from '@cango-app/types'
import ReplayIcon from '@mui/icons-material/Replay'
import Stack from '@mui/system/Stack/Stack'
import { SelectChangeEvent } from '@mui/material'
import { v4 } from 'uuid'

import { Box, Chip, IconButton, Select, Text, Modal } from 'src/components'
import { PlusIcon } from 'src/assets/icons'
import { ThreadList } from 'src/components/section-tasks-v3/chains-list'
import { colors } from 'src/theme/colors'
import { DependencyModal } from 'src/modules/chains/components/chain-dependency-edge/dependency-modal'
import StepModal from 'src/modules/chains/components/step-modal'

import { ChainContext } from '../chain-provider'
import { NodeData, PropogatedNode } from '../types'

import { DependencyModalProps } from './chain-dependency-edge/types'

// Threads
// 1. Have all parents of a task
// 2. Check connections to check route is correct
// 3. Loop upwards to find all threads

type CycleDependency = Omit<DependencyModalProps, 'child'> & {
	child: V3BlueprintTypes.Descendant | undefined
}

const StandardNode: ComponentType<NodeProps<NodeData>> = ({ data, isConnectable }) => {
	const { onAddStep, nodeMap, stepCycles, threadMap, onUpdateConnection, onRemoveDeletedStep } =
		useContext(ChainContext)
	const [isCreatingStep, setIsCreatingStep] = useState(false)
	const [dependencyModalOpen, setDependencyModalOpen] = useState<CycleDependency | null>(null)
	const [showStepModal, setShowStepModal] = useState(false)

	const handleNewStepClick = async (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		setIsCreatingStep(true)
		await onAddStep(data._id)
		setIsCreatingStep(false)
	}

	const hasLoops = useMemo(() => {
		return [...stepCycles.values()].some((_cycle) => _cycle.source === data._id)
	}, [stepCycles, data._id])

	const threads: V3BlueprintTypes.Thread[] = useMemo(() => {
		return threadMap.get(data._id) ?? []
	}, [nodeMap, data._id])

	const multiUseParents = useMemo(() => {
		const step = nodeMap.get(data._id)
		if (!step) {
			return []
		}

		const endedMultiuses = new Set<string>()
		return step.data.parents.reduce((_parents: PropogatedNode[], _parentId) => {
			const parent = nodeMap.get(_parentId)
			if (!parent) {
				return _parents
			}

			parent.data.descendants.forEach((_desc) => {
				if (_desc.multi_use_config?.is_last_point_to_return) {
					endedMultiuses.add(_desc.step)
				}
			})

			if (!parent.data.isMultiUse || endedMultiuses.has(parent.data._id)) {
				return _parents
			}

			return [..._parents, parent]
		}, [])
	}, [data, nodeMap])

	const handleCycleClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		const tempDependency: CycleDependency = {
			source: data._id,
			target: '',
			onClose: () => setDependencyModalOpen(null),
			isMenu: data.isMenu,
			child: undefined,
			optionsWithFilter: data.complete_options,
		}
		setDependencyModalOpen(tempDependency)
	}

	const handleMultiuseSelect = async (event: SelectChangeEvent<unknown>) => {
		const target = event.target.value as string | undefined
		if (!target) {
			return
		}
		const newChildId = v4()
		const newDescendants = await onUpdateConnection({
			_id: newChildId,
			connection: { source: data._id, target: target },
			method: 'multiUse',
			option_condition: undefined,
			createForEveryOption: false,
			thread: null,
			databaseLogic: null,
			multiUseConfig: {
				is_last_point_to_return: false,
			},
			databaseChanges: [],
			chain_endings: [],
		})

		if (!newDescendants) {
			return
		}

		const newDescendant = newDescendants.find((_desc) => _desc._id === newChildId)

		if (!newDescendant) {
			return
		}

		setDependencyModalOpen({
			source: data._id,
			target: event.target.value as string,
			onClose: () => setDependencyModalOpen(null),
			isMenu: data.isMenu,
			child: newDescendant,
			optionsWithFilter: data.complete_options,
		})
	}

	const handleDeleteStep = (stepId: string) => {
		onRemoveDeletedStep(stepId)
	}

	return (
		<>
			{!!dependencyModalOpen && (!dependencyModalOpen.target || !dependencyModalOpen.child) && (
				<Modal open={true} onClose={() => setDependencyModalOpen(null)}>
					<Box width={400}>
						<Select
							label="Loop back to multi-use"
							options={multiUseParents.map((_parent) => ({
								_id: _parent.id,
								label: _parent.data.name,
							}))}
							onChange={handleMultiuseSelect}
						/>
					</Box>
				</Modal>
			)}
			{!!dependencyModalOpen && dependencyModalOpen.target && dependencyModalOpen.child && (
				<DependencyModal
					{...dependencyModalOpen}
					child={dependencyModalOpen.child}
					onClose={() => setDependencyModalOpen(null)}
				/>
			)}
			{showStepModal && (
				<StepModal
					stepId={data._id}
					onClose={() => setShowStepModal(false)}
					onDeleteStep={handleDeleteStep}
				/>
			)}
			<Box
				bgcolor={!data.isSection ? '#fff' : colors.lightCoral['20']}
				width={200}
				pt={2}
				pb={1}
				px={1}
				borderRadius={1}
				position="relative"
				onClick={() => setShowStepModal(true)}
			>
				<Handle type="target" position={Position.Top} isConnectable={isConnectable} />
				{!data.isSection && (
					<Text fontSize={12} mb={1}>
						Section:
						<b> {data.sections.join(', ')}</b>
					</Text>
				)}
				<Text fontSize={14} textAlign="center" mb={1}>
					{data.isSection && <b>Section: </b>}
					{data.name}
				</Text>
				{!!data.isMultiUse && (
					<Box mb={1}>
						<Chip label="multi-use" size="small" color="info" />
					</Box>
				)}
				{!!threads.length && <ThreadList threads={[...threads.values()]} />}
				{!data.isSection && !data.roles.length && (
					<Alert severity="warning">No roles assigned</Alert>
				)}
				<Box display="flex" justifyContent="space-between">
					<Box>{hasLoops && <Chip label="Loop" size="small" color="sunglow" />}</Box>
					<Stack direction="row" alignItems="center">
						{!!multiUseParents.length && (
							<IconButton size="small" onClick={handleCycleClick}>
								<ReplayIcon sx={{ width: 12, height: 12 }} />
							</IconButton>
						)}
						<IconButton size="small" onClick={handleNewStepClick} isLoading={isCreatingStep}>
							<PlusIcon width={12} stroke={colors.feldgrau['60']} />
						</IconButton>
					</Stack>
				</Box>
				<Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
			</Box>
		</>
	)
}

export default StandardNode
