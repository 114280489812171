import { ComponentType, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3BlueprintTypes } from '@cango-app/types'
import { Alert, AlertTitle } from '@mui/material'

import { Box, Button } from 'src/components'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { AsyncDispatchType } from 'src/store/types'
import { DriveFilesContext, TaskContext } from 'src/providers'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'

import { ReportBlockedTaskModal } from './report-blocked-task-modal'
import { CompleteTaskButton, OnPostCompleteTaskProps } from './complete-task-button'

export const BottomButtonBar: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const { goToTask, selectedSectionId, goToSection } = useMyTasks()
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)
	const { allFiles, addNewFilesToCollection } = useParentFolderFiles(
		parentFolderId as string,
		parentFolderName as string,
	)
	const { task, updateTask } = useContext(TaskContext)
	const project = useSelector(projectSelectors.getSelectedProject)

	const [isResolving, setIsResolving] = useState(false)
	const [shouldOpenBlockedModal, setShouldOpenBlockedModal] = useState(false)

	const handleCompleteTaskClick = async ({
		status,
		hasResolveFlagged = false,
		payload,
	}: OnPostCompleteTaskProps) => {
		if (!task) {
			return
		}

		if (status !== 'fulfilled') {
			return
		}
		updateTask('lifecycle.complete', true)

		const newTasks = payload.tasks
		const allFileIds = allFiles.map((_file) => _file.id)
		const hasNewFiles = newTasks.some((_task) =>
			_task.actions.some((_action) =>
				_action.file_ids.some((_fileId) => !allFileIds.includes(_fileId)),
			),
		)
		if (hasNewFiles) {
			addNewFilesToCollection()
		}

		if (payload.nextTaskId) {
			goToTask(payload.nextTaskId)
		} else {
			goToSection(payload.nextSectionId ?? selectedSectionId)
		}
		if (task.unblock_task && hasResolveFlagged) {
			dispatch(
				projectActions.resolveFlaggedTask({
					projectId: task?.project_id,
					taskId: task.unblock_task._id,
				}),
			)
		}
	}

	const handleResolveBlockedTaskClick = async () => {
		if (!task) return
		setIsResolving(true)
		updateTask('isFlagged', false, { updateDb: true })
		setIsResolving(false)
	}

	return (
		<>
			<ReportBlockedTaskModal
				open={shouldOpenBlockedModal}
				onClose={() => setShouldOpenBlockedModal(false)}
			/>
			<Box
				display="flex"
				flexDirection={{ mobile: 'column', laptop: 'row' }}
				justifyContent="flex-end"
				flexGrow={{ mobile: 1, laptop: 0 }}
				py={2}
			>
				<Box display="flex" flexDirection={{ mobile: 'column', laptop: 'row' }}>
					{!task?.isFlagged ? (
						<Button
							variant="text"
							sx={{ mr: { laptop: 2 }, mb: { mobile: 2, laptop: 0 }, mt: { laptop: 2 } }}
							onClick={() => setShouldOpenBlockedModal(true)}
						>
							Report blocked task
						</Button>
					) : (
						<Button
							sx={{ mr: { laptop: 2 }, mb: { mobile: 2, laptop: 0 } }}
							color="sunglow"
							onClick={handleResolveBlockedTaskClick}
							isLoading={isResolving}
						>
							Resolve blocked task
						</Button>
					)}
					<Box>
						{!!task && !project?.archived.state && (
							<CompleteTaskButton
								onPostComplete={handleCompleteTaskClick}
								sectionId={selectedSectionId}
							/>
						)}
						{task?.actions.some(
							(_action) => _action.type === V3BlueprintTypes.ActionEnum.Archive,
						) && (
							<Alert severity="warning">
								<AlertTitle>Warning</AlertTitle>
								One of the actions of this task is to archive the project. By completing this task
								you will <b>NOT</b> be archiving the project
							</Alert>
						)}
					</Box>
				</Box>
			</Box>
		</>
	)
}
