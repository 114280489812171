import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import { PulseLoader } from 'react-spinners'
import _isEmpty from 'lodash/isEmpty'
import { v4 } from 'uuid'
import Stack from '@mui/system/Stack/Stack'

import { TableContext } from 'src/providers'
import { colors } from 'src/theme/colors'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { Modal } from '../modal'
import { Box } from '../box'
import { Text } from '../text'
import { Button } from '../button'
import { DisplayView } from '../display-view'
import { PresentationView } from '../display-view/presentation-view'

import { ColumnsAndFilters } from './columns-and-filters'
import { ViewSettings } from './view-settings'
import { getDefaultValues } from './getDefaultValues'
import { GroupingChildren } from './grouping-children'

const DisplayTable: ComponentType = () => {
	const { watch } = useFormContext<TableTypes.TableView>()
	const formFields = watch()
	const { tableConfig, mappedColumns, resolvedRows } = useContext(TableContext)

	if (!tableConfig) return null

	if (formFields.displayMode === TableTypes.ViewStyleMode.editable) {
		return <DisplayView {...formFields} />
	}

	return (
		<PresentationView rows={resolvedRows} columns={[...mappedColumns.values()]} view={formFields} />
	)
}

const ViewForm: ComponentType<{
	onViewSaved: (newViewId: string | undefined) => void
	viewId: string | undefined
}> = ({ onViewSaved, viewId }) => {
	const { tableConfig, updateTableConfig } = useContext(TableContext)
	const formControl = useForm<TableTypes.TableView>({
		defaultValues: getDefaultValues({ tableViews: tableConfig?.views ?? [], viewId }),
	})
	const {
		handleSubmit,
		formState: { errors },
	} = formControl

	const [expandedStage, setExpandedStage] = useState<number | false>(0)
	const [isSavingView, setIsSavingView] = useState(false)

	const handleAccordionChange =
		(panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpandedStage(newExpanded ? panel : false)
		}

	const handleSaveView = async (data: TableTypes.TableView) => {
		if (!tableConfig) return
		setIsSavingView(true)

		if (data.displayMode === TableTypes.ViewStyleMode.presentation) {
			data.displayChildren = true
		}

		try {
			const existingViewIndex = tableConfig.views.findIndex((view) => view._id === viewId)
			let viewsCopy = [...(tableConfig.views ?? [])]
			if (viewId && existingViewIndex !== -1) {
				viewsCopy[existingViewIndex] = { ...data, _id: viewId }
				await updateTableConfig({ views: viewsCopy }, true)
				onViewSaved(undefined)
			} else {
				const newId = v4()
				viewsCopy = [{ ...data, _id: newId }, ...viewsCopy]
				await updateTableConfig({ views: viewsCopy }, true)
				onViewSaved(newId)
			}
		} catch (err) {
			showSnackbar('Error creating view', { variant: 'error' })
		} finally {
			setIsSavingView(false)
		}
	}

	const handleDeleteView = async () => {
		if (!tableConfig) return
		try {
			await updateTableConfig(
				{ views: tableConfig?.views?.filter((view) => view._id !== viewId) },
				true,
			)
			onViewSaved(undefined)
		} catch (error) {
			showSnackbar('Error deleting view', { variant: 'error' })
		}
	}

	return (
		<FormProvider {...formControl}>
			<Box
				width="95vw"
				height="88vh"
				display="flex"
				flexDirection="column"
				position="relative"
				py={2}
			>
				<Box height="80%" sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: 4 }}>
					<Accordion expanded={expandedStage === 0} onChange={handleAccordionChange(0)}>
						<AccordionSummary>
							<Text variant="h6">View settings</Text>
						</AccordionSummary>
						<AccordionDetails>
							<ViewSettings />
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expandedStage === 1} onChange={handleAccordionChange(1)}>
						<AccordionSummary>
							<Text variant="h6">Filters</Text>
						</AccordionSummary>
						<AccordionDetails>
							<ColumnsAndFilters viewId={viewId} />
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expandedStage === 5}
						onChange={handleAccordionChange(5)}
						sx={{ mb: 2 }}
					>
						<AccordionSummary>
							<Text variant="h6">Grouping and Aggregations</Text>
						</AccordionSummary>
						<AccordionDetails>
							<GroupingChildren />
						</AccordionDetails>
					</Accordion>
					<Accordion expanded>
						<AccordionSummary>
							<Text variant="h6">Preview</Text>
						</AccordionSummary>
						<AccordionDetails>
							<Box height="auto">
								<FormProvider {...formControl}>
									<DisplayTable />
								</FormProvider>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>
				<Box position="fixed" bottom={8}>
					<Stack sx={{ mt: 2 }} flexDirection="row">
						<Button onClick={handleSubmit(handleSaveView)} isLoading={isSavingView} sx={{ mr: 1 }}>
							Save view
						</Button>
						<Button variant="outlined" color="error" onClick={handleDeleteView}>
							Delete view
						</Button>
					</Stack>
					{!_isEmpty(errors) && (
						<Text color="red" fontSize={14} mt={1}>
							Missing fields
						</Text>
					)}
				</Box>
			</Box>
		</FormProvider>
	)
}

const CreateViewLoadingContainer: ComponentType<{
	viewId: string | undefined
	onViewSaved: (newViewId: string | undefined) => void
}> = (props) => {
	const { tableConfig, isLoadingTable } = useContext(TableContext)

	if (isLoadingTable || !tableConfig) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center">
				<PulseLoader />
			</Box>
		)
	}

	return <ViewForm {...props} />
}

const CreateViewContainer: ComponentType<{
	open: boolean
	onClose: () => void
	viewId: string | undefined
	onViewSaved: (newViewId: string | undefined) => void
}> = ({ open, onClose, viewId, onViewSaved }) => {
	const { tableConfig } = useContext(TableContext)

	const viewName = useMemo(() => {
		if (!tableConfig) return ''

		if (!viewId) {
			return 'Create view'
		}

		const view = tableConfig.views.find((view) => view._id === viewId)

		if (!view) {
			return 'Edit view'
		}

		return view.name
	}, [tableConfig?.views, viewId])

	return (
		<Modal
			open={open}
			onClose={onClose}
			withCloseButton
			containerStyle={{ bgcolor: colors.neutral['10'], maxHeight: 'auto', py: 2 }}
		>
			<Box>
				<Text variant="h5">{viewName}</Text>
				<CreateViewLoadingContainer onViewSaved={onViewSaved} viewId={viewId} />
			</Box>
		</Modal>
	)
}

export default CreateViewContainer
