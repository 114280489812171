import { ComponentType } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox } from 'src/components'

import { DependencyForm } from './types'

export const ApplySelectionToFilter: ComponentType = () => {
	const { control } = useFormContext<DependencyForm>()

	return (
		<Controller
			control={control}
			name="option_condition.add_selection_to_database_chain_filters"
			render={({ field: { value, onChange } }) => {
				return (
					<Checkbox label="Apply selection to database logic" checked={value} onChange={onChange} />
				)
			}}
		/>
	)
}
