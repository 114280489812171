import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { ClientTypes, PermissionsTypes } from '@cango-app/types'
import { UserSdk } from '@cango-app/sdk'

import { errorHandler } from '../../../helpers/api'
import { selectors as authSelectors } from '../auth'
import { RootState } from '../../types'

export const fetchAllUsers = createAsyncThunk<
	ClientTypes.User[],
	{ withAssignments?: boolean } | undefined,
	{ rejectValue: string; dispatch: Dispatch }
>('user/getAllUsers', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)

		const response = await UserSdk.getAll(import.meta.env.VITE_API as string, headers)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateUser = createAsyncThunk<UserSdk.UpdateUserResponse, UserSdk.UpdateUserRequest>(
	'user/updateUser',
	async (data, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)

			const response = await UserSdk.updateUser(import.meta.env.VITE_API as string, headers, data)

			// REIMPLEMENT SESSION JWT UPDATE ON PERMISSION LEVEL CHANGE
			// const savedUserDetails = userSelectors.getUserDetails(state)
			// const response = await axios.put('user', user, headers)
			// let updatedUser = undefined

			// const updatingCurrentUser = savedUserDetails?._id === user._id
			// const rolesUpdated = _.isEqual(savedUserDetails?.roles, user.roles)
			// const permissionsUpdated = savedUserDetails?.permissions !== user.permissions
			// if (updatingCurrentUser && (permissionsUpdated || rolesUpdated)) {
			// 	const sessionResponse = await axios.put('session', user, headers)
			// 	updatedUser = decodeJwt(sessionResponse.data.token) as ClientTypes.User
			// 	dispatch(authActions.setJwt(sessionResponse.data.token))
			// }
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const deleteUser = createAsyncThunk<
	UserSdk.DeleteUserResponse,
	{
		userId: string
		substituteUserId: string
	},
	{
		state: RootState
		dispatch: Dispatch
	}
>(
	'user/deleteUser',
	async ({ userId, substituteUserId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const response = await UserSdk.deleteUser(
				import.meta.env.VITE_API as string,
				headers,
				userId,
				{
					substituteUserId,
				},
			)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const createUser = createAsyncThunk<
	UserSdk.CreateUserResponse,
	Omit<UserSdk.CreateUserRequest, 'version'>,
	{
		state: RootState
		dispatch: Dispatch
	}
>('user/createUser', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await UserSdk.createUser(import.meta.env.VITE_API as string, headers, {
			...data,
		})
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateUsersPermissions = createAsyncThunk<
	{
		userIds: string[]
		permission: PermissionsTypes.Permissions
	},
	{
		userIds: string[]
		permission: PermissionsTypes.Permissions
	},
	{
		state: RootState
		dispatch: Dispatch
	}
>(
	'user/updateUsersPermissions',
	async ({ permission, userIds }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			await UserSdk.updateUserPermissions(import.meta.env.VITE_API as string, headers, {
				userIds,
				permissionId: permission._id,
			})
			return { permission, userIds }
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)
