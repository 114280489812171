import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { Box, Divider } from 'src/components'
import { APP_BAR_HEIGHT } from 'src/routing/navigation/desktop-navigation'
import { TableProvider } from 'src/providers'

import { selectors as projectSelectors } from '../../store/modules/projects-v3'

import { ProjectsList } from './project-list/project-list'
import SectionContainer from './section'

export const MyTasksDesktopContainer: ComponentType = () => {
	const project = useSelector(projectSelectors.getSelectedProject)
	return (
		<TableProvider tableId={project?.database_table}>
			<Box
				px={4}
				flex={1}
				maxHeight={{ laptop: `calc(100vh - ${APP_BAR_HEIGHT}px)` }}
				display="flex"
				flexDirection="column"
				overflow="auto"
			>
				<Box display="flex" flex={1} sx={{ overflowY: 'auto' }}>
					<Box width={490} flex={1} height="100%" mr={1}>
						<ProjectsList />
					</Box>
					<Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
					<SectionContainer />
				</Box>
			</Box>
		</TableProvider>
	)
}
