import { V3BlueprintTypes, V3ClientTypes, V3ProjectTypes } from '@cango-app/types'
import _get from 'lodash/get'
import _uniqBy from 'lodash/uniqBy'

import { ProjectSection } from 'src/store/modules/projects-v3/selectors'

import { ProjectGroupingType } from '../store/modules/persisted-config'

type TaskWithSectionId = V3ClientTypes.Project.Task & { sectionId: string }

type TaskWithScope = V3ClientTypes.Project.Task & { scope: string[] }

const nextEligibleTask = ({
	taskId,
	direction,
	checkOppositeDirection,
	sections,
	goToTask,
}: {
	taskId: string
	direction: 1 | -1
	checkOppositeDirection?: boolean
	sections: ProjectSection[]
	goToTask?: string
}): V3ClientTypes.Project.Task | null => {
	let currentSectionId: string | undefined = undefined

	const taskList = sections.reduce((_acc: TaskWithSectionId[], _section) => {
		return [
			..._acc,
			..._section.tasks.map((_task) => {
				if (_task._id === taskId) {
					currentSectionId = _section._id
				}
				return {
					..._task,
					sectionId: _section._id,
				}
			}),
		]
	}, [])
	const currentIndex = taskList.findIndex(({ _id }) => _id === taskId)
	if (goToTask) {
		const goToTaskIndex = taskList.findIndex(({ step }) => step?._id === goToTask)
		if (goToTaskIndex !== -1) {
			return taskList[goToTaskIndex]
		}
	}

	let index = currentIndex

	if (index < 0) return null

	// Define a helper function to check if a task is eligible
	const isEligible = (task: TaskWithSectionId | undefined) => {
		if (!task || task.sectionId !== currentSectionId) return false
		return !(task.isMultiUse || task.lifecycle.complete)
	}

	// Check in the initial direction
	while (index >= 0 && index < taskList.length) {
		index += direction
		const task = _get(taskList, index)
		if (isEligible(task)) {
			return task
		}
	}

	// If no eligible task found in the initial direction and checkOppositeDirection is true
	if (checkOppositeDirection) {
		index = currentIndex
		while (index >= 0 && index < taskList.length) {
			index -= direction // Opposite direction
			const task = _get(taskList, index)
			if (isEligible(task)) {
				return task
			}
		}
	}

	// If still no eligible task found, return null
	return null
}

const nextEligibleSection = ({
	sectionId,
	direction,
	checkOppositeDirection,
	sections,
}: {
	sectionId: string
	direction: 1 | -1
	checkOppositeDirection?: boolean
	sections: ProjectSection[]
}): ProjectSection | null => {
	const currentIndex = sections.findIndex(({ _id }) => _id === sectionId)
	let index = currentIndex

	if (index < 0) return null

	// Define a helper function to check if a task is eligible
	const isEligible = (section: ProjectSection | undefined) => {
		return !!section
	}

	// Check in the initial direction
	while (index >= 0 && index < sections.length) {
		index += direction
		const section = _get(sections, index)
		if (isEligible(section)) {
			return section
		}
	}

	// If no eligible task found in the initial direction and checkOppositeDirection is true
	if (checkOppositeDirection) {
		index = currentIndex
		while (index >= 0 && index < sections.length) {
			index -= direction // Opposite direction
			const section = _get(sections, index)
			if (isEligible(section)) {
				return section
			}
		}
	}

	// If still no eligible task found, return null
	return null
}

const constructProjectSections = (
	projectTasks: V3ClientTypes.Project.Task[],
	groupBy?: ProjectGroupingType,
) => {
	const chains = new Map<string, V3ClientTypes.Project.Task['chain']>()

	let sections: {
		sectionName: string
		projectId: string
		chainId?: string
		chain: V3ClientTypes.Project.Task['chain']
	}[] = []
	if (groupBy === ProjectGroupingType.Sections) {
		sections = projectTasks.reduce(
			(
				_acc: {
					sectionName: string
					projectId: string
					chain: V3ClientTypes.Project.Task['chain']
				}[],
				_task,
			) => {
				if (_task.section === V3BlueprintTypes.PROJECT_WIDE_SECTION_NAME) return _acc
				if (!_acc.some((_section) => _section.sectionName === _task.section)) {
					_acc.push({
						sectionName: _task.section,
						projectId: _task.project_id,
						chain: undefined,
					})
				}
				return _acc
			},
			[],
		)
	} else if (groupBy === ProjectGroupingType.Tags) {
		const chainSections = projectTasks.reduce(
			(_acc: { chainId?: string; sectionName: string; projectId: string }[], _task) => {
				if (_task.section === V3BlueprintTypes.PROJECT_WIDE_SECTION_NAME) return _acc

				if (_task.chain?._id && !chains.has(_task.chain._id)) {
					chains.set(_task.chain._id, _task.chain)
				}

				const taskChainId = _task.chain?._id

				if (!_acc.some((_section) => _section.sectionName === _task.section)) {
					_acc.push({
						chainId: taskChainId,
						sectionName: _task.section,
						projectId: _task.project_id,
					})
					return _acc
				}

				const correspondingChainSections = _acc.filter(({ chainId }) => chainId === taskChainId)

				if (!correspondingChainSections.length) {
					_acc.push({
						chainId: taskChainId,
						sectionName: _task.section,
						projectId: _task.project_id,
					})
					return _acc
				}

				const matchingSection = correspondingChainSections.find(
					({ sectionName }) => sectionName === _task.section,
				)

				if (!matchingSection) {
					_acc.push({
						chainId: taskChainId,
						sectionName: _task.section,
						projectId: _task.project_id,
					})
					return _acc
				}
				return _acc
			},
			[],
		)

		sections = chainSections.map((_section) => {
			return {
				..._section,
				chain: chains.get(_section.chainId ?? ''),
			}
		})
	}

	return sections
}

export const getChainList = (chain: V3ClientTypes.Project.Task['chain']) => {
	const parentThreads = chain?.parent_chains ?? []

	if (!chain?._id) {
		return _uniqBy(parentThreads, '_id')
	}

	return _uniqBy([...parentThreads, chain], '_id')
}

const orderTasksByScope = (tasks: V3ClientTypes.Project.Task[]) => {
	const tasksWithScope: TaskWithScope[] = tasks.map((_task) => ({
		..._task,
		scope: getChainList(_task.chain).map(({ _id }) => _id),
	}))

	function sortHierarchy(
		tasks: TaskWithScope[],
		parentScope: string[] = [],
		visited: Set<string> = new Set(),
		result: any[] = [],
	) {
		const groupedTasks: Record<string, any[]> = {}

		tasks.forEach((task) => {
			if (!task.scope || !task._id) return

			if (visited.has(task._id)) {
				return
			}
			visited.add(task._id)

			const nextLevel = task.scope.slice(parentScope.length)[0] || ''
			if (!groupedTasks[nextLevel]) {
				groupedTasks[nextLevel] = []
			}
			groupedTasks[nextLevel].push(task)
		})

		Object.keys(groupedTasks).forEach((key) => {
			groupedTasks[key].sort((a, b) => a.created_at - b.created_at)
			groupedTasks[key].forEach((task) => {
				result.push(task) // Add the current task to the result
				sortHierarchy(tasks, [...parentScope, key], visited, result) // Recurse for children
			})
		})

		return result
	}

	return sortHierarchy(tasksWithScope)
}

export const constructProjectSectionsWithTasks = ({
	tasks,
	userId,
	returnAll = false,
	groupBy = ProjectGroupingType.Tags,
}: {
	tasks: V3ClientTypes.Project.Task[]
	userId: string
	returnAll?: boolean
	groupBy?: ProjectGroupingType
}) => {
	const orderedTasks = orderTasksByScope(tasks)
	const sections = constructProjectSections(orderedTasks, groupBy)

	const allTasks = Array.from(tasks.values())

	if (groupBy === ProjectGroupingType.Sections) {
		return sections.reduce((_acc: ProjectSection[], _section) => {
			const sectionTasks: V3ClientTypes.Project.Task[] = allTasks.filter((_task) => {
				return !(
					_task.section !== _section.sectionName ||
					(!returnAll && !_task.assignees?.some(({ user }) => user === userId))
				)
			})

			if (
				!returnAll &&
				(!sectionTasks.length || sectionTasks.every((_task) => _task.lifecycle.complete))
			) {
				return _acc
			}
			return [
				..._acc,
				{
					_id: _section.sectionName,
					name: _section.sectionName,
					tasks: sectionTasks,
					projectId: _section.projectId,
					chain: undefined,
					isBlocked: sectionTasks.some(({ isFlagged }) => isFlagged),
				},
			]
		}, [])
	}

	return sections.reduce((_acc: ProjectSection[], _section) => {
		const sectionTasks: V3ClientTypes.Project.Task[] = allTasks.filter((_task) => {
			if (
				_task.section !== _section.sectionName ||
				(!returnAll && !_task.assignees?.some(({ user }) => user === userId))
			)
				return false
			return _section.chain?._id === _task.chain?._id
		})

		if (
			!returnAll &&
			(!sectionTasks.length || sectionTasks.every((_task) => _task.lifecycle.complete))
		) {
			return _acc
		}
		return [
			..._acc,
			{
				_id: _section.chain?._id
					? `${_section.chain._id}+${_section.sectionName}`
					: _section.sectionName,
				name: _section.sectionName,
				tasks: sectionTasks,
				chain: _section.chain,
				isBlocked: sectionTasks.some(({ isFlagged }) => isFlagged),
				projectId: _section.projectId,
			},
		]
	}, [])
}

export const getNextEligibleTask = (
	currentTaskId: string,
	tasks: V3ClientTypes.Project.Task[],
	userId: string,
	groupBy: ProjectGroupingType,
	goToTask: string | undefined,
) => {
	const sections = constructProjectSectionsWithTasks({ tasks, userId, groupBy })
	return nextEligibleTask({
		taskId: currentTaskId,
		direction: 1,
		checkOppositeDirection: true,
		sections,
		goToTask,
	})
}

export const getNextEligibleSection = (
	currentSectionId: string,
	tasks: V3ClientTypes.Project.Task[],
	userId: string,
	groupBy: ProjectGroupingType,
) => {
	const sections = constructProjectSectionsWithTasks({ tasks, userId, groupBy })
	return nextEligibleSection({
		sectionId: currentSectionId,
		direction: 1,
		checkOppositeDirection: true,
		sections,
	})
}
