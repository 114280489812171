import { V3BlueprintTypes, ClientTypes } from '@cango-app/types'
import { ComponentType, PropsWithChildren, useContext, useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Badge, Box, SelectChangeEvent } from '@mui/material'
import { v4 } from 'uuid'

import { Chip, Select, TextField, Text, Button, Modal, IconButton, Divider } from 'src/components'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { TableFilters } from 'src/components/table-filters'
import { EditPenIcon, TrashIcon } from 'src/assets/icons'
import { getActionLabel } from 'src/helpers/labels'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { TemplatesContext } from 'src/providers/templates-provider'
import { ViewsButton } from 'src/modules/tables/views-button'
import { colors } from 'src/theme/colors'
import { TableContext } from 'src/providers'

import { StepFormType } from './step-form-container'
import { TemplateButton } from './template-button'
import { TemplateUploadFieldsV2 } from './template-upload-fields-v2'

// this is duplicated from ACTIONS_REQUIRING_ROLES
export const ATTACHMENTS_REQUIRING_ROLES = [
	V3BlueprintTypes.ActionEnum.Call,
	V3BlueprintTypes.ActionEnum.Email,
	V3BlueprintTypes.ActionEnum.Invite,
	V3BlueprintTypes.ActionEnum.Meeting,
]
// this is duplicated from ACTIONS_REQUIRING_LINK
export const ATTACHMENTS_REQUIRING_LINK = [
	V3BlueprintTypes.ActionEnum.Software,
	V3BlueprintTypes.ActionEnum.Video,
]

type Props = {
	type: V3BlueprintTypes.ActionEnum
	attachmentIndex: number
	chainIndex: number
}

const AttachmentButton: ComponentType<
	PropsWithChildren<{ onClick: () => void; badgeContent: string | number }>
> = ({ children, badgeContent, onClick }) => {
	return (
		<Button variant="text" onClick={onClick}>
			<Badge badgeContent={badgeContent} color="primary">
				{children}
			</Badge>
		</Button>
	)
}

const LinkInput: React.FC<Pick<Props, 'attachmentIndex' | 'chainIndex'>> = ({
	attachmentIndex,
	chainIndex,
}) => {
	const { control } = useFormContext<StepFormType>() // Make sure to have the correct generic <StepFormType>
	const { fields, append, remove } = useFieldArray({
		control,
		name: `chain_actions.${chainIndex}.attachments.${attachmentIndex}.links`,
	})

	return (
		<Box>
			{fields.map((field, fieldIndex) => (
				<Controller
					key={field._id} // It's important to use a unique key for list items
					control={control}
					name={`chain_actions.${chainIndex}.attachments.${attachmentIndex}.links.${fieldIndex}.link`}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<Box display="flex">
							<TextField
								error={!!error}
								label="Link"
								value={value}
								fullWidth
								onChange={onChange}
								containerProps={{ flex: 1, mb: 2 }}
							/>
							<Box>
								<IconButton sx={{ mt: 3, ml: 1 }} onClick={() => remove(fieldIndex)}>
									<TrashIcon width={14} />
								</IconButton>
							</Box>
						</Box>
					)}
				/>
			))}
			<Button
				variant="outlined"
				size="small"
				onClick={() => {
					append({
						_id: v4(),
						link: '',
					})
				}}
			>
				Add new link
			</Button>
		</Box>
	)
}

const TaskReferences: React.FC<Pick<Props, 'attachmentIndex' | 'chainIndex'>> = ({
	attachmentIndex,
	chainIndex,
}) => {
	const { control, watch } = useFormContext<StepFormType>()
	const stepId = watch('_id')
	const { nodes } = useContext(ChainContext)
	const { fields, append, remove } = useFieldArray({
		control,
		name: `chain_actions.${chainIndex}.attachments.${attachmentIndex}.task_references`,
	})

	const taskOptions = useMemo(() => {
		const filteredNodes = nodes.filter((_node) => _node.id !== stepId && !_node.data.isSection)
		return filteredNodes.map((_node) => ({
			_id: _node.id,
			label: _node.data.name,
		}))
	}, [nodes])

	return (
		<Box>
			{fields.length === 0 && (
				<Text
					sx={{
						textAlign: 'center',
						mb: 2,
					}}
				>
					No Task Reference
				</Text>
			)}
			{fields.map((field, fieldIndex) => (
				<>
					<Box key={field.task}>
						<Controller
							control={control}
							name={`chain_actions.${chainIndex}.attachments.${attachmentIndex}.task_references.${fieldIndex}.task`}
							render={({ field: { value, onChange } }) => (
								<Box display="flex">
									<Select
										value={value}
										onChange={onChange}
										label="Select a task"
										options={taskOptions}
										containerProps={{ width: 375 }}
									/>
									<Box>
										<IconButton sx={{ mt: 3, ml: 1 }} onClick={() => remove(fieldIndex)}>
											<TrashIcon width={14} />
										</IconButton>
									</Box>
								</Box>
							)}
						/>
						<Controller
							control={control}
							name={`chain_actions.${chainIndex}.attachments.${attachmentIndex}.task_references.${fieldIndex}.fields`}
							render={({ field: { value, onChange } }) => (
								<Select
									multiple
									label="Fields"
									value={value}
									onChange={onChange}
									options={Object.values(V3BlueprintTypes.TaskReferenceEnum).map((type) => ({
										_id: type,
										label: getActionLabel(type),
									}))}
									containerProps={{ maxWidth: 375, mb: 1 }}
								/>
							)}
						/>
					</Box>
					<Divider sx={{ mb: 2 }} />
				</>
			))}
			<Button
				variant="outlined"
				size="small"
				onClick={() => {
					append({
						task: '',
						fields: [],
					})
				}}
			>
				Add a task reference
			</Button>
		</Box>
	)
}
export const AttachmentsFields: ComponentType<Props> = ({ type, attachmentIndex, chainIndex }) => {
	const { control, watch, setValue } = useFormContext<StepFormType>()
	const watchLinks = watch(`chain_actions.${chainIndex}.attachments.${attachmentIndex}.links`)
	const watchTemplate = watch(
		`chain_actions.${chainIndex}.attachments.${attachmentIndex}.template.template`,
	)
	const watchFileIds = watch(`chain_actions.${chainIndex}.attachments.${attachmentIndex}.file_ids`)
	const watchTaskReferences = watch(
		`chain_actions.${chainIndex}.attachments.${attachmentIndex}.task_references`,
	)
	const selectedViewId = watch(`chain_actions.${chainIndex}.attachments.${attachmentIndex}.view`)
	const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
	const [openView, setOpenView] = useState(false)
	const { templates } = useContext(TemplatesContext)
	const { tableConfig } = useContext(TableContext)
	const roles = useSelector(roleSelectors.getRoles)

	const viewSelected = useMemo(() => {
		if (!tableConfig?.views || type !== V3BlueprintTypes.ActionEnum.SetResources) {
			return null
		}

		return tableConfig.views.find((_view) => _view._id === selectedViewId)
	}, [type, tableConfig?.views, selectedViewId])

	if (type === V3BlueprintTypes.ActionEnum.None) {
		return (
			<Box flex={1} display="flex" justifyContent="center" alignItems="center">
				<Text>Select an attachment type</Text>
			</Box>
		)
	}

	if (ATTACHMENTS_REQUIRING_ROLES.includes(type)) {
		return (
			<Controller
				control={control}
				name={`chain_actions.${chainIndex}.attachments.${attachmentIndex}.roles`}
				render={({ field: { value, onChange }, fieldState: { error } }) => {
					const handleChange = (event: SelectChangeEvent<unknown>) => {
						const { value } = event.target
						onChange(typeof value === 'string' ? value.split(',') : value)
					}
					return (
						<Select
							error={!!error}
							label={`${type} who?`}
							onChange={handleChange}
							value={value || []}
							multiple
							multiline
							options={roles}
							containerProps={{ mb: 2, maxWidth: 375 }}
							renderValue={(selected: any) => {
								const roleNames = selected.map((id: string) =>
									roles.find((role) => role._id === id),
								)
								return (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{roleNames.map((role: ClientTypes.Role) => {
											return role ? <Chip key={role._id} label={role.label} /> : ''
										})}
									</Box>
								)
							}}
						/>
					)
				}}
			/>
		)
	}

	if (type === V3BlueprintTypes.ActionEnum.Note) {
		return (
			<Controller
				control={control}
				name={`chain_actions.${chainIndex}.attachments.${attachmentIndex}.note`}
				render={({ field: { value, onChange } }) => (
					<TextField
						label="Describe the action"
						value={value}
						fullWidth
						multiline
						onChange={(event) => onChange(event.target.value)}
						sx={{ mb: 2 }}
					/>
				)}
			/>
		)
	}

	if (type === V3BlueprintTypes.ActionEnum.Questionaire) {
		return (
			<Box
				display="flex"
				flex={1}
				justifyContent="center"
				alignItems="center"
				flexDirection="column"
			>
				<Text variant="caption">Questionaire table filters:</Text>
				<Controller
					name={`attachments.${attachmentIndex}.questionaire`}
					render={({ field: { onChange, value } }) => {
						return <TableFilters initialState={value} onChange={onChange} />
					}}
				/>
			</Box>
		)
	}

	if (type === V3BlueprintTypes.ActionEnum.SetResources) {
		return (
			<>
				{!!viewSelected && (
					<Box display="flex" justifyContent="space-between" mb={2}>
						<Box
							display="flex"
							justifyContent="space-between"
							border="1px solid"
							borderColor={colors.neutral['40']}
							borderRadius={2}
							px={2}
							py={1}
							minWidth={175}
							bgcolor="#fff"
						>
							<Box>
								<Text variant="overline">View</Text>
								<Text>{viewSelected.name}</Text>
							</Box>
							<Box sx={{ ml: 2 }} display="flex" alignItems="center">
								<IconButton onClick={() => setOpenView(true)}>
									<EditPenIcon stroke={colors.neutral['60']} />
								</IconButton>
								<IconButton
									onClick={() => {
										setValue(
											`chain_actions.${chainIndex}.attachments.${attachmentIndex}.view`,
											'',
											{
												shouldDirty: true,
											},
										)
									}}
								>
									<TrashIcon stroke={colors.neutral['60']} />
								</IconButton>
							</Box>
						</Box>
					</Box>
				)}
				<ViewsButton
					openViewParams={{
						open: openView,
						viewId: selectedViewId,
					}}
					onViewSelect={(viewId) => {
						setValue(`chain_actions.${chainIndex}.attachments.${attachmentIndex}.view`, viewId, {
							shouldDirty: true,
						})
						if (!viewId) {
							setOpenView(true)
						}
					}}
					onClose={() => setOpenView(false)}
					label={selectedViewId ? 'Change view' : 'Select view'}
					buttonVariant="outlined"
					size="small"
					onViewSaved={(viewId) =>
						setValue(`chain_actions.${chainIndex}.attachments.${attachmentIndex}.view`, viewId, {
							shouldDirty: true,
						})
					}
				/>
			</>
		)
	}

	if (type === V3BlueprintTypes.ActionEnum.Template) {
		const foundTemplate = templates.find(({ _id }) => watchTemplate === _id)
		return (
			<>
				<Button variant="text" onClick={() => setOpenAttachmentModal(true)}>
					{foundTemplate ? foundTemplate.name : 'Select Template'}
				</Button>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="750px">
						<TemplateButton
							chainIndex={chainIndex}
							actionIndex={attachmentIndex}
							type="attachments"
						/>
					</Box>
				</Modal>
			</>
		)
	}

	if (ATTACHMENTS_REQUIRING_LINK.includes(type)) {
		return (
			<>
				<AttachmentButton
					badgeContent={watchLinks ? watchLinks.length : 0}
					onClick={() => setOpenAttachmentModal(true)}
				>
					Edit links
				</AttachmentButton>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="750px">
						<LinkInput attachmentIndex={attachmentIndex} chainIndex={chainIndex} />
					</Box>
				</Modal>
			</>
		)
	}

	if (type === V3BlueprintTypes.ActionEnum.TaskReference) {
		return (
			<>
				<AttachmentButton
					badgeContent={watchTaskReferences ? watchTaskReferences.length : 0}
					onClick={() => setOpenAttachmentModal(true)}
				>
					Edit Task References
				</AttachmentButton>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="450px">
						<TaskReferences attachmentIndex={attachmentIndex} chainIndex={chainIndex} />
					</Box>
				</Modal>
			</>
		)
	}

	if (type === V3BlueprintTypes.ActionEnum.FileTemplate) {
		return (
			<>
				<AttachmentButton
					badgeContent={watchFileIds ? watchFileIds.length : 0}
					onClick={() => setOpenAttachmentModal(true)}
				>
					Edit Templated file
				</AttachmentButton>
				<Modal open={openAttachmentModal} onClose={() => setOpenAttachmentModal(false)}>
					<Box width="450px">
						<TemplateUploadFieldsV2 attachmentIndex={attachmentIndex} chainIndex={chainIndex} />
					</Box>
				</Modal>
			</>
		)
	}
}
