import React, { ComponentType, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RouteId } from 'src/constants/routes'
import { LoadingDocument } from 'src/components'
import type { AsyncDispatchType } from 'src/store/types'
import { actions as authActions } from 'src/store/modules/auth'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { SwitchOrgState, selectors as configSelectors } from 'src/store/modules/config'
import { DriveFilesProvider, TableProvider } from 'src/providers'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { usePrevious } from 'src/hooks/usePrevious'

import { useProject } from '../useProject'

import { ProjectComponent } from './project-component'

import 'src/assets/css/Task.css'

export const ProjectContainer: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [searchParams] = useSearchParams()
	const { projectId: currentProjectId, selectedTaskId } = useProject()
	const orgId = searchParams.get('orgId')
	const previousProjectId = usePrevious(currentProjectId)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const project = useSelector(projectSelectors.getSelectedProject)
	const [isLoadingProject, setIsLoadingProject] = useState(false)
	const [switchOrgState, setSwitchOrgState] = useState(SwitchOrgState.NotSwitching)
	const [errorFetchingProject, setErrorFetchingProject] = useState('')

	const fetchProject = async () => {
		if (!currentProjectId) return
		window.scrollTo(0, 0)
		setIsLoadingProject(true)
		const response = await dispatch(
			projectActions.getProject({
				projectId: currentProjectId,
			}),
		)
		setIsLoadingProject(false)
		if (response.meta.requestStatus === 'rejected') {
			setErrorFetchingProject(`Project with ID ${currentProjectId} not found`)
		}
	}

	const handleRevertInstance = async (instanceId: string, sectionId: string) => {
		if (!currentProjectId) return
		try {
			showSnackbar('Unable to revert instance', { variant: 'error' })
			// await V3ProjectSdk.deleteAsRequiredInstance(import.meta.env.VITE_API as string, authHeaders, {
			// 	instanceId,
			// 	sectionId,
			// })
			// await dispatch(
			// 	projectActions.getById({
			// 		projectId: currentProjectId,
			// 	}),
			// )
		} catch (error) {
			showSnackbar('Error deleting instance', { variant: 'error' })
		}
	}

	const switchOrgAndFetchBlueprint = async () => {
		if (!orgId) {
			setSwitchOrgState(SwitchOrgState.NoOrgId)
			return
		}
		setSwitchOrgState(SwitchOrgState.Switching)
		const response = await dispatch(authActions.switchOrganisation({ organisationId: orgId }))
		if (response.meta.requestStatus === 'rejected') {
			setSwitchOrgState(SwitchOrgState.Failed)
			setErrorFetchingProject(`Failed to switch organisation to ${orgId}`)
			return
		}
		setSwitchOrgState(SwitchOrgState.NotSwitching)
		fetchProject()
	}

	const loadingText = useMemo(() => {
		switch (switchOrgState) {
			case SwitchOrgState.Switching:
				return `Switching organisation to ${orgId}`
			case SwitchOrgState.Failed:
				return 'Failed to switch organisation'
			case SwitchOrgState.NoOrgId:
				return 'No organisation ID provided'
		}

		if (errorFetchingProject) {
			return ''
		}

		return ''
	}, [switchOrgState, project, errorFetchingProject])

	useEffect(() => {
		if (orgId !== organisationId) {
			switchOrgAndFetchBlueprint()
		}

		if (previousProjectId && currentProjectId && previousProjectId !== currentProjectId) {
			fetchProject()
		}
	}, [orgId, organisationId, previousProjectId, currentProjectId])

	useEffect(() => {
		if (orgId === organisationId) {
			fetchProject()
		}
	}, [])

	if (isLoadingProject || !project) {
		return (
			<LoadingDocument
				errorText={errorFetchingProject}
				docType="Project"
				returnToRoute={RouteId.Projects}
				customText={loadingText}
			/>
		)
	}

	return (
		<DriveFilesProvider
			parentFolderName={project.name}
			parentFolderId={project?.google_drive_folder_id}
		>
			<TableProvider tableId={project?.database_table}>
				<ProjectComponent
					orgId={orgId as string}
					onRevertInstance={handleRevertInstance}
					selectedTaskId={selectedTaskId}
				/>
			</TableProvider>
		</DriveFilesProvider>
	)
}
