import { TableTypes } from '@cango-app/types'
import { GridCellCoordinates } from '@mui/x-data-grid-premium'
import clipboard from 'clipboardy'
import _uniqBy from 'lodash/uniqBy'

import { showSnackbar } from 'src/helpers/snackbarManager'

export type COPY_CLIPBOARD = {
	type: 'data' | 'row_calculation' | 'descendants' | 'cell' | 'questionnaire_options'
	data: any
}

export const handleSpecialPasteFromClipboard = async (
	records: Map<string, TableTypes.Record>,
	selectedCells: GridCellCoordinates[],
) => {
	const rawClipboardData = await clipboard.read()
	const clipboardData: COPY_CLIPBOARD = JSON.parse(rawClipboardData)

	if (!clipboardData?.type || !clipboardData.data) {
		showSnackbar('Clipboard cannot be pasted', { variant: 'error' })
		return []
	}

	return [...records.values()].reduce(
		(_updatedRecords: TableTypes.Record[], { _id, ..._record }) => {
			const foundRow = selectedCells.find(({ id }) => _id === id)
			if (!foundRow) {
				return _updatedRecords
			}

			return [
				..._updatedRecords,
				{
					..._record,
					_id,
					data: _record.data,
					...(clipboardData.type === 'data' && {
						data: {
							..._record.data,
							[foundRow.field]: clipboardData.data,
						},
					}),
					calculations: {
						..._record.calculations,
						...(clipboardData.type === 'row_calculation' && {
							[foundRow.field]: clipboardData.data,
						}),
					},
					descendants: _uniqBy(
						[
							...(_record.descendants ?? []),
							...(clipboardData.type === 'descendants' ? clipboardData.data : []),
						],
						'step',
					),
				},
			]
		},
		[],
	)
}
