import React, { useCallback, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Controller, useForm } from 'react-hook-form'
import { V3ClientTypes } from '@cango-app/types'
import _isEmpty from 'lodash/isEmpty'
import { GridFilterModel } from '@mui/x-data-grid-premium'

import { Box, Button, Text } from 'src/components'
import { TableFilters } from 'src/components/table-filters'

export enum StatusType {
	Confirmed = 'confirmed',
	Rejected = 'rejected',
}

type ResolveData = {
	status: StatusType
	changes: GridFilterModel | null
}

type PromiseHandlers = {
	resolve: (data: ResolveData) => void
	initialFilterState: GridFilterModel
}

const useResetDatabaseValues = (): [
	JSX.Element,
	(descendant: V3ClientTypes.Project.ProjectDescendant) => Promise<ResolveData>,
] => {
	const [promise, setPromise] = useState<PromiseHandlers | null>(null)
	const [isLoading] = useState(false)
	const { control, handleSubmit, getValues } = useForm<{ filter: GridFilterModel }>({
		defaultValues: {
			filter: { items: [] },
		},
	})

	const checkDatabaseRevert = (
		descendant: V3ClientTypes.Project.ProjectDescendant,
	): Promise<ResolveData> => {
		return new Promise((resolve) => {
			if (!descendant.database_changes || _isEmpty(descendant.database_changes)) {
				resolve({ status: StatusType.Rejected, changes: null })
				return
			}

			setPromise({
				resolve,
				initialFilterState: {
					items: descendant.database_changes.map((_change) => {
						if (_change.operator === 'custom_input') {
							return {
								id: _change.id,
								field: _change.field,
								operator: 'isEmpty',
							}
						}
						return _change
					}),
				},
			})
		})
	}

	const handleClose = () => {
		setPromise(null)
	}

	const handleConfirm = async () => {
		if (!promise) {
			return
		}
		const data = getValues()
		promise.resolve({ status: StatusType.Confirmed, changes: data.filter })
		handleClose()
	}

	const handleCancel = useCallback(() => {
		promise?.resolve({ status: StatusType.Rejected, changes: null })
		handleClose()
	}, [promise])

	// You could replace the Dialog with your library's version
	const ResetDatabaseDialog = useMemo(
		() => (
			<Dialog
				open={promise !== null}
				fullWidth
				PaperProps={{
					sx: { borderRadius: 2, p: 2 },
				}}
			>
				<DialogContent>
					<Box mb={1}>
						<Box>
							<Text fontWeight="bold" variant="overline" fontSize={16}>
								Do you want to revert database changed for this selection?
							</Text>
						</Box>
					</Box>
					<Box>
						<Controller
							control={control}
							name="filter"
							render={({ field: { onChange } }) => (
								<TableFilters
									onChange={onChange}
									initialState={{ filter: { filterModel: promise?.initialFilterState } }}
									localeText={{
										filterPanelAddFilter: 'Add mod',
										toolbarFilters: 'Mods',
									}}
									disableLogicOperators
								/>
							)}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleConfirm} isLoading={isLoading}>
						Confirm
					</Button>
					<Button onClick={handleCancel} variant="outlined">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		),
		[promise, isLoading, handleSubmit, handleConfirm, control],
	)

	return [ResetDatabaseDialog, checkDatabaseRevert]
}

export default useResetDatabaseValues
