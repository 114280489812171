import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganisationProfile } from '@cango-app/types/lib/user'

import * as thunks from './thunks'
import { UserState } from './types'

const initialState: UserState = {
	users: [],
	isLoadingUsers: false,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser(state: UserState, action: PayloadAction<UserState['currentUser']>) {
			state.currentUser = action.payload
		},
		resetUser(state: UserState, action: PayloadAction<UserState['currentUser']>) {
			return {
				...initialState,
				currentUser: action.payload,
			}
		},
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.updateUser.fulfilled, (state: UserState, action) => {
			const { _id } = action.payload
			const userIndex = state.users.findIndex((user) => user._id === _id)
			state.users[userIndex] = action.payload
		})
		builder.addCase(thunks.deleteUser.fulfilled, (state: UserState, action) => {
			const { userId } = action.meta.arg
			state.users = state.users.filter((user) => user._id !== userId)
		})
		builder.addCase(thunks.createUser.fulfilled, (state: UserState, action) => {
			state.users = [...state.users, action.payload]
		})
		builder.addCase(thunks.fetchAllUsers.pending, (state: UserState) => {
			state.isLoadingUsers = true
		})
		builder.addCase(thunks.fetchAllUsers.fulfilled, (state: UserState, action) => {
			state.users = action.payload
			state.isLoadingUsers = false
		})
		builder.addCase(thunks.fetchAllUsers.rejected, (state: UserState) => {
			state.isLoadingUsers = false
		})
		builder.addCase(thunks.updateUsersPermissions.fulfilled, (state: UserState, action) => {
			const { permission, userIds } = action.payload
			state.users = state.users.map((user) => {
				if (userIds.includes(user._id)) {
					return {
						...user,
						organisations: user.organisations.map((organisation) => ({
							...organisation,
							permissions: permission,
						})),
					}
				}
				return user
			})
			if (state.currentUser && userIds.includes(state.currentUser?._id)) {
				state.currentUser = {
					...state.currentUser,
					permissions: permission,
				}
			}
		})
	},
})
