import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CommsSdk } from '@cango-app/sdk'
import _findIndex from 'lodash/findIndex'
import _set from 'lodash/set'
import dayjs from 'dayjs'

import { MessageStatus, NotesState } from './types'
import * as thunks from './thunks'

const initialState: NotesState = {
	inboxNavState: CommsSdk.InboxNavState.MyMessages,
	inboxNotes: [],
	tasksWithUnreadNotes: [],
	isLoadingInbox: false,
	isLoadingTaskNotes: false,
	taskNotes: [],
}

export const notesSlice = createSlice({
	name: 'notes',
	initialState,
	reducers: {
		setUnreadTasks: (
			state: NotesState,
			action: PayloadAction<CommsSdk.FetchUnreadTasksResponse>,
		) => {
			state.tasksWithUnreadNotes = action.payload
		},
		resetTaskNotes: (state: NotesState) => {
			state.taskNotes = initialState.taskNotes
		},
		setSelectedInboxTaskId: (state: NotesState, action: PayloadAction<string | undefined>) => {
			state.selectedInboxTaskId = action.payload
		},
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.readNotes.fulfilled, (state: NotesState, action) => {
			state.inboxNotes = state.inboxNotes.map((note) =>
				action.meta.arg.includes(note._id) ? { ...note, read: true } : note,
			)
		})
		builder.addCase(thunks.getInbox.pending, (state: NotesState, action) => {
			state.isLoadingInbox = true
			state.inboxNavState = action.meta.arg
			state.inboxNotes = []
		})
		builder.addCase(thunks.getInbox.fulfilled, (state: NotesState, action) => {
			state.isLoadingInbox = false
			state.inboxNotes = action.payload
		})
		builder.addCase(thunks.getInbox.rejected, (state: NotesState) => {
			state.isLoadingInbox = false
		})
		builder
			.addCase(thunks.fetchAllTaskNotes.pending, (state) => {
				state.isLoadingTaskNotes = true
				state.taskNotes = []
			})
			.addCase(thunks.fetchAllTaskNotes.fulfilled, (state, action) => {
				state.taskNotes = action.payload
				state.tasksWithUnreadNotes = state.tasksWithUnreadNotes.filter(
					({ taskId }) => taskId !== action.meta.arg.taskId,
				)
				state.isLoadingTaskNotes = false
			})
			.addCase(thunks.fetchAllTaskNotes.rejected, (state) => {
				state.isLoadingTaskNotes = false
			})
		builder
			.addCase(thunks.updateTaskNotes.pending, (state) => {
				state.isLoadingTaskNotes = true
			})
			.addCase(thunks.updateTaskNotes.fulfilled, (state, action) => {
				const filteredNotes = action.payload.filter(
					(note) => !state.taskNotes.find((n) => n._id === note._id),
				)
				state.taskNotes.push(...filteredNotes)
				state.tasksWithUnreadNotes = state.tasksWithUnreadNotes.filter(
					({ taskId }) => taskId !== action.meta.arg.taskId,
				)
				state.isLoadingTaskNotes = false
			})
			.addCase(thunks.updateTaskNotes.rejected, (state) => {
				state.isLoadingTaskNotes = false
			})
		builder
			.addCase(thunks.sendNote.pending, (state, action) => {
				const { note, tempId, fileIds, userId, organisationId } = action.meta.arg
				const tempNote = {
					created_by: userId,
					timestamp: dayjs().unix(),
					text: fileIds?.length ? 'Uploading' : note,
					state: [],
					_id: tempId,
					messageStatus: MessageStatus.IsSending,
					file_ids: [],
					organisationId,
				}
				state.taskNotes.push(tempNote)
			})
			.addCase(thunks.sendNote.fulfilled, (state, action) => {
				const { tempId, taskId } = action.meta.arg
				const noteResponse = action.payload
				const taskIndex = _findIndex(state.taskNotes, ['_id', tempId])
				if (taskIndex === undefined || taskIndex === -1) {
					return
				}
				const taskInboxIndex = _findIndex(state.inboxNotes, ['task._id', taskId])

				if (taskInboxIndex !== undefined && taskInboxIndex >= 0) {
					state.inboxNotes[taskInboxIndex] = {
						...state.inboxNotes[taskInboxIndex],
						...noteResponse,
					}
				}

				_set(state.taskNotes, taskIndex, {
					...noteResponse,
					messageStatus: MessageStatus.Sent,
				})
			})
			.addCase(thunks.sendNote.rejected, (state, action) => {
				const { tempId } = action.meta.arg
				const taskIndex = _findIndex(state.taskNotes, ['_id', tempId])
				if (taskIndex === undefined || taskIndex === -1) {
					return
				}
				_set(state.taskNotes, [taskIndex, 'messageStatus'], MessageStatus.Failed)
			})
	},
})
